import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  access_key?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type AdvanceViewTable = {
  __typename?: 'AdvanceViewTable';
  sla: Array<SlaTableType>;
  trends: Array<TrendsTableType>;
};

export type AssignTagRule = {
  __typename?: 'AssignTagRule';
  id: Scalars['Float'];
  literal: Scalars['String'];
  tag: Tag;
};

export type BiColumn = {
  __typename?: 'BIColumn';
  columns: Array<Column>;
  title: BiTableId;
};

export type BiTable = {
  __typename?: 'BITable';
  trends: Array<BiColumn>;
  weeklyColumns: WeeklyColumnValues;
};

/** BI table ids */
export enum BiTableId {
  AccuracyPercentage = 'ACCURACY_PERCENTAGE',
  AccuracyPercentageFc = 'ACCURACY_PERCENTAGE_FC',
  AccuracyPercentageTrendFc = 'ACCURACY_PERCENTAGE_TREND_FC',
  AvgBasketSize = 'AVG_BASKET_SIZE',
  AvgOrderPerDay = 'AVG_ORDER_PER_DAY',
  AvgOrderPerDayForShop = 'AVG_ORDER_PER_DAY_FOR_SHOP',
  AvgOrderValue = 'AVG_ORDER_VALUE',
  AvgShipmentPerDay = 'AVG_SHIPMENT_PER_DAY',
  AvgShipmentPerDayForShop = 'AVG_SHIPMENT_PER_DAY_FOR_SHOP',
  AvgShipmentWeights = 'AVG_SHIPMENT_WEIGHTS',
  Backorders = 'BACKORDERS',
  CancelledOrders = 'CANCELLED_ORDERS',
  CancelledOrdersPerc = 'CANCELLED_ORDERS_PERC',
  CancelledOutOfOrders = 'CANCELLED_OUT_OF_ORDERS',
  CancelledOutOfOrdersPerc = 'CANCELLED_OUT_OF_ORDERS_PERC',
  ComplaintsCount = 'COMPLAINTS_COUNT',
  ComplaintsCountPerFc = 'COMPLAINTS_COUNT_PER_FC',
  ComplaintsPerReason = 'COMPLAINTS_PER_REASON',
  ComplaintsPerReasonPerFc = 'COMPLAINTS_PER_REASON_PER_FC',
  DelayedOrdersCount = 'DELAYED_ORDERS_COUNT',
  DelayedOrdersPercentage = 'DELAYED_ORDERS_PERCENTAGE',
  DOsBySku = 'DOs_BY_SKU',
  ExcludedOrdersPercentage = 'EXCLUDED_ORDERS_PERCENTAGE',
  HeatMapCityTotalOrders = 'HEAT_MAP_CITY_TOTAL_ORDERS',
  HeatMapPcTotalOrders = 'HEAT_MAP_PC_TOTAL_ORDERS',
  HeatMapSkuRevenue = 'HEAT_MAP_SKU_REVENUE',
  HeatMapSkuTotalOrders = 'HEAT_MAP_SKU_TOTAL_ORDERS',
  HeatMapStateRevenue = 'HEAT_MAP_STATE_REVENUE',
  HeatMapStateTotalOrders = 'HEAT_MAP_STATE_TOTAL_ORDERS',
  MdnBasketSize = 'MDN_BASKET_SIZE',
  MdnOrderValue = 'MDN_ORDER_VALUE',
  MdnShipmentWeights = 'MDN_SHIPMENT_WEIGHTS',
  NetSales = 'NET_SALES',
  NetSalesTrend = 'NET_SALES_TREND',
  NonDomesticShipments = 'NON_DOMESTIC_SHIPMENTS',
  OnTimeFulfillmentPerFc = 'ON_TIME_FULFILLMENT_PER_FC',
  OnTimeFulfillmentTrendPerFc = 'ON_TIME_FULFILLMENT_TREND_PER_FC',
  OnTimeFulfilmentCount = 'ON_TIME_FULFILMENT_COUNT',
  OnTimeFulfilmentPercShop = 'ON_TIME_FULFILMENT_PERC_SHOP',
  OnTimeFulfilmentPercShopTrend = 'ON_TIME_FULFILMENT_PERC_SHOP_TREND',
  OnTimeFulfilmentPerFcPerSc = 'ON_TIME_FULFILMENT_PER_FC_PER_SC',
  Orders = 'ORDERS',
  OrdersCountTrendPercMonthly = 'ORDERS_COUNT_TREND_PERC_MONTHLY',
  OrdersPerFc = 'ORDERS_PER_FC',
  OrderToDelivered = 'ORDER_TO_DELIVERED',
  OrderToShipment = 'ORDER_TO_SHIPMENT',
  OrderToShipmentComplete = 'ORDER_TO_SHIPMENT_COMPLETE',
  OrderToShipmentForShop = 'ORDER_TO_SHIPMENT_FOR_SHOP',
  ParcelExpense = 'PARCEL_EXPENSE',
  PicksPerFc = 'PICKS_PER_FC',
  QualityIssuesPercPer_1000 = 'QUALITY_ISSUES_PERC_PER_1000',
  QualityIssuesPercPer_1000PerFc = 'QUALITY_ISSUES_PERC_PER_1000_PER_FC',
  Revenue = 'REVENUE',
  RevenuePerFc = 'REVENUE_PER_FC',
  ShipmentByFc = 'SHIPMENT_BY_FC',
  ShipmentBySku = 'SHIPMENT_BY_SKU',
  ShipmentByTrac = 'SHIPMENT_BY_TRAC',
  ShipmentException = 'SHIPMENT_EXCEPTION',
  ShipmentExceptionPerCity = 'SHIPMENT_EXCEPTION_PER_CITY',
  ShippedLateOutOfOrders = 'SHIPPED_LATE_OUT_OF_ORDERS',
  ShippedLateOutOfOrdersPerc = 'SHIPPED_LATE_OUT_OF_ORDERS_PERC',
  ShippedOntimeOutOfOrders = 'SHIPPED_ONTIME_OUT_OF_ORDERS',
  ShippedOntimeOutOfOrdersPerc = 'SHIPPED_ONTIME_OUT_OF_ORDERS_PERC',
  ShippedOutOfOrders = 'SHIPPED_OUT_OF_ORDERS',
  ShippedToDelivered = 'SHIPPED_TO_DELIVERED',
  TotalAccurateOrders = 'TOTAL_ACCURATE_ORDERS',
  TotalAccurateOrdersPerFc = 'TOTAL_ACCURATE_ORDERS_PER_FC',
  TotalOrders = 'TOTAL_ORDERS',
  TotalPicks = 'TOTAL_PICKS',
  TotalSales = 'TOTAL_SALES',
  TotalSalesTrend = 'TOTAL_SALES_TREND',
  TotalShippedLate = 'TOTAL_SHIPPED_LATE',
  TotalShippedLatePerc = 'TOTAL_SHIPPED_LATE_PERC',
  TotalShippedOnTimePercentage = 'TOTAL_SHIPPED_ON_TIME_PERCENTAGE',
  TotalShippedOrders = 'TOTAL_SHIPPED_ORDERS',
  TotalShippedOrdersTrend = 'TOTAL_SHIPPED_ORDERS_TREND',
  UnfulfilledOutOfOrders = 'UNFULFILLED_OUT_OF_ORDERS',
  UnfulfilledOutOfOrdersPerc = 'UNFULFILLED_OUT_OF_ORDERS_PERC',
  ZoneDistribution = 'ZONE_DISTRIBUTION'
}

export type BrandInfo = {
  __typename?: 'BrandInfo';
  /** Start of the SLA agreement between Shop and FC */
  joined_at?: Maybe<Scalars['DateTime']>;
  locations_count?: Maybe<Scalars['Float']>;
  /** shop_domain is the unique throughout capabl and should be used to. identify a shop. */
  shop_domain?: Maybe<Scalars['String']>;
  /** Representation of the shop_domain as a human freindly name, not unique. */
  shop_name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  /** End of the SLA agreement between Shop and FC */
  valid_until?: Maybe<Scalars['DateTime']>;
};

export type Cells = {
  __typename?: 'Cells';
  cellId: Scalars['String'];
  nestedCell?: Maybe<Array<Cells>>;
  threshold?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type Column = {
  __typename?: 'Column';
  cells: Array<Cells>;
  handle: Scalars['String'];
  name: Scalars['String'];
};

/** Comparison type for sales channel based on SKU count. */
export enum ComparisonType {
  EqualTo = 'EQUAL_TO',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  LessThan = 'LESS_THAN',
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO'
}

export type ComplaintTicketItem = {
  __typename?: 'ComplaintTicketItem';
  assignedTo?: Maybe<ContactInfo>;
  channel?: Maybe<Scalars['String']>;
  /** When the event was closed */
  closedDate?: Maybe<Scalars['DateTime']>;
  /** When the event was created */
  createdDate?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<ContactInfo>;
  externalId?: Maybe<Scalars['String']>;
  fromAgent?: Maybe<Scalars['Boolean']>;
  isUnread?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  /** When the last message was received */
  lastReceivedMessageDate?: Maybe<Scalars['DateTime']>;
  /** When the last message was sent */
  lastSentMessageDate?: Maybe<Scalars['DateTime']>;
  messages?: Maybe<Array<PublicApiTicketMessage>>;
  meta?: Maybe<Scalars['String']>;
  /** When the ticket was opened */
  openedDate?: Maybe<Scalars['DateTime']>;
  orderIds?: Maybe<Array<Scalars['Float']>>;
  /** When the ticket would reopen */
  snoozeDate?: Maybe<Scalars['DateTime']>;
  spam?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<PublicApiTicketTag>>;
  ticketingServiceId: Scalars['Float'];
  /** When the ticket was trashed */
  trashedDate?: Maybe<Scalars['DateTime']>;
  /** When the ticket was updated */
  updatedDate?: Maybe<Scalars['DateTime']>;
  uri?: Maybe<Scalars['String']>;
  via?: Maybe<Scalars['String']>;
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lastname?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CouponDiscounts = {
  __typename?: 'CouponDiscounts';
  discount: Scalars['Float'];
  planName: Scalars['String'];
};

/** Current step for the checklist */
export enum CurrentSteps {
  AlertsNotification = 'ALERTS_NOTIFICATION',
  Done = 'DONE',
  FulfilmentProvider = 'FULFILMENT_PROVIDER',
  SalesChannel = 'SALES_CHANNEL',
  Shopify = 'SHOPIFY'
}

export type CustomReport = {
  __typename?: 'CustomReport';
  analyticsType: Scalars['String'];
  comparisonType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  periodType: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
};

export type CustomReportInput = {
  analyticsType: Scalars['String'];
  comparisonType: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  periodType: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  comp_name: Scalars['String'];
  created_at: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  failureReason: Scalars['String'];
  id: Scalars['Float'];
  paymentStatus: Scalars['String'];
  pricingPlan: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

/** Days of week */
export enum DayOfWeek {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

/** status, delayed or not */
export enum Delay {
  Empty = 'EMPTY',
  No = 'NO',
  Yes = 'YES'
}

export type DeliveryEventItem = {
  __typename?: 'DeliveryEventItem';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  /** When the event was created */
  date?: Maybe<Scalars['DateTime']>;
  delayDetailStatus?: Maybe<Scalars['String']>;
  delayDetailSubtype?: Maybe<Scalars['String']>;
  delayDetailType?: Maybe<Scalars['String']>;
  derivedStatus?: Maybe<Scalars['String']>;
  derivedStatusCode?: Maybe<Scalars['String']>;
  eventDescription?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  exceptionCode?: Maybe<Scalars['String']>;
  exceptionDescription?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationType?: Maybe<Scalars['String']>;
  stateOrProvinceCode?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
};

/** Available duration types for intervals */
export enum DurationType {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES'
}

export type ExcludeReason = {
  __typename?: 'ExcludeReason';
  comment: Scalars['String'];
  first_name: Scalars['String'];
  happened_at: Scalars['DateTime'];
  is_assigned?: Maybe<Scalars['Boolean']>;
  is_excluded?: Maybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  primary_reason: Scalars['String'];
  reason_type?: Maybe<ReasonType>;
  secondary_reason: Scalars['String'];
};

export type ExcludeRule = {
  __typename?: 'ExcludeRule';
  exclude: Scalars['Boolean'];
  id: Scalars['Float'];
  ruleType: Scalars['String'];
  tag: Tag;
};

export type ExcludeSkuRule = {
  __typename?: 'ExcludeSKURule';
  customerId: Scalars['Float'];
  id: Scalars['Float'];
  shopDomain: Scalars['String'];
  sku: Scalars['String'];
};

export type FcRights = {
  __typename?: 'FCRights';
  customerId: Scalars['Float'];
  fulfillmentServiceId: Scalars['Float'];
  id: Scalars['Float'];
  userId: Scalars['Float'];
};

export type FulfillmentCenterHoliday = {
  __typename?: 'FulfillmentCenterHoliday';
  holiday_date: Scalars['DateTime'];
  holiday_name: Scalars['String'];
  id: Scalars['Float'];
};

export type FulfillmentCenterHolidayInput = {
  holiday_date: Scalars['DateTime'];
  holiday_name: Scalars['String'];
};

/** The frequency of fulfillment */
export enum FulfillmentFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type FulfillmentLocation = {
  __typename?: 'FulfillmentLocation';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  external_data_source: Scalars['String'];
  handle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  legacy_fulfillment_service_id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  shop_domain: Scalars['String'];
  shop_verified?: Maybe<Scalars['Boolean']>;
  sla?: Maybe<Sla>;
  supports_courier_tracking: Scalars['Boolean'];
  supports_fulfillment_activity_tracking: Scalars['Boolean'];
  supports_inventory_activity_tracking: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  verification_status: Scalars['String'];
};

export type FulfilmentRoutes = {
  __typename?: 'FulfilmentRoutes';
  address?: Maybe<Scalars['String']>;
  /** Only used at fetch */
  fcHandle: Scalars['String'];
  fulfilmentServiceId: Scalars['Float'];
  id: Scalars['Float'];
  priority: Scalars['Float'];
};

export type FulfilmentRoutesInput = {
  address?: Maybe<Scalars['String']>;
  /** Only used at fetch */
  fcHandle?: Maybe<Scalars['String']>;
  fulfilmentServiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

export type FulfilmentRoutesUpdate = {
  addresses: Array<Scalars['String']>;
  fulfilmentServiceId: Scalars['Float'];
  priority: Scalars['Float'];
};

export type FulfilmentService = {
  __typename?: 'FulfilmentService';
  address?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  consider_partial?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['Float']>;
  daylightEnabled: Scalars['Boolean'];
  daylightFrom?: Maybe<Scalars['DateTime']>;
  daylightOffset: Scalars['Float'];
  daylightTo?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fcSettings: Array<FulfilmentServiceSettings>;
  fromTime?: Maybe<Scalars['DateTime']>;
  fulfillment_order_opt_in?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  include_pending_stock?: Maybe<Scalars['Boolean']>;
  inventory_management?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_internal_id?: Maybe<Scalars['Float']>;
  routingSoftware?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['DateTime']>;
  tracking_support?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Float']>;
  warehouseName?: Maybe<Scalars['String']>;
};

export type FulfilmentServiceInput = {
  address?: Maybe<Scalars['String']>;
  callback_url?: Maybe<Scalars['String']>;
  consider_partial?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['Float']>;
  daylightEnabled?: Maybe<Scalars['Boolean']>;
  daylightFrom?: Maybe<Scalars['DateTime']>;
  daylightOffset?: Maybe<Scalars['Float']>;
  daylightTo?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fcSettings?: Maybe<Array<FulfilmentServiceSettingInput>>;
  fromTime?: Maybe<Scalars['DateTime']>;
  fulfillment_order_opt_in?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  include_pending_stock?: Maybe<Scalars['Boolean']>;
  inventory_management?: Maybe<Scalars['Boolean']>;
  location_id?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Float']>;
  provider_internal_id?: Maybe<Scalars['Float']>;
  routingSoftware?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['DateTime']>;
  tracking_support?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Float']>;
  warehouseName?: Maybe<Scalars['String']>;
};

export type FulfilmentServiceSettingInput = {
  contractPercent?: Maybe<Scalars['Float']>;
  /** Cut off time for FC */
  cutoffDate?: Maybe<Scalars['DateTime']>;
  dayOfShipping: DayOfWeek;
  days?: Maybe<Scalars['String']>;
  delayDuration?: Maybe<Scalars['Float']>;
  delayDurationType: DurationType;
  delayOrders?: Maybe<Scalars['Boolean']>;
  /** Till when the settings are applied */
  end?: Maybe<Scalars['DateTime']>;
  fcId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Start of the SLA agreement between Shop and FC */
  joinedAt?: Maybe<Scalars['DateTime']>;
  orderAccuracyPercent?: Maybe<Scalars['Float']>;
  ordersAfter?: Maybe<Scalars['Float']>;
  ordersBefore?: Maybe<Scalars['Float']>;
  prepDuration?: Maybe<Scalars['Float']>;
  prepDurationType: DurationType;
  salesChannelId: Scalars['Float'];
  shippingPeriodCount: Scalars['Float'];
  shippingPeriodType: DurationType;
  shippingWindowType: ShippingWindowType;
  shopDomain: Scalars['String'];
  /** From when the settings are applied */
  start: Scalars['DateTime'];
  timezone?: Maybe<Scalars['Float']>;
  /** End of the SLA agreement between Shop and FC */
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type FulfilmentServiceSettings = {
  __typename?: 'FulfilmentServiceSettings';
  contractPercent?: Maybe<Scalars['Float']>;
  /** Cut off time for FC */
  cutoffDate?: Maybe<Scalars['DateTime']>;
  dayOfShipping: DayOfWeek;
  days?: Maybe<Scalars['String']>;
  delayDuration?: Maybe<Scalars['Float']>;
  delayDurationType: DurationType;
  delayOrders: Scalars['Boolean'];
  /** Till when the settings are applied */
  end?: Maybe<Scalars['DateTime']>;
  fcId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Start of the SLA agreement between Shop and FC */
  joinedAt?: Maybe<Scalars['DateTime']>;
  orderAccuracyPercent?: Maybe<Scalars['Float']>;
  ordersAfter?: Maybe<Scalars['Float']>;
  ordersBefore?: Maybe<Scalars['Float']>;
  prepDuration?: Maybe<Scalars['Float']>;
  prepDurationType: DurationType;
  salesChannelId: Scalars['Float'];
  shippingPeriodCount: Scalars['Float'];
  shippingPeriodType: DurationType;
  shippingWindowType: ShippingWindowType;
  shopDomain: Scalars['String'];
  /** From when the settings are applied */
  start: Scalars['DateTime'];
  timezone?: Maybe<Scalars['Float']>;
  /** End of the SLA agreement between Shop and FC */
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationByCoordinates = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GeoLocationByGoogleMapsPlaceId = {
  placeId: Scalars['String'];
};

export type GoogleMapsAddressSuggestion = {
  __typename?: 'GoogleMapsAddressSuggestion';
  main_text: Scalars['String'];
  place_id: Scalars['String'];
  secondary_text: Scalars['String'];
  suggestedAddress: Scalars['String'];
};

export type InProcessOnboarding = {
  __typename?: 'InProcessOnboarding';
  activeUser: OnboardingUser;
  cardAttached: Scalars['Boolean'];
  fulfilmentServices: Array<ShopFc>;
  selectedPlanAlias: Scalars['String'];
  shopifyIntegrated: Scalars['Boolean'];
  shopifyURL: Scalars['String'];
  stripeIntent: StripeIntent;
};

export type LineItem = {
  __typename?: 'LineItem';
  name: Scalars['String'];
  qty: Scalars['Float'];
  sku: Scalars['String'];
  unit_price: Scalars['String'];
  weight: Scalars['Float'];
};

export type LocationInfo = {
  __typename?: 'LocationInfo';
  active_brands?: Maybe<Scalars['Float']>;
  /** Representation of the fulfillment center location as a human freindly name */
  location_name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addFCRoutes: Scalars['Boolean'];
  addFulfillmentCentersHolidays: Scalars['Boolean'];
  addSkubanaShop: ShopToken;
  addTags: Scalars['Boolean'];
  /** Assign reason codes to the orders */
  assignReasonCodeToOrders: Scalars['Boolean'];
  attachCardAndCreateCustomer?: Maybe<InProcessOnboarding>;
  createAssignRule: Scalars['Boolean'];
  createExcludeRule: Scalars['Boolean'];
  createExcludeSKURule: Scalars['Boolean'];
  createRouteRule: Scalars['Boolean'];
  createRouteSKURule: Scalars['Boolean'];
  createSLAOverwrittingRule: Scalars['Boolean'];
  createSLAOverwrittingSKURule: Scalars['Boolean'];
  deactivateFulfillmentLocation: FulfillmentLocation;
  deleteCustomReports: Scalars['Boolean'];
  deleteFCRoutes: Scalars['Boolean'];
  deleteFulfillment: Scalars['Boolean'];
  deleteFulfillmentCentersHolidays: Scalars['Boolean'];
  /** Reroutes these orders to specified FC */
  deleteReasonCodes: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  disableDaylightSaving: Scalars['Boolean'];
  disableFulfillment: Scalars['Boolean'];
  disablePartialFulfiment: Scalars['Boolean'];
  enableDaylightSaving: Scalars['Boolean'];
  enableFulfillment: Scalars['Boolean'];
  enableFulfillmentLocation: FulfillmentLocation;
  enablePartialFulfiment: Scalars['Boolean'];
  /** Excludes order or reroute it based on filter criteria */
  excludeOrReRouteOrders: Scalars['Boolean'];
  /** Excludes order or includes it based on exclude value */
  excludeOrder: Scalars['Boolean'];
  finalizeOnboardingForCustomer: Scalars['String'];
  initiatedOnboardingProcess?: Maybe<InProcessOnboarding>;
  insertFulfillmentService: Scalars['Boolean'];
  inviteUser?: Maybe<Array<UserRole>>;
  /** Mark this shop active */
  markShopActive: Scalars['Boolean'];
  /** Reroutes these orders to specified FC */
  overwriteOrdersSLA: Scalars['Boolean'];
  removeAssignRule: Scalars['Boolean'];
  removeExcludeRule: Scalars['Boolean'];
  removeExcludeSKURule: Scalars['Boolean'];
  removeRouteRule: Scalars['Boolean'];
  removeRouteSKURule: Scalars['Boolean'];
  removeSLAOverwrittingRule: Scalars['Boolean'];
  removeSLAOverwrittingSKURule: Scalars['Boolean'];
  removeSalesChannel: Scalars['Boolean'];
  removeSalesChannelRule: Scalars['Boolean'];
  removeTags: Scalars['Boolean'];
  removeTagsFromOrders: Scalars['Boolean'];
  /** Reroutes these orders to specified FC */
  rerouteOrders: Scalars['Boolean'];
  saveCustomReports: Scalars['Boolean'];
  sendHelpEmail: Scalars['Boolean'];
  /** Unassign reason codes from the orders, empty list will unassign from all orders */
  unassignReasonCodeToOrders: Scalars['Boolean'];
  updateFCRights: Scalars['Boolean'];
  updateFCRoutes: Scalars['Boolean'];
  updateFulfillmentService: Scalars['Boolean'];
  updatePlanForCustomer: Scalars['Boolean'];
  updateRouteRule: Scalars['Boolean'];
  updateSLAOverwrittingRule: Scalars['Boolean'];
  updateScorecardSchedule: Scalars['Float'];
  updateScorecardUsers: Scalars['Boolean'];
  updateUser: Scalars['Boolean'];
  updateUserCurrentStep: Scalars['Boolean'];
  updateUserFirstLastName: Scalars['Boolean'];
  updateUserSkippedStep: Scalars['Boolean'];
  /** Reroutes these orders to specified FC */
  upsertReasonCodes: Scalars['Boolean'];
  upsertSalesChannel: Scalars['Boolean'];
  upsertSalesChannelRule: Scalars['Boolean'];
  verifyLocationSLA: Array<FulfillmentLocation>;
  verifyLocationSLAWithCoordinates: FulfillmentLocation;
  verifyLocationSLAWithGoogleMapsReference: FulfillmentLocation;
  verifyToken?: Maybe<Scalars['Boolean']>;
};


export type MutationAddFcRoutesArgs = {
  route: FulfilmentRoutesInput;
};


export type MutationAddFulfillmentCentersHolidaysArgs = {
  handle: Scalars['String'];
  holidays: Array<FulfillmentCenterHolidayInput>;
  shopName: Scalars['String'];
};


export type MutationAddSkubanaShopArgs = {
  cid: Scalars['String'];
  code: Scalars['String'];
};


export type MutationAddTagsArgs = {
  provider_internal_ids?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationAssignReasonCodeToOrdersArgs = {
  orderIds: Array<Scalars['String']>;
  reasonCode: ReasonCodeInput;
};


export type MutationAttachCardAndCreateCustomerArgs = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  planAlias: Scalars['String'];
  stripeIntent: StripeIntentInput;
};


export type MutationCreateAssignRuleArgs = {
  literal?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateExcludeRuleArgs = {
  exclude: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
};


export type MutationCreateExcludeSkuRuleArgs = {
  skus: Array<Scalars['String']>;
};


export type MutationCreateRouteRuleArgs = {
  fc_handle?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateRouteSkuRuleArgs = {
  fc_handle?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Scalars['String']>>;
};


export type MutationCreateSlaOverwrittingRuleArgs = {
  date?: Maybe<Scalars['DateTime']>;
  delayInDays?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<SlaOverwrittingType>;
};


export type MutationCreateSlaOverwrittingSkuRuleArgs = {
  date?: Maybe<Scalars['DateTime']>;
  delayInDays?: Maybe<Scalars['Float']>;
  skus?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<SlaOverwrittingType>;
};


export type MutationDeactivateFulfillmentLocationArgs = {
  LocationId: Scalars['String'];
};


export type MutationDeleteCustomReportsArgs = {
  reportId: Scalars['Float'];
};


export type MutationDeleteFcRoutesArgs = {
  ids: Array<Scalars['Float']>;
};


export type MutationDeleteFulfillmentArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteFulfillmentCentersHolidaysArgs = {
  handle: Scalars['String'];
  ids: Array<Scalars['Float']>;
  shopName: Scalars['String'];
};


export type MutationDeleteReasonCodesArgs = {
  reasonCodes: Array<ReasonCodeInput>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['Float'];
};


export type MutationDisableDaylightSavingArgs = {
  id: Scalars['Float'];
};


export type MutationDisableFulfillmentArgs = {
  id: Scalars['Float'];
};


export type MutationEnableDaylightSavingArgs = {
  from: Scalars['DateTime'];
  id: Scalars['Float'];
  offset: Scalars['Float'];
  to: Scalars['DateTime'];
};


export type MutationEnableFulfillmentArgs = {
  id: Scalars['Float'];
};


export type MutationEnableFulfillmentLocationArgs = {
  LocationInput: Scalars['String'];
};


export type MutationExcludeOrReRouteOrdersArgs = {
  fromDate: Scalars['DateTime'];
  fulfillmentCenter?: Maybe<Scalars['String']>;
  markReRoute?: Maybe<Scalars['Boolean']>;
  newSLADate?: Maybe<Scalars['DateTime']>;
  primaryReason?: Maybe<PrimaryExcludeReason>;
  routeToFC?: Maybe<Scalars['String']>;
  salesChannel?: Maybe<Array<Scalars['String']>>;
  secondaryReason?: Maybe<Scalars['String']>;
  skuNumber?: Maybe<Array<Scalars['String']>>;
  slaStatus?: Maybe<Delay>;
  status?: Maybe<OrderStatus>;
  tag?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
};


export type MutationExcludeOrderArgs = {
  comment: Scalars['String'];
  exclude: Scalars['Boolean'];
  orderIds: Array<Scalars['String']>;
  primaryReason: Scalars['String'];
  secondaryReason: Scalars['String'];
};


export type MutationFinalizeOnboardingForCustomerArgs = {
  onboardingValues: OnboardingInput;
};


export type MutationInitiatedOnboardingProcessArgs = {
  planAlias: PricingPlan;
  shopApiKey: Scalars['String'];
  shopName: Scalars['String'];
  shopPassword: Scalars['String'];
  shopType?: Maybe<ShopType>;
};


export type MutationInsertFulfillmentServiceArgs = {
  warehouseName: Scalars['String'];
};


export type MutationInviteUserArgs = {
  users: Array<UserRoleInput>;
};


export type MutationMarkShopActiveArgs = {
  shopName: Scalars['String'];
  shopType?: Maybe<ShopType>;
};


export type MutationOverwriteOrdersSlaArgs = {
  slaDate?: Maybe<Scalars['DateTime']>;
  tags: Array<Scalars['String']>;
};


export type MutationRemoveAssignRuleArgs = {
  tags: Array<Scalars['String']>;
};


export type MutationRemoveExcludeRuleArgs = {
  tags: Array<Scalars['String']>;
};


export type MutationRemoveExcludeSkuRuleArgs = {
  skus: Array<Scalars['String']>;
};


export type MutationRemoveRouteRuleArgs = {
  tags: Array<Scalars['String']>;
};


export type MutationRemoveRouteSkuRuleArgs = {
  skus?: Maybe<Array<Scalars['String']>>;
};


export type MutationRemoveSlaOverwrittingRuleArgs = {
  tags: Array<Scalars['String']>;
};


export type MutationRemoveSlaOverwrittingSkuRuleArgs = {
  skus: Array<Scalars['String']>;
};


export type MutationRemoveSalesChannelArgs = {
  salesChannel: SalesChannelInput;
};


export type MutationRemoveSalesChannelRuleArgs = {
  salesChannelRule: SalesChannelRuleObject;
};


export type MutationRemoveTagsArgs = {
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationRemoveTagsFromOrdersArgs = {
  provider_internal_ids?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationRerouteOrdersArgs = {
  fc_handle: Scalars['String'];
  orderIds: Array<Scalars['String']>;
};


export type MutationSaveCustomReportsArgs = {
  report: CustomReportInput;
};


export type MutationSendHelpEmailArgs = {
  content: Scalars['String'];
  subject: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationUnassignReasonCodeToOrdersArgs = {
  orderIds: Array<Scalars['String']>;
  reasonCode: ReasonCodeInput;
};


export type MutationUpdateFcRightsArgs = {
  fulfillmentId: Scalars['Float'];
  userIds: Array<Scalars['Float']>;
};


export type MutationUpdateFcRoutesArgs = {
  routes: FulfilmentRoutesUpdate;
};


export type MutationUpdateFulfillmentServiceArgs = {
  fcData: Array<FulfilmentServiceInput>;
};


export type MutationUpdatePlanForCustomerArgs = {
  planAlias: Scalars['String'];
};


export type MutationUpdateRouteRuleArgs = {
  fc_handle?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateSlaOverwrittingRuleArgs = {
  date?: Maybe<Scalars['DateTime']>;
  delayInDays?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<SlaOverwrittingType>;
};


export type MutationUpdateScorecardScheduleArgs = {
  schedule: ScorecardScheduleInput;
};


export type MutationUpdateScorecardUsersArgs = {
  emails: Array<Scalars['String']>;
  scheduleId: Scalars['Float'];
};


export type MutationUpdateUserArgs = {
  user: UserInput;
};


export type MutationUpdateUserCurrentStepArgs = {
  currentStep: CurrentSteps;
};


export type MutationUpdateUserFirstLastNameArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationUpdateUserSkippedStepArgs = {
  skippedSteps: Scalars['String'];
};


export type MutationUpsertReasonCodesArgs = {
  reasonCodes: Array<ReasonCodeInput>;
};


export type MutationUpsertSalesChannelArgs = {
  salesChannel: SalesChannelInput;
};


export type MutationUpsertSalesChannelRuleArgs = {
  salesChannelRule: SalesChannelRuleObject;
};


export type MutationVerifyLocationSlaArgs = {
  LocationId: Scalars['String'];
  sla: SlaInput;
};


export type MutationVerifyLocationSlaWithCoordinatesArgs = {
  LocationId: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  geo: GeoLocationByCoordinates;
  sla: SlaInput;
};


export type MutationVerifyLocationSlaWithGoogleMapsReferenceArgs = {
  LocationId: Scalars['String'];
  geo: GeoLocationByGoogleMapsPlaceId;
  sla: SlaInput;
  updatedName?: Maybe<Scalars['String']>;
};


export type MutationVerifyTokenArgs = {
  shop: Scalars['String'];
  shopType: Scalars['String'];
  token: Scalars['String'];
};

export type OnboardingErrorObject = {
  onboardingStep?: Maybe<OnboardingStepEnum>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type OnboardingFcHolidays = {
  fulfillmentServiceId: Scalars['Float'];
  holiday_date: Scalars['DateTime'];
  holiday_name: Scalars['String'];
};

export type OnboardingFulfillmentCenter = {
  address: Scalars['String'];
  contractPercent: Scalars['Float'];
  cutoffDate: Scalars['DateTime'];
  dayOfShipping: Scalars['String'];
  days: Scalars['String'];
  delayDuration: Scalars['Float'];
  delayDurationType: Scalars['String'];
  delayOrders: Scalars['Boolean'];
  fromTime: Scalars['DateTime'];
  handle: Scalars['String'];
  holidays: Array<OnboardingFcHolidays>;
  orderAccuracyPercent: Scalars['Float'];
  ordersAfter: Scalars['Float'];
  ordersBefore: Scalars['Float'];
  prepDurationTime: Scalars['Float'];
  prepDurationType: Scalars['String'];
  provider_internal_Id: Scalars['Float'];
  route: OnboardingRoutes;
  routingSoftware: Scalars['String'];
  salesChannelId: Scalars['Float'];
  scorecardSchedules?: Maybe<OnboardingScorecardSheduleInput>;
  shippingPeriod: Scalars['Float'];
  shippingPeriodType: Scalars['String'];
  shippingWindowType: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  timezone: Scalars['Float'];
  toTime: Scalars['DateTime'];
  userId: Scalars['Float'];
  warehouseName: Scalars['String'];
};

export type OnboardingInput = {
  cacheResults?: Maybe<Scalars['Boolean']>;
  discountCode?: Maybe<Scalars['String']>;
  fulfillmentCenters: Array<OnboardingFulfillmentCenter>;
  invitedUsers: Array<UserRoleInput>;
  onboardingError?: Maybe<OnboardingErrorObject>;
  onboardingStep?: Maybe<OnboardingStepEnum>;
  planAlias: Scalars['String'];
  registeringUser?: Maybe<OnboardingUserInput>;
  shopProviderInternalId: Scalars['Float'];
  shopType: Scalars['String'];
  shopifyAccessKey: Scalars['String'];
  shopifyPassword: Scalars['String'];
  shopifyUrl: Scalars['String'];
};

export type OnboardingRoutes = {
  addresses: Array<Scalars['String']>;
  priority: Scalars['Float'];
};

export type OnboardingScorecardSheduleInput = {
  days: Scalars['String'];
  fulfillmentServiceId: Scalars['Float'];
  id: Scalars['Float'];
  scorecardUsers: Array<ScorecardUsersInput>;
  time?: Maybe<Scalars['DateTime']>;
  timezone: Scalars['Float'];
};

/** Onboarding Steps Enum */
export enum OnboardingStepEnum {
  AttachSalesChannel = 'AttachSalesChannel',
  CreateEmailSchedules = 'CreateEmailSchedules',
  CreateFulfilmentServices = 'CreateFulfilmentServices',
  CreateUsers = 'CreateUsers',
  FinalizeOnboarding = 'FinalizeOnboarding',
  Finished = 'Finished',
  GenerateSubscriptionUrl = 'GenerateSubscriptionURL',
  Initated = 'Initated',
  UpdateShopInfo = 'UpdateShopInfo'
}

export type OnboardingUser = {
  __typename?: 'OnboardingUser';
  comp_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  cust_id: Scalars['Float'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
};

export type OnboardingUserInput = {
  comp_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  cust_id?: Maybe<Scalars['Float']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  billing_address?: Maybe<Scalars['String']>;
  billing_email?: Maybe<Scalars['String']>;
  billing_name?: Maybe<Scalars['String']>;
  billing_phone?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  delay?: Maybe<Scalars['Float']>;
  deliveryEvents?: Maybe<Array<Maybe<DeliveryEventItem>>>;
  exclude_reasons?: Maybe<Array<Maybe<ExcludeReason>>>;
  exlude?: Maybe<Scalars['Boolean']>;
  lineitems: Array<Maybe<LineItem>>;
  onTimeFulfilmentReason?: Maybe<Scalars['String']>;
  orderAccuracyReason?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<OrderStatus>;
  orderStatusSLA?: Maybe<OrderStatusSla>;
  qty?: Maybe<Scalars['Float']>;
  salesChannelInternal?: Maybe<Scalars['String']>;
  sales_channel?: Maybe<Scalars['String']>;
  shipping_address?: Maybe<Scalars['String']>;
  shipping_cost?: Maybe<Scalars['String']>;
  shipping_email?: Maybe<Scalars['String']>;
  shipping_name?: Maybe<Scalars['String']>;
  shipping_phone?: Maybe<Scalars['String']>;
  /** shop_domain is the unique throughout capabl and should be used to. identify a shop. */
  shop_domain?: Maybe<Scalars['String']>;
  /** Representation of the shop_domain as a human freindly name, not unique. */
  shop_name?: Maybe<Scalars['String']>;
  slaDate?: Maybe<Scalars['DateTime']>;
  stage?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tickets?: Maybe<Array<Maybe<ComplaintTicketItem>>>;
  total_discount?: Maybe<Scalars['String']>;
  total_price?: Maybe<Scalars['String']>;
  total_taxes?: Maybe<Scalars['String']>;
  tracking_company?: Maybe<Scalars['String']>;
  tracking_number?: Maybe<Scalars['String']>;
  tracking_url?: Maybe<Scalars['String']>;
};

/** Order status for query */
export enum OrderStatus {
  AllOrders = 'ALL_ORDERS',
  Backlogged = 'BACKLOGGED',
  Cancelled = 'CANCELLED',
  Complaints = 'COMPLAINTS',
  Delayed = 'DELAYED',
  Excluded = 'EXCLUDED',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  Shipped = 'SHIPPED',
  ShippedOffSlaOrders = 'SHIPPED_OFF_SLA_ORDERS',
  ShippedOnSlaOrders = 'SHIPPED_ON_SLA_ORDERS',
  SlaOrders = 'SLA_ORDERS',
  Unfullfilled = 'UNFULLFILLED'
}

/** Order sla status */
export enum OrderStatusSla {
  Done = 'DONE',
  MarkedForRouting = 'MARKED_FOR_ROUTING',
  MarkedForSlaCalculation = 'MARKED_FOR_SLA_CALCULATION'
}

/** Type of the pricing plans */
export enum PricingPlan {
  Basic = 'BASIC',
  BasicAnnually = 'BASIC_ANNUALLY',
  Lite = 'LITE',
  LiteAnnually = 'LITE_ANNUALLY',
  Plus = 'PLUS',
  PlusAnnually = 'PLUS_ANNUALLY',
  Premium = 'PREMIUM',
  PremiumAnnually = 'PREMIUM_ANNUALLY',
  Starter = 'STARTER',
  StarterAnnually = 'STARTER_ANNUALLY',
  Ultra = 'ULTRA',
  UltraAnnually = 'ULTRA_ANNUALLY'
}

export type PricingPlans = {
  __typename?: 'PricingPlans';
  alias: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['Float'];
  integrations: Scalars['Float'];
  locations: Scalars['Float'];
  monthlyOrders: Scalars['Float'];
  months: Scalars['Float'];
  name: Scalars['String'];
  overallOrders: Scalars['Float'];
  price: Scalars['Float'];
  scorecardDaysLimit: Scalars['Float'];
  scorecardUsersLimit: Scalars['Float'];
  usersLimit: Scalars['Float'];
};

/** enum for primary exclude reasons */
export enum PrimaryExcludeReason {
  BackOrders = 'BACK_ORDERS',
  ExcludeOrders = 'EXCLUDE_ORDERS',
  PreOrders = 'PRE_ORDERS',
  ReRoute = 'RE_ROUTE'
}

export type PublicApiTicketMessage = {
  __typename?: 'PublicApiTicketMessage';
  bodyHtml?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  /** When the event was closed */
  createdDate: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  /** When the event was closed */
  failedDate?: Maybe<Scalars['DateTime']>;
  fromAgent?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['Float']>;
  lastSendingError?: Maybe<Scalars['String']>;
  messageId: Scalars['Float'];
  receiverId: Scalars['Float'];
  ruleId?: Maybe<Scalars['Float']>;
  senderId: Scalars['Float'];
  /** When the event was closed */
  sentDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  strippedHtml?: Maybe<Scalars['String']>;
  strippedText?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  ticketId: Scalars['Float'];
  ticketingServiceId: Scalars['Float'];
  uri?: Maybe<Scalars['String']>;
  via?: Maybe<Scalars['String']>;
};

export type PublicApiTicketTag = {
  __typename?: 'PublicApiTicketTag';
  createdDate: Scalars['DateTime'];
  decorationColor?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tagId: Scalars['Float'];
  ticketingServiceId: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  advanceView: AdvanceViewTable;
  biView: BiTable;
  biViewWithTimeSpanDistribution: BiTable;
  /** Cancels current subscription */
  cancelSubscirptionForActiveShop: Scalars['String'];
  fetchAddressSuggestions: Array<GoogleMapsAddressSuggestion>;
  fetchAllScorecardSchedules: Array<ScorecardSchedule>;
  fetchAllScorecardUsers: Array<ScorecardUsers>;
  fetchAllTags: Array<Tag>;
  fetchAllUsers?: Maybe<Array<User>>;
  fetchAssignTagRules: Array<AssignTagRule>;
  fetchExcludeRules: Array<ExcludeRule>;
  fetchExcludeSKURules: Array<ExcludeSkuRule>;
  fetchFCRights: Array<FcRights>;
  fetchFCRoutes: Array<FulfilmentRoutes>;
  fetchFulfillmentCenterHolidays: Array<FulfillmentCenterHoliday>;
  fetchFulfillments: Array<FulfilmentService>;
  fetchFulfillmentsForShop: Array<FulfilmentService>;
  fetchInProcessOnboarding?: Maybe<InProcessOnboarding>;
  fetchInProcessShop?: Maybe<InProcessOnboarding>;
  fetchPricingPlans: Array<PricingPlans>;
  fetchRouteRules: Array<RouteRule>;
  fetchRouteSKURules: Array<RouteSkuRule>;
  fetchSLAOverwrittingForSKURules: Array<SlaOverwrittingSkuRule>;
  fetchSLAOverwrittingRules: Array<SlaOverwrittingRule>;
  fetchScorecardScheduleByFC?: Maybe<ScorecardSchedule>;
  fetchSubscriptionURL?: Maybe<Scalars['String']>;
  fetchUser?: Maybe<User>;
  getActiveShop?: Maybe<Shop>;
  getActiveUserRole?: Maybe<UserRole>;
  /** Returns the distinct SKUs from orders against a shop. */
  getAllSKUs: Array<Scalars['String']>;
  getClientSecretFromPaymentIntent: StripeIntent;
  getCustomerDetails: Customer;
  getFCList: Array<LocationInfo>;
  /** Returns the count of orders basis on the filters. */
  getFilteredOrdersCount: Scalars['Float'];
  getFulfillmentLocationsBySAM: Array<FulfillmentLocation>;
  getMarkedForReroutingCount: Scalars['Float'];
  getMarkedForSLACount: Scalars['Float'];
  getOrderWithProviderInternalId?: Maybe<Order>;
  getOrders: Array<Order>;
  getOrdersCount: Scalars['Float'];
  getOrdersWithFilter: Array<Order>;
  getOrdersWithProviderInternalIds: Array<Order>;
  /** Gets the pricing plan for active shop */
  getPricingPlanForActiveShop: PricingPlans;
  getReasonCodes: Array<ReasonCode>;
  /** Returns the distinct sales channel from orders. */
  getSalesChannel: Array<Scalars['String']>;
  getShipments: Array<Shipment>;
  /** Fetches redirection link for active shop for shopify stores */
  getShopifyPaymentLinkForActiveShop: Scalars['String'];
  getShops?: Maybe<Array<Shop>>;
  getShopsInfoForFC: Array<BrandInfo>;
  isEnabledPartialFulfilment: Scalars['Boolean'];
  listAllCustomReports: Array<CustomReport>;
  listFullfilmentService: Array<FulfilmentService>;
  listSalesChannelRules: Array<SalesChannelRule>;
  listSalesChannels: Array<SalesChannel>;
  reportsOverview: ReportsOverview;
  shopExists?: Maybe<Scalars['Boolean']>;
  shopifyHealthCheck: AccessToken;
  sumaryInfo: SummaryScorecard;
  users: Array<User>;
  verifyCouponAndReturnDiscounts: Array<CouponDiscounts>;
  weeklyColumnValues: WeeklyColumnValues;
  weeklyColumnValuesForBI: WeeklyColumnValues;
};


export type QueryAdvanceViewArgs = {
  fullfilmentCenter?: Maybe<Scalars['String']>;
  readFromCache?: Maybe<Scalars['Boolean']>;
  startingDate: Scalars['DateTime'];
};


export type QueryBiViewArgs = {
  biQueries?: Maybe<Array<BiTableId>>;
  readFromCache?: Maybe<Scalars['Boolean']>;
  startingDate: Scalars['DateTime'];
};


export type QueryBiViewWithTimeSpanDistributionArgs = {
  biQueries?: Maybe<Array<BiTableId>>;
  readFromCache?: Maybe<Scalars['Boolean']>;
  weeklyColumnValues: WeeklyColumnValuesArg;
};


export type QueryFetchAddressSuggestionsArgs = {
  AddressQuery: Scalars['String'];
};


export type QueryFetchFulfillmentCenterHolidaysArgs = {
  handle: Scalars['String'];
  shopName: Scalars['String'];
};


export type QueryFetchFulfillmentsForShopArgs = {
  shopName: Scalars['String'];
};


export type QueryFetchScorecardScheduleByFcArgs = {
  fulfillmentId: Scalars['Float'];
};


export type QueryGetFilteredOrdersCountArgs = {
  fromDate: Scalars['DateTime'];
  fulfillmentCenter?: Maybe<Scalars['String']>;
  salesChannel?: Maybe<Array<Scalars['String']>>;
  skuNumber?: Maybe<Array<Scalars['String']>>;
  slaStatus?: Maybe<Delay>;
  status?: Maybe<OrderStatus>;
  tag?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
};


export type QueryGetOrderWithProviderInternalIdArgs = {
  providerInternalId: Scalars['String'];
};


export type QueryGetOrdersArgs = {
  filter?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  fulfillmentCenter?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  shopDomain?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  toDate: Scalars['DateTime'];
};


export type QueryGetOrdersCountArgs = {
  filter?: Maybe<Scalars['String']>;
  fromDate: Scalars['DateTime'];
  fulfillmentCenter?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  shopDomain?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  toDate: Scalars['DateTime'];
};


export type QueryGetOrdersWithFilterArgs = {
  filter?: Maybe<Scalars['String']>;
  fullfilmentCenter?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
};


export type QueryGetOrdersWithProviderInternalIdsArgs = {
  providerInternalIds: Array<Scalars['String']>;
};


export type QueryGetShipmentsArgs = {
  filter?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  fullfilmentCenter?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  pageSize?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetShopifyPaymentLinkForActiveShopArgs = {
  paymentPlanAlias: Scalars['String'];
};


export type QueryReportsOverviewArgs = {
  fromDate: Scalars['DateTime'];
  fullfilmentCenter?: Maybe<Scalars['String']>;
  toDate: Scalars['DateTime'];
};


export type QueryShopExistsArgs = {
  shopName: Scalars['String'];
  shopType?: Maybe<ShopType>;
};


export type QuerySumaryInfoArgs = {
  fromDate?: Maybe<Scalars['DateTime']>;
  fullfilmentCenter?: Maybe<Scalars['String']>;
  readFromCache?: Maybe<Scalars['Boolean']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryVerifyCouponAndReturnDiscountsArgs = {
  coupon: Scalars['String'];
};


export type QueryWeeklyColumnValuesArgs = {
  startingDate: Scalars['DateTime'];
};


export type QueryWeeklyColumnValuesForBiArgs = {
  endDate: Scalars['DateTime'];
  fortnightDivision?: Maybe<Scalars['Boolean']>;
  includeDays?: Maybe<Scalars['Boolean']>;
  numberOfMonths?: Maybe<Scalars['Float']>;
  numberOfQuarters?: Maybe<Scalars['Float']>;
  numberOfWeeks?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type ReasonCode = {
  __typename?: 'ReasonCode';
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  shopDomain: Scalars['String'];
};

export type ReasonCodeInput = {
  id?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  shopDomain: Scalars['String'];
};

/** history event type */
export enum ReasonType {
  OnTimeFulfilment = 'ON_TIME_FULFILMENT',
  OrderAccuracy = 'ORDER_ACCURACY'
}

export type ReportsOverview = {
  __typename?: 'ReportsOverview';
  dates: Array<Scalars['DateTime']>;
  maxleadTimeForShuppedOrder: Array<Maybe<Scalars['Float']>>;
  overallPerformance: Array<Maybe<Scalars['Float']>>;
  showAllMetrics: Array<Maybe<Scalars['Float']>>;
  slaPercentageAndChange: Array<Maybe<Scalars['Float']>>;
  totalSLAShippedWithinSLA: Array<Maybe<Scalars['Float']>>;
  totalUnitsShipped: Array<Maybe<Scalars['Float']>>;
};

/** Available roles for users */
export enum Role {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type RouteRule = {
  __typename?: 'RouteRule';
  fcId?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  tag: Tag;
};

export type RouteSkuRule = {
  __typename?: 'RouteSKURule';
  fcId?: Maybe<Scalars['Float']>;
  fcName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  sku: Scalars['String'];
};

export type Sla = {
  __typename?: 'SLA';
  cutOffTime: Scalars['DateTime'];
  daysOfOperation: Array<WeekDays>;
  expiration?: Maybe<Scalars['DateTime']>;
  fulfillmentFrequency: Scalars['Float'];
  fulfillmentFrequencyUnit: FulfillmentFrequency;
  onTimeOrderPercent: Scalars['Float'];
  processingDelay: Scalars['Float'];
  processingDelayUnit: DurationType;
};

/** Shipment sla in out status */
export enum SlaInOut {
  InSla = 'IN_SLA',
  OutSla = 'OUT_SLA'
}

export type SlaInput = {
  cutOffTime: Scalars['DateTime'];
  daysOfOperation: Array<WeekDays>;
  expiration?: Maybe<Scalars['DateTime']>;
  fulfillmentFrequency: Scalars['Float'];
  fulfillmentFrequencyUnit: Scalars['String'];
  onTimeOrderPercent: Scalars['Float'];
  processingDelay: Scalars['Float'];
  processingDelayUnit: Scalars['String'];
};

export type SlaOverwrittingRule = {
  __typename?: 'SLAOverwrittingRule';
  date?: Maybe<Scalars['DateTime']>;
  delayInDays: Scalars['Float'];
  id: Scalars['Float'];
  tag: Tag;
  type: Scalars['String'];
};

export type SlaOverwrittingSkuRule = {
  __typename?: 'SLAOverwrittingSKURule';
  date?: Maybe<Scalars['DateTime']>;
  delayInDays: Scalars['Float'];
  id: Scalars['Float'];
  shopId: Scalars['Float'];
  sku: Scalars['String'];
  type: Scalars['String'];
};

/** SLA Overwritting Type */
export enum SlaOverwrittingType {
  Calculated = 'CALCULATED',
  Fixed = 'FIXED'
}

/** SLA table ids */
export enum SlaTableId {
  AverageLt = 'AVERAGE_LT',
  DelayedOrders = 'DELAYED_ORDERS',
  MaxLt = 'MAX_LT',
  MedianLt = 'MEDIAN_LT',
  ShippedBySlaDate = 'SHIPPED_BY_SLA_DATE',
  ShippedOffSlaDate = 'SHIPPED_OFF_SLA_DATE',
  SlaOrderAvailable = 'SLA_ORDER_AVAILABLE',
  SlaPercentage = 'SLA_PERCENTAGE',
  TotalOrdersShipped = 'TOTAL_ORDERS_SHIPPED'
}

export type SlaTableType = {
  __typename?: 'SLATableType';
  title: SlaTableId;
  values: Array<Cells>;
};

export type SalesChannel = {
  __typename?: 'SalesChannel';
  customerId?: Maybe<Scalars['Float']>;
  displayName: Scalars['String'];
  id: Scalars['Float'];
};

export type SalesChannelInput = {
  customerId?: Maybe<Scalars['Float']>;
  displayName: Scalars['String'];
  id: Scalars['Float'];
};

export type SalesChannelRule = {
  __typename?: 'SalesChannelRule';
  comparisonNumber?: Maybe<Scalars['Float']>;
  comparisonType?: Maybe<ComparisonType>;
  id: Scalars['Float'];
  isPrefix: Scalars['Boolean'];
  literal: Scalars['String'];
  ruleType: SalesChannelRuleType;
  salesChannelName?: Maybe<Scalars['String']>;
  scId?: Maybe<Scalars['Float']>;
};

export type SalesChannelRuleObject = {
  comparisonNumber?: Maybe<Scalars['Float']>;
  comparisonType?: Maybe<ComparisonType>;
  id?: Maybe<Scalars['Float']>;
  isPrefix?: Maybe<Scalars['Boolean']>;
  literal?: Maybe<Scalars['String']>;
  ruleType: SalesChannelRuleType;
  salesChannelName?: Maybe<Scalars['String']>;
  scId?: Maybe<Scalars['Float']>;
};

/** Rule type of the sales channel! */
export enum SalesChannelRuleType {
  OrderId = 'ORDER_ID',
  Sku = 'SKU',
  SkuCount = 'SKU_COUNT',
  Tag = 'TAG'
}

export type ScorecardSchedule = {
  __typename?: 'ScorecardSchedule';
  days: Scalars['String'];
  fulfillmentServiceId: Scalars['Float'];
  id: Scalars['Float'];
  time?: Maybe<Scalars['DateTime']>;
  timezone: Scalars['Float'];
};

export type ScorecardScheduleInput = {
  days?: Maybe<Scalars['String']>;
  fulfillmentServiceId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type ScorecardUsers = {
  __typename?: 'ScorecardUsers';
  email: Scalars['String'];
  id: Scalars['Float'];
  scheduleId: Scalars['Float'];
};

export type ScorecardUsersInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  scheduleId?: Maybe<Scalars['Float']>;
};

export type Shipment = {
  __typename?: 'Shipment';
  exclude: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  numberOfLineItems: Scalars['Float'];
  qty: Scalars['Float'];
  sku: Scalars['String'];
  slaDate: Scalars['DateTime'];
  slaStatus: SlaInOut;
  status: ShipmentStatus;
};

/** Shipment status */
export enum ShipmentStatus {
  Open = 'Open',
  Shipped = 'SHIPPED'
}

/** Available shipping window types for Sales Channels */
export enum ShippingWindowType {
  Basic = 'BASIC',
  DaysCount = 'DAYS_COUNT',
  DayOfWeek = 'DAY_OF_WEEK'
}

export type Shop = {
  __typename?: 'Shop';
  access_key?: Maybe<Scalars['String']>;
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iana_timezone?: Maybe<Scalars['String']>;
  isOldInstallation?: Maybe<Scalars['Boolean']>;
  /** Start of the SLA agreement between Shop and FC */
  joined_at?: Maybe<Scalars['DateTime']>;
  locations_count?: Maybe<Scalars['Float']>;
  myshopify_domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  plan_display_name?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  pricingPlan?: Maybe<Scalars['String']>;
  pricingPlanId?: Maybe<Scalars['Float']>;
  primary_locale?: Maybe<Scalars['String']>;
  primary_location_id?: Maybe<Scalars['Float']>;
  provider_internal_id?: Maybe<Scalars['Float']>;
  province?: Maybe<Scalars['String']>;
  province_code?: Maybe<Scalars['String']>;
  shopType: Scalars['String'];
  shop_owner?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  /** End of the SLA agreement between Shop and FC */
  valid_until?: Maybe<Scalars['DateTime']>;
  weight_unit?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ShopFc = {
  __typename?: 'ShopFC';
  address?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  providerInternalId: Scalars['Float'];
};

export type ShopToken = {
  __typename?: 'ShopToken';
  otp: Scalars['String'];
  shop: Scalars['String'];
  type: ShopType;
};

/** Type of the shops */
export enum ShopType {
  Custom = 'CUSTOM',
  Shopify = 'SHOPIFY',
  Skubana = 'SKUBANA'
}

export type StripeIntent = {
  __typename?: 'StripeIntent';
  clientSecret: Scalars['String'];
  coupon: Scalars['String'];
  customerId: Scalars['String'];
};

export type StripeIntentInput = {
  clientSecret?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};

export type SummaryScorecard = {
  __typename?: 'SummaryScorecard';
  oldesOutstandingOrders: Scalars['String'];
  oldesOutstandingOrdersDaysLate: Scalars['String'];
  oldesOutstandingOrdersPercentage: Scalars['Float'];
  totalOrdersShipped: Scalars['Float'];
  totalOrdersShippedLate: Scalars['Float'];
  totalOrdersShippedLatePercentage: Scalars['Float'];
  totalOrdersShippedPercentage: Scalars['Float'];
  totalOrdersShippedWithinSLA: Scalars['Float'];
  totalOrdersShippedWithinSLAPercentage: Scalars['Float'];
  totalUnshippedOrdersOutsideSLA: Scalars['Float'];
  totalUnshippedOrdersOutsideSLAPercentage: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** Trend table ids */
export enum TrendTableId {
  AverageLt = 'AVERAGE_LT',
  MaxLt = 'MAX_LT',
  MedianLt = 'MEDIAN_LT',
  ShippedBySlaDate = 'SHIPPED_BY_SLA_DATE',
  SlaOrderAvailable = 'SLA_ORDER_AVAILABLE',
  SlaPercentage = 'SLA_PERCENTAGE'
}

export type TrendsTableType = {
  __typename?: 'TrendsTableType';
  title: TrendTableId;
  values: Array<Cells>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  currentStep?: Maybe<CurrentSteps>;
  customerId: Scalars['Float'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  role: Role;
  skippedSteps?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
};

export type UserInput = {
  createdAt: Scalars['DateTime'];
  currentStep?: Maybe<CurrentSteps>;
  customerId: Scalars['Float'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  role: Role;
  skippedSteps?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
};

export type UserRole = {
  __typename?: 'UserRole';
  currentStep?: Maybe<CurrentSteps>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role: Role;
  skippedSteps?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
};

export type UserRoleInput = {
  currentStep?: Maybe<CurrentSteps>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  role: Role;
  skippedSteps?: Maybe<Scalars['String']>;
  userType?: Maybe<UserType>;
};

/** User to differentiate between new and existing users */
export enum UserType {
  New = 'NEW',
  Old = 'OLD',
  Shop = 'SHOP'
}

/** The days of the week */
export enum WeekDays {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

export type WeeklyColumnValues = {
  __typename?: 'WeeklyColumnValues';
  current: Array<Scalars['String']>;
  currentInternal: Array<Scalars['String']>;
  daily: Array<Scalars['String']>;
  dailyInternal: Array<Scalars['String']>;
  daysOfWeek: Array<Scalars['String']>;
  fulfillmentServices: Array<Scalars['String']>;
  monthly: Array<Scalars['String']>;
  previous: Array<Scalars['String']>;
  previousInternal: Array<Scalars['String']>;
  quarter: Array<Scalars['String']>;
  sixMonths: Array<Scalars['String']>;
  totalSpan?: Maybe<Scalars['String']>;
  weekly: Array<Scalars['String']>;
};

export type WeeklyColumnValuesArg = {
  current?: Maybe<Array<Scalars['String']>>;
  currentInternal?: Maybe<Array<Scalars['String']>>;
  daily?: Maybe<Array<Scalars['String']>>;
  dailyInternal?: Maybe<Array<Scalars['String']>>;
  daysOfWeek?: Maybe<Array<Scalars['String']>>;
  fulfillmentServices: Array<Scalars['String']>;
  monthly?: Maybe<Array<Scalars['String']>>;
  previous?: Maybe<Array<Scalars['String']>>;
  previousInternal?: Maybe<Array<Scalars['String']>>;
  quarter?: Maybe<Array<Scalars['String']>>;
  sixMonths?: Maybe<Array<Scalars['String']>>;
  totalSpan?: Maybe<Scalars['String']>;
  weekly?: Maybe<Array<Scalars['String']>>;
};

export type AddEventsMutationVariables = Exact<{
  holidays: Array<FulfillmentCenterHolidayInput> | FulfillmentCenterHolidayInput;
  handle: Scalars['String'];
  shopName: Scalars['String'];
}>;


export type AddEventsMutation = { __typename?: 'Mutation', addFulfillmentCentersHolidays: boolean };

export type GetEventsQueryVariables = Exact<{
  handle: Scalars['String'];
  shopName: Scalars['String'];
}>;


export type GetEventsQuery = { __typename?: 'Query', fetchFulfillmentCenterHolidays: Array<{ __typename?: 'FulfillmentCenterHoliday', holiday_name: string, holiday_date: any, id: number }> };

export type GetAdvanceViewDataQueryVariables = Exact<{
  toDate: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
  fullfilmentCenter?: Maybe<Scalars['String']>;
  pageNumber?: Maybe<Scalars['Float']>;
  salesChannelId?: Maybe<Scalars['Float']>;
  filter?: Maybe<Scalars['String']>;
  status: OrderStatus;
  pageSize: Scalars['Float'];
}>;


export type GetAdvanceViewDataQuery = { __typename?: 'Query', getOrdersCount: number, getOrders: Array<{ __typename?: 'Order', orderId?: string | null | undefined, orderName?: string | null | undefined, orderDate?: any | null | undefined, slaDate?: any | null | undefined, orderStatus?: OrderStatus | null | undefined, delay?: number | null | undefined, exlude?: boolean | null | undefined, closedAt?: any | null | undefined, tracking_company?: string | null | undefined, tracking_number?: string | null | undefined, tracking_url?: string | null | undefined, onTimeFulfilmentReason?: string | null | undefined, orderAccuracyReason?: string | null | undefined, orderStatusSLA?: OrderStatusSla | null | undefined, sales_channel?: string | null | undefined, stage?: string | null | undefined, total_price?: string | null | undefined, salesChannelInternal?: string | null | undefined, lineitems: Array<{ __typename?: 'LineItem', qty: number } | null | undefined>, tags?: Array<{ __typename?: 'Tag', name: string, type: string } | null | undefined> | null | undefined, exclude_reasons?: Array<{ __typename?: 'ExcludeReason', secondary_reason: string, primary_reason: string, comment: string } | null | undefined> | null | undefined }> };

export type GetSingleOrderQueryVariables = Exact<{
  providerInternalId: Scalars['String'];
}>;


export type GetSingleOrderQuery = { __typename?: 'Query', getOrderWithProviderInternalId?: { __typename?: 'Order', orderId?: string | null | undefined, orderName?: string | null | undefined, qty?: number | null | undefined, orderDate?: any | null | undefined, slaDate?: any | null | undefined, orderStatus?: OrderStatus | null | undefined, delay?: number | null | undefined, exlude?: boolean | null | undefined, closedAt?: any | null | undefined, tracking_company?: string | null | undefined, tracking_number?: string | null | undefined, tracking_url?: string | null | undefined, shipping_name?: string | null | undefined, shipping_phone?: string | null | undefined, shipping_email?: string | null | undefined, shipping_address?: string | null | undefined, billing_name?: string | null | undefined, billing_phone?: string | null | undefined, billing_email?: string | null | undefined, billing_address?: string | null | undefined, total_price?: string | null | undefined, total_discount?: string | null | undefined, shipping_cost?: string | null | undefined, total_taxes?: string | null | undefined, lineitems: Array<{ __typename?: 'LineItem', name: string, qty: number, unit_price: string, weight: number, sku: string } | null | undefined>, tags?: Array<{ __typename?: 'Tag', type: string, name: string } | null | undefined> | null | undefined, exclude_reasons?: Array<{ __typename?: 'ExcludeReason', secondary_reason: string, primary_reason: string, comment: string, happened_at: any, is_excluded?: boolean | null | undefined, first_name: string, last_name: string, reason_type?: ReasonType | null | undefined, is_assigned?: boolean | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type GetMultipleOrdersQueryVariables = Exact<{
  providerInternalIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetMultipleOrdersQuery = { __typename?: 'Query', getOrdersWithProviderInternalIds: Array<{ __typename?: 'Order', tags?: Array<{ __typename?: 'Tag', name: string, type: string } | null | undefined> | null | undefined, lineitems: Array<{ __typename?: 'LineItem', sku: string } | null | undefined> }> };

export type IsEnabledPartialFulfilmentQueryVariables = Exact<{ [key: string]: never; }>;


export type IsEnabledPartialFulfilmentQuery = { __typename?: 'Query', isEnabledPartialFulfilment: boolean };

export type GetMarkedForSlaCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarkedForSlaCountQuery = { __typename?: 'Query', getMarkedForSLACount: number };

export type GetMarkedForReroutingCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarkedForReroutingCountQuery = { __typename?: 'Query', getMarkedForReroutingCount: number };

export type EnablePartialFulfimentMutationVariables = Exact<{ [key: string]: never; }>;


export type EnablePartialFulfimentMutation = { __typename?: 'Mutation', enablePartialFulfiment: boolean };

export type DisablePartialFulfimentMutationVariables = Exact<{ [key: string]: never; }>;


export type DisablePartialFulfimentMutation = { __typename?: 'Mutation', disablePartialFulfiment: boolean };

export type AddTagsToOrdersMutationVariables = Exact<{
  provider_internal_ids: Array<Scalars['String']> | Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddTagsToOrdersMutation = { __typename?: 'Mutation', addTags: boolean };

export type RemoveTagsToOrdersMutationVariables = Exact<{
  provider_internal_ids: Array<Scalars['String']> | Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveTagsToOrdersMutation = { __typename?: 'Mutation', removeTagsFromOrders: boolean };

export type CreateSlaOverwrittingRuleMutationVariables = Exact<{
  date: Scalars['DateTime'];
  delayInDays: Scalars['Float'];
  type: SlaOverwrittingType;
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateSlaOverwrittingRuleMutation = { __typename?: 'Mutation', createSLAOverwrittingRule: boolean };

export type CreateSlaOverwrittingSkuRuleMutationVariables = Exact<{
  date: Scalars['DateTime'];
  delayInDays: Scalars['Float'];
  type: SlaOverwrittingType;
  skus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateSlaOverwrittingSkuRuleMutation = { __typename?: 'Mutation', createSLAOverwrittingSKURule: boolean };

export type CreateAssignRuleMutationVariables = Exact<{
  literal: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateAssignRuleMutation = { __typename?: 'Mutation', createAssignRule: boolean };

export type ExcludeOrReRouteOrdersMutationVariables = Exact<{
  routeToFC?: Maybe<Scalars['String']>;
  markReRoute?: Maybe<Scalars['Boolean']>;
  newSLADate?: Maybe<Scalars['DateTime']>;
  primaryReason?: Maybe<PrimaryExcludeReason>;
  slaStatus?: Maybe<Delay>;
  orderStatus?: Maybe<OrderStatus>;
  salesChannel?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  skuNumber?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tag?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  toDate: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
  fullfilmentCenter: Scalars['String'];
  secondaryReason?: Maybe<Scalars['String']>;
}>;


export type ExcludeOrReRouteOrdersMutation = { __typename?: 'Mutation', excludeOrReRouteOrders: boolean };

export type GetFilteredOrdersCountQueryVariables = Exact<{
  slaStatus: Delay;
  orderStatus?: Maybe<OrderStatus>;
  salesChannel?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  skuNumber?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  tag?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  toDate: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
  fullfilmentCenter: Scalars['String'];
}>;


export type GetFilteredOrdersCountQuery = { __typename?: 'Query', getFilteredOrdersCount: number };

export type GetAllSkUsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSkUsQuery = { __typename?: 'Query', getAllSKUs: Array<string> };

export type BiViewQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  biQueries: Array<BiTableId> | BiTableId;
  readFromCache?: Maybe<Scalars['Boolean']>;
}>;


export type BiViewQuery = { __typename?: 'Query', biView: { __typename?: 'BITable', trends: Array<{ __typename?: 'BIColumn', title: BiTableId, columns: Array<{ __typename?: 'Column', name: string, cells: Array<{ __typename?: 'Cells', cellId: string, value?: number | null | undefined }> }> }>, weeklyColumns: { __typename?: 'WeeklyColumnValues', dailyInternal: Array<string>, current: Array<string>, previous: Array<string>, weekly: Array<string>, monthly: Array<string>, quarter: Array<string> } } };

export type GetCustomerDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerDetailsQuery = { __typename?: 'Query', getCustomerDetails: { __typename?: 'Customer', comp_name: string, stripeCustomerId: string, pricingPlan: string, paymentStatus: string, failureReason: string, dueDate: any } };

export type ListAllCustomReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllCustomReportsQuery = { __typename?: 'Query', listAllCustomReports: Array<{ __typename?: 'CustomReport', analyticsType: string, comparisonType: string, end?: any | null | undefined, id?: number | null | undefined, name: string, periodType: string, start?: any | null | undefined, type: string, description?: string | null | undefined }> };

export type SaveCustomReportsMutationVariables = Exact<{
  report: CustomReportInput;
}>;


export type SaveCustomReportsMutation = { __typename?: 'Mutation', saveCustomReports: boolean };

export type DeleteCustomReportsMutationVariables = Exact<{
  reportId: Scalars['Float'];
}>;


export type DeleteCustomReportsMutation = { __typename?: 'Mutation', deleteCustomReports: boolean };

export type WeeklyColumnValuesForBiQueryVariables = Exact<{
  endDate: Scalars['DateTime'];
  includeDays?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
}>;


export type WeeklyColumnValuesForBiQuery = { __typename?: 'Query', weeklyColumnValuesForBI: { __typename?: 'WeeklyColumnValues', weekly: Array<string>, totalSpan?: string | null | undefined, monthly: Array<string>, daysOfWeek: Array<string>, dailyInternal: Array<string> } };

export type BiViewWithTimeSpanDistributionQueryVariables = Exact<{
  weeklyColumnValues: WeeklyColumnValuesArg;
  biQueries?: Maybe<Array<BiTableId> | BiTableId>;
}>;


export type BiViewWithTimeSpanDistributionQuery = { __typename?: 'Query', biViewWithTimeSpanDistribution: { __typename?: 'BITable', trends: Array<{ __typename?: 'BIColumn', title: BiTableId, columns: Array<{ __typename?: 'Column', handle: string, name: string, cells: Array<{ __typename?: 'Cells', cellId: string, threshold?: number | null | undefined, value?: number | null | undefined, nestedCell?: Array<{ __typename?: 'Cells', cellId: string, threshold?: number | null | undefined, value?: number | null | undefined }> | null | undefined }> }> }>, weeklyColumns: { __typename?: 'WeeklyColumnValues', weekly: Array<string>, totalSpan?: string | null | undefined } } };

export type DeleteEventMutationVariables = Exact<{
  ids: Array<Scalars['Float']> | Scalars['Float'];
  handle: Scalars['String'];
  shopName: Scalars['String'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteFulfillmentCentersHolidays: boolean };

export type ExcludeOrderMutationVariables = Exact<{
  exclude: Scalars['Boolean'];
  orderIds: Array<Scalars['String']> | Scalars['String'];
  comment: Scalars['String'];
  primaryReason: Scalars['String'];
  secondaryReason: Scalars['String'];
}>;


export type ExcludeOrderMutation = { __typename?: 'Mutation', excludeOrder: boolean };

export type RerouteOrdersMutationVariables = Exact<{
  fc_handle: Scalars['String'];
  orderIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type RerouteOrdersMutation = { __typename?: 'Mutation', rerouteOrders: boolean };

export type CreateExcludeRuleMutationVariables = Exact<{
  exclude: Scalars['Boolean'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateExcludeRuleMutation = { __typename?: 'Mutation', createExcludeRule: boolean };

export type CreateExcludeSkuRuleMutationVariables = Exact<{
  skus: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateExcludeSkuRuleMutation = { __typename?: 'Mutation', createExcludeSKURule: boolean };

export type CreateRouteRuleMutationVariables = Exact<{
  fc_handle: Scalars['String'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateRouteRuleMutation = { __typename?: 'Mutation', createRouteRule: boolean };

export type CreateRouteSkuRuleMutationVariables = Exact<{
  fc_handle: Scalars['String'];
  skus: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateRouteSkuRuleMutation = { __typename?: 'Mutation', createRouteSKURule: boolean };

export type RemoveExcludeRuleMutationVariables = Exact<{
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveExcludeRuleMutation = { __typename?: 'Mutation', removeExcludeRule: boolean };

export type RemoveRouteRuleMutationVariables = Exact<{
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveRouteRuleMutation = { __typename?: 'Mutation', removeRouteRule: boolean };

export type RemoveRouteSkuRuleMutationVariables = Exact<{
  skus: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveRouteSkuRuleMutation = { __typename?: 'Mutation', removeRouteSKURule: boolean };

export type OverwriteOrdersSlaMutationVariables = Exact<{
  slaDate: Scalars['DateTime'];
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type OverwriteOrdersSlaMutation = { __typename?: 'Mutation', overwriteOrdersSLA: boolean };

export type FetchExcludeRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchExcludeRulesQuery = { __typename?: 'Query', fetchExcludeRules: Array<{ __typename?: 'ExcludeRule', id: number, exclude: boolean, tag: { __typename?: 'Tag', name: string, type: string } }> };

export type FetchExcludeSkuRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchExcludeSkuRulesQuery = { __typename?: 'Query', fetchExcludeSKURules: Array<{ __typename?: 'ExcludeSKURule', id: number, sku: string, shopDomain: string, customerId: number }> };

export type RemoveExcludeSkuRuleMutationVariables = Exact<{
  skus: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveExcludeSkuRuleMutation = { __typename?: 'Mutation', removeExcludeSKURule: boolean };

export type FetchSlaOverwrittingRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSlaOverwrittingRulesQuery = { __typename?: 'Query', fetchSLAOverwrittingRules: Array<{ __typename?: 'SLAOverwrittingRule', id: number, type: string, date?: any | null | undefined, delayInDays: number, tag: { __typename?: 'Tag', name: string, type: string } }> };

export type RemoveSlaOverwrittingRuleMutationVariables = Exact<{
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveSlaOverwrittingRuleMutation = { __typename?: 'Mutation', removeSLAOverwrittingRule: boolean };

export type FetchSlaOverwrittingForSkuRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSlaOverwrittingForSkuRulesQuery = { __typename?: 'Query', fetchSLAOverwrittingForSKURules: Array<{ __typename?: 'SLAOverwrittingSKURule', id: number, sku: string, type: string, date?: any | null | undefined, delayInDays: number, shopId: number }> };

export type RemoveSlaOverwrittingSkuRuleMutationVariables = Exact<{
  skus: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveSlaOverwrittingSkuRuleMutation = { __typename?: 'Mutation', removeSLAOverwrittingSKURule: boolean };

export type FetchAssignTagRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAssignTagRulesQuery = { __typename?: 'Query', fetchAssignTagRules: Array<{ __typename?: 'AssignTagRule', id: number, literal: string, tag: { __typename?: 'Tag', name: string, type: string } }> };

export type RemoveAssignRuleMutationVariables = Exact<{
  tags: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveAssignRuleMutation = { __typename?: 'Mutation', removeAssignRule: boolean };

export type FetchRouteRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchRouteRulesQuery = { __typename?: 'Query', fetchRouteRules: Array<{ __typename?: 'RouteRule', id: number, fcId?: number | null | undefined, tag: { __typename?: 'Tag', name: string, type: string } }> };

export type FetchRouteSkuRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchRouteSkuRulesQuery = { __typename?: 'Query', fetchRouteSKURules: Array<{ __typename?: 'RouteSKURule', fcId?: number | null | undefined, id: number, sku: string, fcName?: string | null | undefined }> };

export type FetchAllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllTagsQuery = { __typename?: 'Query', fetchAllTags: Array<{ __typename?: 'Tag', name: string, type: string }> };

export type GetSalesChannelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesChannelQuery = { __typename?: 'Query', getSalesChannel: Array<string> };

export type GetFulfillmentLocationsBySamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFulfillmentLocationsBySamQuery = { __typename?: 'Query', getFulfillmentLocationsBySAM: Array<{ __typename?: 'FulfillmentLocation', active: boolean, address?: string | null | undefined, external_data_source: string, handle?: string | null | undefined, id: string, legacy_fulfillment_service_id?: number | null | undefined, shop_verified?: boolean | null | undefined, shop_domain: string, name: string, timezone?: string | null | undefined, verification_status: string, sla?: { __typename?: 'SLA', cutOffTime: any, daysOfOperation: Array<WeekDays>, expiration?: any | null | undefined, fulfillmentFrequency: number, fulfillmentFrequencyUnit: FulfillmentFrequency, onTimeOrderPercent: number, processingDelay: number, processingDelayUnit: DurationType } | null | undefined }> };

export type VerifyLocationSlaWithGoogleMapsReferenceMutationVariables = Exact<{
  geo: GeoLocationByGoogleMapsPlaceId;
  sla: SlaInput;
  locationId: Scalars['String'];
  updatedName?: Maybe<Scalars['String']>;
}>;


export type VerifyLocationSlaWithGoogleMapsReferenceMutation = { __typename?: 'Mutation', verifyLocationSLAWithGoogleMapsReference: { __typename?: 'FulfillmentLocation', active: boolean, address?: string | null | undefined, external_data_source: string, handle?: string | null | undefined, id: string, legacy_fulfillment_service_id?: number | null | undefined, shop_verified?: boolean | null | undefined, shop_domain: string, name: string, timezone?: string | null | undefined, verification_status: string, sla?: { __typename?: 'SLA', cutOffTime: any, daysOfOperation: Array<WeekDays>, expiration?: any | null | undefined, fulfillmentFrequency: number, fulfillmentFrequencyUnit: FulfillmentFrequency, onTimeOrderPercent: number, processingDelay: number, processingDelayUnit: DurationType } | null | undefined } };

export type DeactivateFulfillmentLocationMutationVariables = Exact<{
  locationId: Scalars['String'];
}>;


export type DeactivateFulfillmentLocationMutation = { __typename?: 'Mutation', deactivateFulfillmentLocation: { __typename?: 'FulfillmentLocation', id: string, external_data_source: string, shop_domain: string, name: string, handle?: string | null | undefined, address?: string | null | undefined, supports_inventory_activity_tracking: boolean, supports_courier_tracking: boolean, supports_fulfillment_activity_tracking: boolean, verification_status: string, timezone?: string | null | undefined, legacy_fulfillment_service_id?: number | null | undefined, shop_verified?: boolean | null | undefined, active: boolean, sla?: { __typename?: 'SLA', daysOfOperation: Array<WeekDays>, cutOffTime: any, processingDelay: number, processingDelayUnit: DurationType, fulfillmentFrequency: number, fulfillmentFrequencyUnit: FulfillmentFrequency, onTimeOrderPercent: number, expiration?: any | null | undefined } | null | undefined } };

export type EnableFulfillmentLocationMutationVariables = Exact<{
  locationInput: Scalars['String'];
}>;


export type EnableFulfillmentLocationMutation = { __typename?: 'Mutation', enableFulfillmentLocation: { __typename?: 'FulfillmentLocation', id: string, external_data_source: string, shop_domain: string, name: string, handle?: string | null | undefined, address?: string | null | undefined, supports_inventory_activity_tracking: boolean, supports_courier_tracking: boolean, supports_fulfillment_activity_tracking: boolean, verification_status: string, timezone?: string | null | undefined, legacy_fulfillment_service_id?: number | null | undefined, shop_verified?: boolean | null | undefined, active: boolean, sla?: { __typename?: 'SLA', daysOfOperation: Array<WeekDays>, cutOffTime: any, processingDelay: number, processingDelayUnit: DurationType, fulfillmentFrequency: number, fulfillmentFrequencyUnit: FulfillmentFrequency, onTimeOrderPercent: number, expiration?: any | null | undefined } | null | undefined } };

export type FetchAddressSuggestionsQueryVariables = Exact<{
  addressQuery: Scalars['String'];
}>;


export type FetchAddressSuggestionsQuery = { __typename?: 'Query', fetchAddressSuggestions: Array<{ __typename?: 'GoogleMapsAddressSuggestion', place_id: string, suggestedAddress: string, main_text: string, secondary_text: string }> };

export type InsertFcMutationVariables = Exact<{
  warehouseName: Scalars['String'];
}>;


export type InsertFcMutation = { __typename?: 'Mutation', insertFulfillmentService: boolean };

export type DeleteFulfillmentMutationVariables = Exact<{
  deleteFulfillmentId: Scalars['Float'];
}>;


export type DeleteFulfillmentMutation = { __typename?: 'Mutation', deleteFulfillment: boolean };

export type UpdateFulfillmentServiceMutationVariables = Exact<{
  fcData: Array<FulfilmentServiceInput> | FulfilmentServiceInput;
}>;


export type UpdateFulfillmentServiceMutation = { __typename?: 'Mutation', updateFulfillmentService: boolean };

export type ShopifyHealthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopifyHealthCheckQuery = { __typename?: 'Query', shopifyHealthCheck: { __typename?: 'AccessToken', access_key?: string | null | undefined, domain?: string | null | undefined } };

export type DisableFulfillmentMutationVariables = Exact<{
  disableFulfillmentId: Scalars['Float'];
}>;


export type DisableFulfillmentMutation = { __typename?: 'Mutation', disableFulfillment: boolean };

export type EnableFulfillmentMutationVariables = Exact<{
  enableFulfillmentId: Scalars['Float'];
}>;


export type EnableFulfillmentMutation = { __typename?: 'Mutation', enableFulfillment: boolean };

export type FetchFcRightsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchFcRightsQuery = { __typename?: 'Query', fetchFCRights: Array<{ __typename?: 'FCRights', id: number, fulfillmentServiceId: number, userId: number, customerId: number }> };

export type UpdateFcRightsMutationVariables = Exact<{
  userIds: Array<Scalars['Float']> | Scalars['Float'];
  fulfillmentId: Scalars['Float'];
}>;


export type UpdateFcRightsMutation = { __typename?: 'Mutation', updateFCRights: boolean };

export type GetFullfilmentCenterListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFullfilmentCenterListQuery = { __typename?: 'Query', listFullfilmentService: Array<{ __typename?: 'FulfilmentService', id?: number | null | undefined, warehouseName?: string | null | undefined, handle?: string | null | undefined, fcSettings: Array<{ __typename?: 'FulfilmentServiceSettings', contractPercent?: number | null | undefined, shopDomain: string }> }>, fetchFCRoutes: Array<{ __typename?: 'FulfilmentRoutes', id: number, address?: string | null | undefined, fulfilmentServiceId: number, priority: number, fcHandle: string }> };

export type FetchFcRoutesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchFcRoutesQuery = { __typename?: 'Query', fetchFCRoutes: Array<{ __typename?: 'FulfilmentRoutes', id: number, address?: string | null | undefined, fulfilmentServiceId: number, priority: number, fcHandle: string }>, fetchFulfillments: Array<{ __typename?: 'FulfilmentService', id?: number | null | undefined, warehouseName?: string | null | undefined, service_name?: string | null | undefined }> };

export type SendHelpEmailMutationVariables = Exact<{
  userEmail: Scalars['String'];
  subject: Scalars['String'];
  content: Scalars['String'];
}>;


export type SendHelpEmailMutation = { __typename?: 'Mutation', sendHelpEmail: boolean };

export type UpdateRoutesMutationVariables = Exact<{
  routes: FulfilmentRoutesUpdate;
}>;


export type UpdateRoutesMutation = { __typename?: 'Mutation', updateFCRoutes: boolean };

export type FetchPricingPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchPricingPlansQuery = { __typename?: 'Query', fetchPricingPlans: Array<{ __typename?: 'PricingPlans', name: string, monthlyOrders: number, overallOrders: number, scorecardUsersLimit: number, usersLimit: number, alias: string }> };

export type UpdatePlanForCustomerMutationVariables = Exact<{
  planName: Scalars['String'];
}>;


export type UpdatePlanForCustomerMutation = { __typename?: 'Mutation', updatePlanForCustomer: boolean };

export type MarkShopActiveMutationVariables = Exact<{
  shopName: Scalars['String'];
  shopType: ShopType;
}>;


export type MarkShopActiveMutation = { __typename?: 'Mutation', markShopActive: boolean };

export type GetPricingPlanForActiveShopQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPricingPlanForActiveShopQuery = { __typename?: 'Query', getPricingPlanForActiveShop: { __typename?: 'PricingPlans', id: number, name: string, alias: string, monthlyOrders: number, overallOrders: number, scorecardDaysLimit: number, scorecardUsersLimit: number, usersLimit: number, price: number } };

export type UpsertReasonCodesMutationVariables = Exact<{
  reasonCodes: Array<ReasonCodeInput> | ReasonCodeInput;
}>;


export type UpsertReasonCodesMutation = { __typename?: 'Mutation', upsertReasonCodes: boolean };

export type DeleteReasonCodesMutationVariables = Exact<{
  reasonCodes: Array<ReasonCodeInput> | ReasonCodeInput;
}>;


export type DeleteReasonCodesMutation = { __typename?: 'Mutation', deleteReasonCodes: boolean };

export type AssignReasonCodeToOrdersMutationVariables = Exact<{
  orderIds: Array<Scalars['String']> | Scalars['String'];
  reasonCode: ReasonCodeInput;
}>;


export type AssignReasonCodeToOrdersMutation = { __typename?: 'Mutation', assignReasonCodeToOrders: boolean };

export type UnassignReasonCodeToOrdersMutationVariables = Exact<{
  orderIds: Array<Scalars['String']> | Scalars['String'];
  reasonCode: ReasonCodeInput;
}>;


export type UnassignReasonCodeToOrdersMutation = { __typename?: 'Mutation', unassignReasonCodeToOrders: boolean };

export type GetReasonCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReasonCodesQuery = { __typename?: 'Query', getReasonCodes: Array<{ __typename?: 'ReasonCode', id?: number | null | undefined, name: string, shopDomain: string }> };

export type ListSalesChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSalesChannelsQuery = { __typename?: 'Query', listSalesChannels: Array<{ __typename?: 'SalesChannel', displayName: string, id: number }> };

export type UpsertSalesChannelMutationVariables = Exact<{
  salesChannel: SalesChannelInput;
}>;


export type UpsertSalesChannelMutation = { __typename?: 'Mutation', upsertSalesChannel: boolean };

export type RemoveSalesChannelMutationVariables = Exact<{
  salesChannel: SalesChannelInput;
}>;


export type RemoveSalesChannelMutation = { __typename?: 'Mutation', removeSalesChannel: boolean };

export type ListSalesChannelRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSalesChannelRulesQuery = { __typename?: 'Query', listSalesChannelRules: Array<{ __typename?: 'SalesChannelRule', comparisonNumber?: number | null | undefined, comparisonType?: ComparisonType | null | undefined, id: number, isPrefix: boolean, literal: string, ruleType: SalesChannelRuleType, scId?: number | null | undefined, salesChannelName?: string | null | undefined }> };

export type UpsertSalesChannelRuleMutationVariables = Exact<{
  salesChannelRule: SalesChannelRuleObject;
}>;


export type UpsertSalesChannelRuleMutation = { __typename?: 'Mutation', upsertSalesChannelRule: boolean };

export type RemoveSalesChannelRuleMutationVariables = Exact<{
  salesChannelRule: SalesChannelRuleObject;
}>;


export type RemoveSalesChannelRuleMutation = { __typename?: 'Mutation', removeSalesChannelRule: boolean };

export type ScorecardQueryVariables = Exact<{
  ordersToDate: Scalars['DateTime'];
  ordersFromDate: Scalars['DateTime'];
  fullfilmentCenter?: Maybe<Scalars['String']>;
  reportsOverviewToDate: Scalars['DateTime'];
  reportsOverviewFromDate: Scalars['DateTime'];
  reportsOverviewFullfilmentCenter?: Maybe<Scalars['String']>;
  sumaryInfoToDate?: Maybe<Scalars['DateTime']>;
  sumaryInfoFullfilmentCenter?: Maybe<Scalars['String']>;
  sumaryInfoFromDate?: Maybe<Scalars['DateTime']>;
  status: OrderStatus;
}>;


export type ScorecardQuery = { __typename?: 'Query', getOrders: Array<{ __typename?: 'Order', orderId?: string | null | undefined, orderName?: string | null | undefined, qty?: number | null | undefined, orderDate?: any | null | undefined, slaDate?: any | null | undefined, orderStatus?: OrderStatus | null | undefined, delay?: number | null | undefined, shipping_address?: string | null | undefined }>, reportsOverview: { __typename?: 'ReportsOverview', showAllMetrics: Array<number | null | undefined>, totalUnitsShipped: Array<number | null | undefined>, totalSLAShippedWithinSLA: Array<number | null | undefined>, overallPerformance: Array<number | null | undefined>, maxleadTimeForShuppedOrder: Array<number | null | undefined>, slaPercentageAndChange: Array<number | null | undefined> }, sumaryInfo: { __typename?: 'SummaryScorecard', totalOrdersShipped: number, totalOrdersShippedPercentage: number, totalOrdersShippedWithinSLA: number, totalOrdersShippedWithinSLAPercentage: number, totalOrdersShippedLate: number, totalOrdersShippedLatePercentage: number, oldesOutstandingOrders: string, oldesOutstandingOrdersPercentage: number, oldesOutstandingOrdersDaysLate: string, totalUnshippedOrdersOutsideSLA: number, totalUnshippedOrdersOutsideSLAPercentage: number } };

export type GetScorecardSummaryQueryVariables = Exact<{
  toDate?: Maybe<Scalars['DateTime']>;
  fromDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetScorecardSummaryQuery = { __typename?: 'Query', sumaryInfo: { __typename?: 'SummaryScorecard', totalOrdersShipped: number, totalOrdersShippedPercentage: number, totalOrdersShippedWithinSLA: number, totalOrdersShippedWithinSLAPercentage: number, totalOrdersShippedLate: number, totalOrdersShippedLatePercentage: number, oldesOutstandingOrders: string, oldesOutstandingOrdersPercentage: number, oldesOutstandingOrdersDaysLate: string, totalUnshippedOrdersOutsideSLA: number, totalUnshippedOrdersOutsideSLAPercentage: number } };

export type GetReportsOverviewQueryVariables = Exact<{
  toDate: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
}>;


export type GetReportsOverviewQuery = { __typename?: 'Query', reportsOverview: { __typename?: 'ReportsOverview', showAllMetrics: Array<number | null | undefined>, totalUnitsShipped: Array<number | null | undefined>, totalSLAShippedWithinSLA: Array<number | null | undefined>, overallPerformance: Array<number | null | undefined>, maxleadTimeForShuppedOrder: Array<number | null | undefined>, slaPercentageAndChange: Array<number | null | undefined> } };

export type GetOrdersQueryVariables = Exact<{
  toDate: Scalars['DateTime'];
  fromDate: Scalars['DateTime'];
  status: OrderStatus;
}>;


export type GetOrdersQuery = { __typename?: 'Query', getOrders: Array<{ __typename?: 'Order', orderId?: string | null | undefined, orderName?: string | null | undefined, qty?: number | null | undefined, orderDate?: any | null | undefined, slaDate?: any | null | undefined, orderStatus?: OrderStatus | null | undefined, delay?: number | null | undefined }> };

export type FetchScorecardScheduleByFcQueryVariables = Exact<{
  fulfillmentId: Scalars['Float'];
}>;


export type FetchScorecardScheduleByFcQuery = { __typename?: 'Query', fetchScorecardScheduleByFC?: { __typename?: 'ScorecardSchedule', id: number, days: string, time?: any | null | undefined, fulfillmentServiceId: number } | null | undefined };

export type FetchAllScorecardSchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllScorecardSchedulesQuery = { __typename?: 'Query', fetchAllScorecardSchedules: Array<{ __typename?: 'ScorecardSchedule', id: number, days: string, time?: any | null | undefined, timezone: number, fulfillmentServiceId: number }> };

export type UpdateScorecardScheduleMutationVariables = Exact<{
  schedule: ScorecardScheduleInput;
}>;


export type UpdateScorecardScheduleMutation = { __typename?: 'Mutation', updateScorecardSchedule: number };

export type FetchAllScorecardUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllScorecardUsersQuery = { __typename?: 'Query', fetchAllScorecardUsers: Array<{ __typename?: 'ScorecardUsers', id: number, email: string, scheduleId: number }> };

export type UpdateScorecardUsersMutationVariables = Exact<{
  scheduleId: Scalars['Float'];
  emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateScorecardUsersMutation = { __typename?: 'Mutation', updateScorecardUsers: boolean };

export type VerifyTokenMutationVariables = Exact<{
  shopType: Scalars['String'];
  token: Scalars['String'];
  shop: Scalars['String'];
}>;


export type VerifyTokenMutation = { __typename?: 'Mutation', verifyToken?: boolean | null | undefined };

export type AddSkubanaShopMutationVariables = Exact<{
  cid: Scalars['String'];
  code: Scalars['String'];
}>;


export type AddSkubanaShopMutation = { __typename?: 'Mutation', addSkubanaShop: { __typename?: 'ShopToken', shop: string, type: ShopType, otp: string } };

export type SubscriptionUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionUrlQuery = { __typename?: 'Query', fetchSubscriptionURL?: string | null | undefined };

export type GetStandardViewQueryVariables = Exact<{
  sumaryInfoToDate2?: Maybe<Scalars['DateTime']>;
  sumaryInfoFromDate2?: Maybe<Scalars['DateTime']>;
  sumaryInfoFullfilmentCenter2?: Maybe<Scalars['String']>;
  reportsOverviewToDate2: Scalars['DateTime'];
  reportsOverviewFromDate2: Scalars['DateTime'];
  reportsOverviewFullfilmentCenter2?: Maybe<Scalars['String']>;
}>;


export type GetStandardViewQuery = { __typename?: 'Query', sumaryInfo: { __typename?: 'SummaryScorecard', totalOrdersShipped: number, totalOrdersShippedPercentage: number, totalOrdersShippedWithinSLA: number, totalOrdersShippedWithinSLAPercentage: number, totalOrdersShippedLate: number, totalOrdersShippedLatePercentage: number, oldesOutstandingOrders: string, oldesOutstandingOrdersPercentage: number, oldesOutstandingOrdersDaysLate: string, totalUnshippedOrdersOutsideSLAPercentage: number, totalUnshippedOrdersOutsideSLA: number }, reportsOverview: { __typename?: 'ReportsOverview', showAllMetrics: Array<number | null | undefined>, totalUnitsShipped: Array<number | null | undefined>, totalSLAShippedWithinSLA: Array<number | null | undefined>, overallPerformance: Array<number | null | undefined>, maxleadTimeForShuppedOrder: Array<number | null | undefined>, slaPercentageAndChange: Array<number | null | undefined>, dates: Array<any> } };

export type GetScorecardViewQueryVariables = Exact<{
  FullfilmentCenter?: Maybe<Scalars['String']>;
  startingDate: Scalars['DateTime'];
  advanceViewStartingDate2: Scalars['DateTime'];
  readFromCache?: Maybe<Scalars['Boolean']>;
}>;


export type GetScorecardViewQuery = { __typename?: 'Query', weeklyColumnValues: { __typename?: 'WeeklyColumnValues', current: Array<string>, previous: Array<string>, weekly: Array<string>, monthly: Array<string>, quarter: Array<string> }, advanceView: { __typename?: 'AdvanceViewTable', sla: Array<{ __typename?: 'SLATableType', title: SlaTableId, values: Array<{ __typename?: 'Cells', cellId: string, value?: number | null | undefined, threshold?: number | null | undefined }> }>, trends: Array<{ __typename?: 'TrendsTableType', title: TrendTableId, values: Array<{ __typename?: 'Cells', cellId: string, value?: number | null | undefined, threshold?: number | null | undefined }> }> } };

export type GetPaymentIntentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentIntentQuery = { __typename?: 'Query', getClientSecretFromPaymentIntent: { __typename?: 'StripeIntent', clientSecret: string, customerId: string } };

export type VerifyCouponAndReturnDiscountsQueryVariables = Exact<{
  coupon: Scalars['String'];
}>;


export type VerifyCouponAndReturnDiscountsQuery = { __typename?: 'Query', verifyCouponAndReturnDiscounts: Array<{ __typename?: 'CouponDiscounts', planName: string, discount: number }> };

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = { __typename?: 'Query', fetchUser?: { __typename?: 'User', customerId: number, email: string, role: Role, firstName?: string | null | undefined, lastName?: string | null | undefined, currentStep?: CurrentSteps | null | undefined, skippedSteps?: string | null | undefined } | null | undefined };

export type FulfillmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type FulfillmentsQuery = { __typename?: 'Query', fetchFulfillments: Array<{ __typename?: 'FulfilmentService', id?: number | null | undefined, warehouseName?: string | null | undefined, routingSoftware?: string | null | undefined, address?: string | null | undefined, status?: string | null | undefined, handle?: string | null | undefined, daylightFrom?: any | null | undefined, daylightOffset: number, daylightTo?: any | null | undefined, daylightEnabled: boolean, fromTime?: any | null | undefined, toTime?: any | null | undefined, fcSettings: Array<{ __typename?: 'FulfilmentServiceSettings', id?: number | null | undefined, delayOrders: boolean, delayDuration?: number | null | undefined, delayDurationType: DurationType, contractPercent?: number | null | undefined, days?: string | null | undefined, timezone?: number | null | undefined, start: any, end?: any | null | undefined, cutoffDate?: any | null | undefined, ordersBefore?: number | null | undefined, ordersAfter?: number | null | undefined, fcId?: number | null | undefined, shopDomain: string, orderAccuracyPercent?: number | null | undefined, prepDuration?: number | null | undefined, prepDurationType: DurationType, salesChannelId: number, shippingPeriodCount: number, shippingPeriodType: DurationType, dayOfShipping: DayOfWeek, shippingWindowType: ShippingWindowType }> }> };

export type FetchAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAllUsersQuery = { __typename?: 'Query', fetchAllUsers?: Array<{ __typename?: 'User', id: number, email: string, role: Role, customerId: number, createdAt: any, firstName?: string | null | undefined, lastName?: string | null | undefined }> | null | undefined };

export type InviteUsersMutationVariables = Exact<{
  users: Array<UserRoleInput> | UserRoleInput;
}>;


export type InviteUsersMutation = { __typename?: 'Mutation', inviteUser?: Array<{ __typename?: 'UserRole', email: string, role: Role, firstName?: string | null | undefined, lastName?: string | null | undefined }> | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: boolean };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['Float'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type GetActiveUserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveUserRoleQuery = { __typename?: 'Query', getActiveUserRole?: { __typename?: 'UserRole', role: Role, firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined };

export type UpdateUserFirstLastNameMutationVariables = Exact<{
  lastName: Scalars['String'];
  firstName: Scalars['String'];
}>;


export type UpdateUserFirstLastNameMutation = { __typename?: 'Mutation', updateUserFirstLastName: boolean };

export type UpdateUserCurrentStepMutationVariables = Exact<{
  currentStep: CurrentSteps;
}>;


export type UpdateUserCurrentStepMutation = { __typename?: 'Mutation', updateUserCurrentStep: boolean };

export type UpdateSkippedStepMutationVariables = Exact<{
  skippedSteps: Scalars['String'];
}>;


export type UpdateSkippedStepMutation = { __typename?: 'Mutation', updateUserSkippedStep: boolean };

export type FetchInProcessOnboardingQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchInProcessOnboardingQuery = { __typename?: 'Query', fetchInProcessOnboarding?: { __typename?: 'InProcessOnboarding', selectedPlanAlias: string, cardAttached: boolean, shopifyIntegrated: boolean, shopifyURL: string, activeUser: { __typename?: 'OnboardingUser', id: number, email: string, comp_name: string, cust_id: number, role: string, status: string, created_at?: any | null | undefined, deleted_at?: any | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, fulfilmentServices: Array<{ __typename?: 'ShopFC', name: string, handle: string, providerInternalId: number, id: number, address?: string | null | undefined }>, stripeIntent: { __typename?: 'StripeIntent', clientSecret: string, customerId: string, coupon: string } } | null | undefined };

export type FetchInProcessShopQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchInProcessShopQuery = { __typename?: 'Query', fetchInProcessShop?: { __typename?: 'InProcessOnboarding', selectedPlanAlias: string, shopifyURL: string, cardAttached: boolean, shopifyIntegrated: boolean, activeUser: { __typename?: 'OnboardingUser', id: number, email: string, comp_name: string, cust_id: number, role: string, status: string, created_at?: any | null | undefined, deleted_at?: any | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, fulfilmentServices: Array<{ __typename?: 'ShopFC', name: string, handle: string, providerInternalId: number, id: number, address?: string | null | undefined }>, stripeIntent: { __typename?: 'StripeIntent', clientSecret: string, customerId: string, coupon: string } } | null | undefined };

export type InitiateOnboardingMutationVariables = Exact<{
  shopName: Scalars['String'];
  plan: PricingPlan;
  shopApiKey: Scalars['String'];
  shopPassword: Scalars['String'];
  shopType: ShopType;
}>;


export type InitiateOnboardingMutation = { __typename?: 'Mutation', initiatedOnboardingProcess?: { __typename?: 'InProcessOnboarding', cardAttached: boolean, shopifyIntegrated: boolean, shopifyURL: string, activeUser: { __typename?: 'OnboardingUser', id: number, email: string, comp_name: string, cust_id: number, role: string, status: string, created_at?: any | null | undefined, deleted_at?: any | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, fulfilmentServices: Array<{ __typename?: 'ShopFC', id: number, name: string, providerInternalId: number, handle: string, address?: string | null | undefined }> } | null | undefined };

export type FinalizeOnboardingForCustomerMutationVariables = Exact<{
  onboardingValues: OnboardingInput;
}>;


export type FinalizeOnboardingForCustomerMutation = { __typename?: 'Mutation', finalizeOnboardingForCustomer: string };

export type AttachCardForCustomerMutationVariables = Exact<{
  planName: Scalars['String'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  stripeIntent: StripeIntentInput;
}>;


export type AttachCardForCustomerMutation = { __typename?: 'Mutation', attachCardAndCreateCustomer?: { __typename?: 'InProcessOnboarding', selectedPlanAlias: string, cardAttached: boolean, shopifyIntegrated: boolean, activeUser: { __typename?: 'OnboardingUser', id: number, email: string, comp_name: string, cust_id: number, role: string, status: string, created_at?: any | null | undefined, deleted_at?: any | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, fulfilmentServices: Array<{ __typename?: 'ShopFC', id: number, name: string, providerInternalId: number, handle: string, address?: string | null | undefined }> } | null | undefined };

export type GetShopsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShopsQuery = { __typename?: 'Query', getShops?: Array<{ __typename?: 'Shop', name?: string | null | undefined, domain?: string | null | undefined, shopType: string, pricingPlan?: string | null | undefined, isOldInstallation?: boolean | null | undefined }> | null | undefined };

export type GetActiveShopQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveShopQuery = { __typename?: 'Query', getActiveShop?: { __typename?: 'Shop', domain?: string | null | undefined, shopType: string } | null | undefined };

export type EnableDaylightSavingMutationVariables = Exact<{
  to: Scalars['DateTime'];
  from: Scalars['DateTime'];
  offset: Scalars['Float'];
  id: Scalars['Float'];
}>;


export type EnableDaylightSavingMutation = { __typename?: 'Mutation', enableDaylightSaving: boolean };

export type DisableDaylightSavingMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DisableDaylightSavingMutation = { __typename?: 'Mutation', disableDaylightSaving: boolean };

export type GetShopifyPaymentLinkForActiveShopQueryVariables = Exact<{
  paymentPlanAlias: Scalars['String'];
}>;


export type GetShopifyPaymentLinkForActiveShopQuery = { __typename?: 'Query', getShopifyPaymentLinkForActiveShop: string };

export type CancelSubscirptionForActiveShopQueryVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscirptionForActiveShopQuery = { __typename?: 'Query', cancelSubscirptionForActiveShop: string };

export type ShopExistsQueryVariables = Exact<{
  shopName: Scalars['String'];
  shopType?: Maybe<ShopType>;
}>;


export type ShopExistsQuery = { __typename?: 'Query', shopExists?: boolean | null | undefined };


export const AddEventsDocument = gql`
    mutation AddEvents($holidays: [FulfillmentCenterHolidayInput!]!, $handle: String!, $shopName: String!) {
  addFulfillmentCentersHolidays(
    holidays: $holidays
    handle: $handle
    shopName: $shopName
  )
}
    `;
export type AddEventsMutationFn = Apollo.MutationFunction<AddEventsMutation, AddEventsMutationVariables>;

/**
 * __useAddEventsMutation__
 *
 * To run a mutation, you first call `useAddEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventsMutation, { data, loading, error }] = useAddEventsMutation({
 *   variables: {
 *      holidays: // value for 'holidays'
 *      handle: // value for 'handle'
 *      shopName: // value for 'shopName'
 *   },
 * });
 */
export function useAddEventsMutation(baseOptions?: Apollo.MutationHookOptions<AddEventsMutation, AddEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventsMutation, AddEventsMutationVariables>(AddEventsDocument, options);
      }
export type AddEventsMutationHookResult = ReturnType<typeof useAddEventsMutation>;
export type AddEventsMutationResult = Apollo.MutationResult<AddEventsMutation>;
export type AddEventsMutationOptions = Apollo.BaseMutationOptions<AddEventsMutation, AddEventsMutationVariables>;
export const GetEventsDocument = gql`
    query GetEvents($handle: String!, $shopName: String!) {
  fetchFulfillmentCenterHolidays(handle: $handle, shopName: $shopName) {
    holiday_name
    holiday_date
    id
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      shopName: // value for 'shopName'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const GetAdvanceViewDataDocument = gql`
    query GetAdvanceViewData($toDate: DateTime!, $fromDate: DateTime!, $fullfilmentCenter: String, $pageNumber: Float, $salesChannelId: Float, $filter: String, $status: OrderStatus!, $pageSize: Float!) {
  getOrders(
    filter: $filter
    toDate: $toDate
    fromDate: $fromDate
    fulfillmentCenter: $fullfilmentCenter
    pageNumber: $pageNumber
    salesChannelId: $salesChannelId
    status: $status
    pageSize: $pageSize
  ) {
    orderId
    orderName
    orderDate
    slaDate
    orderStatus
    delay
    exlude
    closedAt
    tracking_company
    tracking_number
    tracking_url
    lineitems {
      qty
    }
    tags {
      name
      type
    }
    exclude_reasons {
      secondary_reason
      primary_reason
      comment
    }
    onTimeFulfilmentReason
    orderAccuracyReason
    orderStatusSLA
    sales_channel
    stage
    total_price
    salesChannelInternal
  }
  getOrdersCount(
    filter: $filter
    toDate: $toDate
    fromDate: $fromDate
    fulfillmentCenter: $fullfilmentCenter
    status: $status
    salesChannelId: $salesChannelId
  )
}
    `;

/**
 * __useGetAdvanceViewDataQuery__
 *
 * To run a query within a React component, call `useGetAdvanceViewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvanceViewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvanceViewDataQuery({
 *   variables: {
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      fullfilmentCenter: // value for 'fullfilmentCenter'
 *      pageNumber: // value for 'pageNumber'
 *      salesChannelId: // value for 'salesChannelId'
 *      filter: // value for 'filter'
 *      status: // value for 'status'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetAdvanceViewDataQuery(baseOptions: Apollo.QueryHookOptions<GetAdvanceViewDataQuery, GetAdvanceViewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvanceViewDataQuery, GetAdvanceViewDataQueryVariables>(GetAdvanceViewDataDocument, options);
      }
export function useGetAdvanceViewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvanceViewDataQuery, GetAdvanceViewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvanceViewDataQuery, GetAdvanceViewDataQueryVariables>(GetAdvanceViewDataDocument, options);
        }
export type GetAdvanceViewDataQueryHookResult = ReturnType<typeof useGetAdvanceViewDataQuery>;
export type GetAdvanceViewDataLazyQueryHookResult = ReturnType<typeof useGetAdvanceViewDataLazyQuery>;
export type GetAdvanceViewDataQueryResult = Apollo.QueryResult<GetAdvanceViewDataQuery, GetAdvanceViewDataQueryVariables>;
export const GetSingleOrderDocument = gql`
    query getSingleOrder($providerInternalId: String!) {
  getOrderWithProviderInternalId(providerInternalId: $providerInternalId) {
    orderId
    orderName
    qty
    orderDate
    slaDate
    orderStatus
    delay
    exlude
    closedAt
    tracking_company
    tracking_number
    tracking_url
    shipping_name
    shipping_phone
    shipping_email
    shipping_address
    billing_name
    billing_phone
    billing_email
    billing_address
    total_price
    total_discount
    shipping_cost
    total_taxes
    tracking_company
    tracking_number
    tracking_url
    lineitems {
      name
      qty
      unit_price
      weight
      sku
    }
    tags {
      type
      name
    }
    exclude_reasons {
      secondary_reason
      primary_reason
      comment
      happened_at
      is_excluded
      first_name
      last_name
      reason_type
      is_assigned
    }
  }
}
    `;

/**
 * __useGetSingleOrderQuery__
 *
 * To run a query within a React component, call `useGetSingleOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleOrderQuery({
 *   variables: {
 *      providerInternalId: // value for 'providerInternalId'
 *   },
 * });
 */
export function useGetSingleOrderQuery(baseOptions: Apollo.QueryHookOptions<GetSingleOrderQuery, GetSingleOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleOrderQuery, GetSingleOrderQueryVariables>(GetSingleOrderDocument, options);
      }
export function useGetSingleOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleOrderQuery, GetSingleOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleOrderQuery, GetSingleOrderQueryVariables>(GetSingleOrderDocument, options);
        }
export type GetSingleOrderQueryHookResult = ReturnType<typeof useGetSingleOrderQuery>;
export type GetSingleOrderLazyQueryHookResult = ReturnType<typeof useGetSingleOrderLazyQuery>;
export type GetSingleOrderQueryResult = Apollo.QueryResult<GetSingleOrderQuery, GetSingleOrderQueryVariables>;
export const GetMultipleOrdersDocument = gql`
    query getMultipleOrders($providerInternalIds: [String!]!) {
  getOrdersWithProviderInternalIds(providerInternalIds: $providerInternalIds) {
    tags {
      name
      type
    }
    lineitems {
      sku
    }
  }
}
    `;

/**
 * __useGetMultipleOrdersQuery__
 *
 * To run a query within a React component, call `useGetMultipleOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleOrdersQuery({
 *   variables: {
 *      providerInternalIds: // value for 'providerInternalIds'
 *   },
 * });
 */
export function useGetMultipleOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMultipleOrdersQuery, GetMultipleOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultipleOrdersQuery, GetMultipleOrdersQueryVariables>(GetMultipleOrdersDocument, options);
      }
export function useGetMultipleOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultipleOrdersQuery, GetMultipleOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultipleOrdersQuery, GetMultipleOrdersQueryVariables>(GetMultipleOrdersDocument, options);
        }
export type GetMultipleOrdersQueryHookResult = ReturnType<typeof useGetMultipleOrdersQuery>;
export type GetMultipleOrdersLazyQueryHookResult = ReturnType<typeof useGetMultipleOrdersLazyQuery>;
export type GetMultipleOrdersQueryResult = Apollo.QueryResult<GetMultipleOrdersQuery, GetMultipleOrdersQueryVariables>;
export const IsEnabledPartialFulfilmentDocument = gql`
    query IsEnabledPartialFulfilment {
  isEnabledPartialFulfilment
}
    `;

/**
 * __useIsEnabledPartialFulfilmentQuery__
 *
 * To run a query within a React component, call `useIsEnabledPartialFulfilmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEnabledPartialFulfilmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEnabledPartialFulfilmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEnabledPartialFulfilmentQuery(baseOptions?: Apollo.QueryHookOptions<IsEnabledPartialFulfilmentQuery, IsEnabledPartialFulfilmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEnabledPartialFulfilmentQuery, IsEnabledPartialFulfilmentQueryVariables>(IsEnabledPartialFulfilmentDocument, options);
      }
export function useIsEnabledPartialFulfilmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEnabledPartialFulfilmentQuery, IsEnabledPartialFulfilmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEnabledPartialFulfilmentQuery, IsEnabledPartialFulfilmentQueryVariables>(IsEnabledPartialFulfilmentDocument, options);
        }
export type IsEnabledPartialFulfilmentQueryHookResult = ReturnType<typeof useIsEnabledPartialFulfilmentQuery>;
export type IsEnabledPartialFulfilmentLazyQueryHookResult = ReturnType<typeof useIsEnabledPartialFulfilmentLazyQuery>;
export type IsEnabledPartialFulfilmentQueryResult = Apollo.QueryResult<IsEnabledPartialFulfilmentQuery, IsEnabledPartialFulfilmentQueryVariables>;
export const GetMarkedForSlaCountDocument = gql`
    query GetMarkedForSLACount {
  getMarkedForSLACount
}
    `;

/**
 * __useGetMarkedForSlaCountQuery__
 *
 * To run a query within a React component, call `useGetMarkedForSlaCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkedForSlaCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkedForSlaCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarkedForSlaCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMarkedForSlaCountQuery, GetMarkedForSlaCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkedForSlaCountQuery, GetMarkedForSlaCountQueryVariables>(GetMarkedForSlaCountDocument, options);
      }
export function useGetMarkedForSlaCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkedForSlaCountQuery, GetMarkedForSlaCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkedForSlaCountQuery, GetMarkedForSlaCountQueryVariables>(GetMarkedForSlaCountDocument, options);
        }
export type GetMarkedForSlaCountQueryHookResult = ReturnType<typeof useGetMarkedForSlaCountQuery>;
export type GetMarkedForSlaCountLazyQueryHookResult = ReturnType<typeof useGetMarkedForSlaCountLazyQuery>;
export type GetMarkedForSlaCountQueryResult = Apollo.QueryResult<GetMarkedForSlaCountQuery, GetMarkedForSlaCountQueryVariables>;
export const GetMarkedForReroutingCountDocument = gql`
    query getMarkedForReroutingCount {
  getMarkedForReroutingCount
}
    `;

/**
 * __useGetMarkedForReroutingCountQuery__
 *
 * To run a query within a React component, call `useGetMarkedForReroutingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarkedForReroutingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarkedForReroutingCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarkedForReroutingCountQuery(baseOptions?: Apollo.QueryHookOptions<GetMarkedForReroutingCountQuery, GetMarkedForReroutingCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarkedForReroutingCountQuery, GetMarkedForReroutingCountQueryVariables>(GetMarkedForReroutingCountDocument, options);
      }
export function useGetMarkedForReroutingCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarkedForReroutingCountQuery, GetMarkedForReroutingCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarkedForReroutingCountQuery, GetMarkedForReroutingCountQueryVariables>(GetMarkedForReroutingCountDocument, options);
        }
export type GetMarkedForReroutingCountQueryHookResult = ReturnType<typeof useGetMarkedForReroutingCountQuery>;
export type GetMarkedForReroutingCountLazyQueryHookResult = ReturnType<typeof useGetMarkedForReroutingCountLazyQuery>;
export type GetMarkedForReroutingCountQueryResult = Apollo.QueryResult<GetMarkedForReroutingCountQuery, GetMarkedForReroutingCountQueryVariables>;
export const EnablePartialFulfimentDocument = gql`
    mutation EnablePartialFulfiment {
  enablePartialFulfiment
}
    `;
export type EnablePartialFulfimentMutationFn = Apollo.MutationFunction<EnablePartialFulfimentMutation, EnablePartialFulfimentMutationVariables>;

/**
 * __useEnablePartialFulfimentMutation__
 *
 * To run a mutation, you first call `useEnablePartialFulfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnablePartialFulfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enablePartialFulfimentMutation, { data, loading, error }] = useEnablePartialFulfimentMutation({
 *   variables: {
 *   },
 * });
 */
export function useEnablePartialFulfimentMutation(baseOptions?: Apollo.MutationHookOptions<EnablePartialFulfimentMutation, EnablePartialFulfimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnablePartialFulfimentMutation, EnablePartialFulfimentMutationVariables>(EnablePartialFulfimentDocument, options);
      }
export type EnablePartialFulfimentMutationHookResult = ReturnType<typeof useEnablePartialFulfimentMutation>;
export type EnablePartialFulfimentMutationResult = Apollo.MutationResult<EnablePartialFulfimentMutation>;
export type EnablePartialFulfimentMutationOptions = Apollo.BaseMutationOptions<EnablePartialFulfimentMutation, EnablePartialFulfimentMutationVariables>;
export const DisablePartialFulfimentDocument = gql`
    mutation DisablePartialFulfiment {
  disablePartialFulfiment
}
    `;
export type DisablePartialFulfimentMutationFn = Apollo.MutationFunction<DisablePartialFulfimentMutation, DisablePartialFulfimentMutationVariables>;

/**
 * __useDisablePartialFulfimentMutation__
 *
 * To run a mutation, you first call `useDisablePartialFulfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisablePartialFulfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disablePartialFulfimentMutation, { data, loading, error }] = useDisablePartialFulfimentMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisablePartialFulfimentMutation(baseOptions?: Apollo.MutationHookOptions<DisablePartialFulfimentMutation, DisablePartialFulfimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisablePartialFulfimentMutation, DisablePartialFulfimentMutationVariables>(DisablePartialFulfimentDocument, options);
      }
export type DisablePartialFulfimentMutationHookResult = ReturnType<typeof useDisablePartialFulfimentMutation>;
export type DisablePartialFulfimentMutationResult = Apollo.MutationResult<DisablePartialFulfimentMutation>;
export type DisablePartialFulfimentMutationOptions = Apollo.BaseMutationOptions<DisablePartialFulfimentMutation, DisablePartialFulfimentMutationVariables>;
export const AddTagsToOrdersDocument = gql`
    mutation addTagsToOrders($provider_internal_ids: [String!]!, $tags: [String!]!) {
  addTags(provider_internal_ids: $provider_internal_ids, tags: $tags)
}
    `;
export type AddTagsToOrdersMutationFn = Apollo.MutationFunction<AddTagsToOrdersMutation, AddTagsToOrdersMutationVariables>;

/**
 * __useAddTagsToOrdersMutation__
 *
 * To run a mutation, you first call `useAddTagsToOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagsToOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagsToOrdersMutation, { data, loading, error }] = useAddTagsToOrdersMutation({
 *   variables: {
 *      provider_internal_ids: // value for 'provider_internal_ids'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAddTagsToOrdersMutation(baseOptions?: Apollo.MutationHookOptions<AddTagsToOrdersMutation, AddTagsToOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTagsToOrdersMutation, AddTagsToOrdersMutationVariables>(AddTagsToOrdersDocument, options);
      }
export type AddTagsToOrdersMutationHookResult = ReturnType<typeof useAddTagsToOrdersMutation>;
export type AddTagsToOrdersMutationResult = Apollo.MutationResult<AddTagsToOrdersMutation>;
export type AddTagsToOrdersMutationOptions = Apollo.BaseMutationOptions<AddTagsToOrdersMutation, AddTagsToOrdersMutationVariables>;
export const RemoveTagsToOrdersDocument = gql`
    mutation removeTagsToOrders($provider_internal_ids: [String!]!, $tags: [String!]!) {
  removeTagsFromOrders(provider_internal_ids: $provider_internal_ids, tags: $tags)
}
    `;
export type RemoveTagsToOrdersMutationFn = Apollo.MutationFunction<RemoveTagsToOrdersMutation, RemoveTagsToOrdersMutationVariables>;

/**
 * __useRemoveTagsToOrdersMutation__
 *
 * To run a mutation, you first call `useRemoveTagsToOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagsToOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagsToOrdersMutation, { data, loading, error }] = useRemoveTagsToOrdersMutation({
 *   variables: {
 *      provider_internal_ids: // value for 'provider_internal_ids'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveTagsToOrdersMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagsToOrdersMutation, RemoveTagsToOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagsToOrdersMutation, RemoveTagsToOrdersMutationVariables>(RemoveTagsToOrdersDocument, options);
      }
export type RemoveTagsToOrdersMutationHookResult = ReturnType<typeof useRemoveTagsToOrdersMutation>;
export type RemoveTagsToOrdersMutationResult = Apollo.MutationResult<RemoveTagsToOrdersMutation>;
export type RemoveTagsToOrdersMutationOptions = Apollo.BaseMutationOptions<RemoveTagsToOrdersMutation, RemoveTagsToOrdersMutationVariables>;
export const CreateSlaOverwrittingRuleDocument = gql`
    mutation CreateSLAOverwrittingRule($date: DateTime!, $delayInDays: Float!, $type: SLAOverwrittingType!, $tags: [String!]) {
  createSLAOverwrittingRule(
    date: $date
    delayInDays: $delayInDays
    type: $type
    tags: $tags
  )
}
    `;
export type CreateSlaOverwrittingRuleMutationFn = Apollo.MutationFunction<CreateSlaOverwrittingRuleMutation, CreateSlaOverwrittingRuleMutationVariables>;

/**
 * __useCreateSlaOverwrittingRuleMutation__
 *
 * To run a mutation, you first call `useCreateSlaOverwrittingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlaOverwrittingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlaOverwrittingRuleMutation, { data, loading, error }] = useCreateSlaOverwrittingRuleMutation({
 *   variables: {
 *      date: // value for 'date'
 *      delayInDays: // value for 'delayInDays'
 *      type: // value for 'type'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateSlaOverwrittingRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSlaOverwrittingRuleMutation, CreateSlaOverwrittingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSlaOverwrittingRuleMutation, CreateSlaOverwrittingRuleMutationVariables>(CreateSlaOverwrittingRuleDocument, options);
      }
export type CreateSlaOverwrittingRuleMutationHookResult = ReturnType<typeof useCreateSlaOverwrittingRuleMutation>;
export type CreateSlaOverwrittingRuleMutationResult = Apollo.MutationResult<CreateSlaOverwrittingRuleMutation>;
export type CreateSlaOverwrittingRuleMutationOptions = Apollo.BaseMutationOptions<CreateSlaOverwrittingRuleMutation, CreateSlaOverwrittingRuleMutationVariables>;
export const CreateSlaOverwrittingSkuRuleDocument = gql`
    mutation CreateSLAOverwrittingSKURule($date: DateTime!, $delayInDays: Float!, $type: SLAOverwrittingType!, $skus: [String!]) {
  createSLAOverwrittingSKURule(
    date: $date
    delayInDays: $delayInDays
    type: $type
    skus: $skus
  )
}
    `;
export type CreateSlaOverwrittingSkuRuleMutationFn = Apollo.MutationFunction<CreateSlaOverwrittingSkuRuleMutation, CreateSlaOverwrittingSkuRuleMutationVariables>;

/**
 * __useCreateSlaOverwrittingSkuRuleMutation__
 *
 * To run a mutation, you first call `useCreateSlaOverwrittingSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlaOverwrittingSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlaOverwrittingSkuRuleMutation, { data, loading, error }] = useCreateSlaOverwrittingSkuRuleMutation({
 *   variables: {
 *      date: // value for 'date'
 *      delayInDays: // value for 'delayInDays'
 *      type: // value for 'type'
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useCreateSlaOverwrittingSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSlaOverwrittingSkuRuleMutation, CreateSlaOverwrittingSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSlaOverwrittingSkuRuleMutation, CreateSlaOverwrittingSkuRuleMutationVariables>(CreateSlaOverwrittingSkuRuleDocument, options);
      }
export type CreateSlaOverwrittingSkuRuleMutationHookResult = ReturnType<typeof useCreateSlaOverwrittingSkuRuleMutation>;
export type CreateSlaOverwrittingSkuRuleMutationResult = Apollo.MutationResult<CreateSlaOverwrittingSkuRuleMutation>;
export type CreateSlaOverwrittingSkuRuleMutationOptions = Apollo.BaseMutationOptions<CreateSlaOverwrittingSkuRuleMutation, CreateSlaOverwrittingSkuRuleMutationVariables>;
export const CreateAssignRuleDocument = gql`
    mutation CreateAssignRule($literal: String!, $tags: [String!]) {
  createAssignRule(tags: $tags, literal: $literal)
}
    `;
export type CreateAssignRuleMutationFn = Apollo.MutationFunction<CreateAssignRuleMutation, CreateAssignRuleMutationVariables>;

/**
 * __useCreateAssignRuleMutation__
 *
 * To run a mutation, you first call `useCreateAssignRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignRuleMutation, { data, loading, error }] = useCreateAssignRuleMutation({
 *   variables: {
 *      literal: // value for 'literal'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateAssignRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignRuleMutation, CreateAssignRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignRuleMutation, CreateAssignRuleMutationVariables>(CreateAssignRuleDocument, options);
      }
export type CreateAssignRuleMutationHookResult = ReturnType<typeof useCreateAssignRuleMutation>;
export type CreateAssignRuleMutationResult = Apollo.MutationResult<CreateAssignRuleMutation>;
export type CreateAssignRuleMutationOptions = Apollo.BaseMutationOptions<CreateAssignRuleMutation, CreateAssignRuleMutationVariables>;
export const ExcludeOrReRouteOrdersDocument = gql`
    mutation ExcludeOrReRouteOrders($routeToFC: String, $markReRoute: Boolean, $newSLADate: DateTime, $primaryReason: PrimaryExcludeReason, $slaStatus: Delay, $orderStatus: OrderStatus, $salesChannel: [String!], $skuNumber: [String!], $tag: [String!], $toDate: DateTime!, $fromDate: DateTime!, $fullfilmentCenter: String!, $secondaryReason: String) {
  excludeOrReRouteOrders(
    routeToFC: $routeToFC
    markReRoute: $markReRoute
    newSLADate: $newSLADate
    primaryReason: $primaryReason
    slaStatus: $slaStatus
    status: $orderStatus
    salesChannel: $salesChannel
    skuNumber: $skuNumber
    tag: $tag
    toDate: $toDate
    fromDate: $fromDate
    fulfillmentCenter: $fullfilmentCenter
    secondaryReason: $secondaryReason
  )
}
    `;
export type ExcludeOrReRouteOrdersMutationFn = Apollo.MutationFunction<ExcludeOrReRouteOrdersMutation, ExcludeOrReRouteOrdersMutationVariables>;

/**
 * __useExcludeOrReRouteOrdersMutation__
 *
 * To run a mutation, you first call `useExcludeOrReRouteOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeOrReRouteOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeOrReRouteOrdersMutation, { data, loading, error }] = useExcludeOrReRouteOrdersMutation({
 *   variables: {
 *      routeToFC: // value for 'routeToFC'
 *      markReRoute: // value for 'markReRoute'
 *      newSLADate: // value for 'newSLADate'
 *      primaryReason: // value for 'primaryReason'
 *      slaStatus: // value for 'slaStatus'
 *      orderStatus: // value for 'orderStatus'
 *      salesChannel: // value for 'salesChannel'
 *      skuNumber: // value for 'skuNumber'
 *      tag: // value for 'tag'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      fullfilmentCenter: // value for 'fullfilmentCenter'
 *      secondaryReason: // value for 'secondaryReason'
 *   },
 * });
 */
export function useExcludeOrReRouteOrdersMutation(baseOptions?: Apollo.MutationHookOptions<ExcludeOrReRouteOrdersMutation, ExcludeOrReRouteOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludeOrReRouteOrdersMutation, ExcludeOrReRouteOrdersMutationVariables>(ExcludeOrReRouteOrdersDocument, options);
      }
export type ExcludeOrReRouteOrdersMutationHookResult = ReturnType<typeof useExcludeOrReRouteOrdersMutation>;
export type ExcludeOrReRouteOrdersMutationResult = Apollo.MutationResult<ExcludeOrReRouteOrdersMutation>;
export type ExcludeOrReRouteOrdersMutationOptions = Apollo.BaseMutationOptions<ExcludeOrReRouteOrdersMutation, ExcludeOrReRouteOrdersMutationVariables>;
export const GetFilteredOrdersCountDocument = gql`
    query GetFilteredOrdersCount($slaStatus: Delay!, $orderStatus: OrderStatus, $salesChannel: [String!], $skuNumber: [String!], $tag: [String!], $toDate: DateTime!, $fromDate: DateTime!, $fullfilmentCenter: String!) {
  getFilteredOrdersCount(
    slaStatus: $slaStatus
    status: $orderStatus
    salesChannel: $salesChannel
    skuNumber: $skuNumber
    tag: $tag
    toDate: $toDate
    fromDate: $fromDate
    fulfillmentCenter: $fullfilmentCenter
  )
}
    `;

/**
 * __useGetFilteredOrdersCountQuery__
 *
 * To run a query within a React component, call `useGetFilteredOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredOrdersCountQuery({
 *   variables: {
 *      slaStatus: // value for 'slaStatus'
 *      orderStatus: // value for 'orderStatus'
 *      salesChannel: // value for 'salesChannel'
 *      skuNumber: // value for 'skuNumber'
 *      tag: // value for 'tag'
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      fullfilmentCenter: // value for 'fullfilmentCenter'
 *   },
 * });
 */
export function useGetFilteredOrdersCountQuery(baseOptions: Apollo.QueryHookOptions<GetFilteredOrdersCountQuery, GetFilteredOrdersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilteredOrdersCountQuery, GetFilteredOrdersCountQueryVariables>(GetFilteredOrdersCountDocument, options);
      }
export function useGetFilteredOrdersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilteredOrdersCountQuery, GetFilteredOrdersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilteredOrdersCountQuery, GetFilteredOrdersCountQueryVariables>(GetFilteredOrdersCountDocument, options);
        }
export type GetFilteredOrdersCountQueryHookResult = ReturnType<typeof useGetFilteredOrdersCountQuery>;
export type GetFilteredOrdersCountLazyQueryHookResult = ReturnType<typeof useGetFilteredOrdersCountLazyQuery>;
export type GetFilteredOrdersCountQueryResult = Apollo.QueryResult<GetFilteredOrdersCountQuery, GetFilteredOrdersCountQueryVariables>;
export const GetAllSkUsDocument = gql`
    query GetAllSKUs {
  getAllSKUs
}
    `;

/**
 * __useGetAllSkUsQuery__
 *
 * To run a query within a React component, call `useGetAllSkUsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSkUsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSkUsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSkUsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSkUsQuery, GetAllSkUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSkUsQuery, GetAllSkUsQueryVariables>(GetAllSkUsDocument, options);
      }
export function useGetAllSkUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSkUsQuery, GetAllSkUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSkUsQuery, GetAllSkUsQueryVariables>(GetAllSkUsDocument, options);
        }
export type GetAllSkUsQueryHookResult = ReturnType<typeof useGetAllSkUsQuery>;
export type GetAllSkUsLazyQueryHookResult = ReturnType<typeof useGetAllSkUsLazyQuery>;
export type GetAllSkUsQueryResult = Apollo.QueryResult<GetAllSkUsQuery, GetAllSkUsQueryVariables>;
export const BiViewDocument = gql`
    query BiView($startDate: DateTime!, $biQueries: [BITableId!]!, $readFromCache: Boolean) {
  biView(
    startingDate: $startDate
    biQueries: $biQueries
    readFromCache: $readFromCache
  ) {
    trends {
      title
      columns {
        name
        cells {
          cellId
          value
        }
      }
    }
    weeklyColumns {
      dailyInternal
      current
      previous
      weekly
      monthly
      quarter
    }
  }
}
    `;

/**
 * __useBiViewQuery__
 *
 * To run a query within a React component, call `useBiViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiViewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      biQueries: // value for 'biQueries'
 *      readFromCache: // value for 'readFromCache'
 *   },
 * });
 */
export function useBiViewQuery(baseOptions: Apollo.QueryHookOptions<BiViewQuery, BiViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BiViewQuery, BiViewQueryVariables>(BiViewDocument, options);
      }
export function useBiViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BiViewQuery, BiViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BiViewQuery, BiViewQueryVariables>(BiViewDocument, options);
        }
export type BiViewQueryHookResult = ReturnType<typeof useBiViewQuery>;
export type BiViewLazyQueryHookResult = ReturnType<typeof useBiViewLazyQuery>;
export type BiViewQueryResult = Apollo.QueryResult<BiViewQuery, BiViewQueryVariables>;
export const GetCustomerDetailsDocument = gql`
    query GetCustomerDetails {
  getCustomerDetails {
    comp_name
    stripeCustomerId
    pricingPlan
    paymentStatus
    failureReason
    dueDate
  }
}
    `;

/**
 * __useGetCustomerDetailsQuery__
 *
 * To run a query within a React component, call `useGetCustomerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>(GetCustomerDetailsDocument, options);
      }
export function useGetCustomerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>(GetCustomerDetailsDocument, options);
        }
export type GetCustomerDetailsQueryHookResult = ReturnType<typeof useGetCustomerDetailsQuery>;
export type GetCustomerDetailsLazyQueryHookResult = ReturnType<typeof useGetCustomerDetailsLazyQuery>;
export type GetCustomerDetailsQueryResult = Apollo.QueryResult<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>;
export const ListAllCustomReportsDocument = gql`
    query ListAllCustomReports {
  listAllCustomReports {
    analyticsType
    comparisonType
    end
    id
    name
    periodType
    start
    type
    description
  }
}
    `;

/**
 * __useListAllCustomReportsQuery__
 *
 * To run a query within a React component, call `useListAllCustomReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllCustomReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllCustomReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllCustomReportsQuery(baseOptions?: Apollo.QueryHookOptions<ListAllCustomReportsQuery, ListAllCustomReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllCustomReportsQuery, ListAllCustomReportsQueryVariables>(ListAllCustomReportsDocument, options);
      }
export function useListAllCustomReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllCustomReportsQuery, ListAllCustomReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllCustomReportsQuery, ListAllCustomReportsQueryVariables>(ListAllCustomReportsDocument, options);
        }
export type ListAllCustomReportsQueryHookResult = ReturnType<typeof useListAllCustomReportsQuery>;
export type ListAllCustomReportsLazyQueryHookResult = ReturnType<typeof useListAllCustomReportsLazyQuery>;
export type ListAllCustomReportsQueryResult = Apollo.QueryResult<ListAllCustomReportsQuery, ListAllCustomReportsQueryVariables>;
export const SaveCustomReportsDocument = gql`
    mutation SaveCustomReports($report: CustomReportInput!) {
  saveCustomReports(report: $report)
}
    `;
export type SaveCustomReportsMutationFn = Apollo.MutationFunction<SaveCustomReportsMutation, SaveCustomReportsMutationVariables>;

/**
 * __useSaveCustomReportsMutation__
 *
 * To run a mutation, you first call `useSaveCustomReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomReportsMutation, { data, loading, error }] = useSaveCustomReportsMutation({
 *   variables: {
 *      report: // value for 'report'
 *   },
 * });
 */
export function useSaveCustomReportsMutation(baseOptions?: Apollo.MutationHookOptions<SaveCustomReportsMutation, SaveCustomReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCustomReportsMutation, SaveCustomReportsMutationVariables>(SaveCustomReportsDocument, options);
      }
export type SaveCustomReportsMutationHookResult = ReturnType<typeof useSaveCustomReportsMutation>;
export type SaveCustomReportsMutationResult = Apollo.MutationResult<SaveCustomReportsMutation>;
export type SaveCustomReportsMutationOptions = Apollo.BaseMutationOptions<SaveCustomReportsMutation, SaveCustomReportsMutationVariables>;
export const DeleteCustomReportsDocument = gql`
    mutation DeleteCustomReports($reportId: Float!) {
  deleteCustomReports(reportId: $reportId)
}
    `;
export type DeleteCustomReportsMutationFn = Apollo.MutationFunction<DeleteCustomReportsMutation, DeleteCustomReportsMutationVariables>;

/**
 * __useDeleteCustomReportsMutation__
 *
 * To run a mutation, you first call `useDeleteCustomReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomReportsMutation, { data, loading, error }] = useDeleteCustomReportsMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteCustomReportsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomReportsMutation, DeleteCustomReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomReportsMutation, DeleteCustomReportsMutationVariables>(DeleteCustomReportsDocument, options);
      }
export type DeleteCustomReportsMutationHookResult = ReturnType<typeof useDeleteCustomReportsMutation>;
export type DeleteCustomReportsMutationResult = Apollo.MutationResult<DeleteCustomReportsMutation>;
export type DeleteCustomReportsMutationOptions = Apollo.BaseMutationOptions<DeleteCustomReportsMutation, DeleteCustomReportsMutationVariables>;
export const WeeklyColumnValuesForBiDocument = gql`
    query weeklyColumnValuesForBI($endDate: DateTime!, $includeDays: Boolean, $startDate: DateTime) {
  weeklyColumnValuesForBI(
    endDate: $endDate
    startDate: $startDate
    includeDays: $includeDays
  ) {
    weekly
    totalSpan
    monthly
    daysOfWeek
    dailyInternal
  }
}
    `;

/**
 * __useWeeklyColumnValuesForBiQuery__
 *
 * To run a query within a React component, call `useWeeklyColumnValuesForBiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyColumnValuesForBiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyColumnValuesForBiQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      includeDays: // value for 'includeDays'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useWeeklyColumnValuesForBiQuery(baseOptions: Apollo.QueryHookOptions<WeeklyColumnValuesForBiQuery, WeeklyColumnValuesForBiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeeklyColumnValuesForBiQuery, WeeklyColumnValuesForBiQueryVariables>(WeeklyColumnValuesForBiDocument, options);
      }
export function useWeeklyColumnValuesForBiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeeklyColumnValuesForBiQuery, WeeklyColumnValuesForBiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeeklyColumnValuesForBiQuery, WeeklyColumnValuesForBiQueryVariables>(WeeklyColumnValuesForBiDocument, options);
        }
export type WeeklyColumnValuesForBiQueryHookResult = ReturnType<typeof useWeeklyColumnValuesForBiQuery>;
export type WeeklyColumnValuesForBiLazyQueryHookResult = ReturnType<typeof useWeeklyColumnValuesForBiLazyQuery>;
export type WeeklyColumnValuesForBiQueryResult = Apollo.QueryResult<WeeklyColumnValuesForBiQuery, WeeklyColumnValuesForBiQueryVariables>;
export const BiViewWithTimeSpanDistributionDocument = gql`
    query biViewWithTimeSpanDistribution($weeklyColumnValues: WeeklyColumnValuesArg!, $biQueries: [BITableId!]) {
  biViewWithTimeSpanDistribution(
    weeklyColumnValues: $weeklyColumnValues
    biQueries: $biQueries
  ) {
    trends {
      columns {
        cells {
          cellId
          threshold
          value
          nestedCell {
            cellId
            threshold
            value
          }
        }
        handle
        name
      }
      title
    }
    weeklyColumns {
      weekly
      totalSpan
    }
  }
}
    `;

/**
 * __useBiViewWithTimeSpanDistributionQuery__
 *
 * To run a query within a React component, call `useBiViewWithTimeSpanDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiViewWithTimeSpanDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiViewWithTimeSpanDistributionQuery({
 *   variables: {
 *      weeklyColumnValues: // value for 'weeklyColumnValues'
 *      biQueries: // value for 'biQueries'
 *   },
 * });
 */
export function useBiViewWithTimeSpanDistributionQuery(baseOptions: Apollo.QueryHookOptions<BiViewWithTimeSpanDistributionQuery, BiViewWithTimeSpanDistributionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BiViewWithTimeSpanDistributionQuery, BiViewWithTimeSpanDistributionQueryVariables>(BiViewWithTimeSpanDistributionDocument, options);
      }
export function useBiViewWithTimeSpanDistributionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BiViewWithTimeSpanDistributionQuery, BiViewWithTimeSpanDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BiViewWithTimeSpanDistributionQuery, BiViewWithTimeSpanDistributionQueryVariables>(BiViewWithTimeSpanDistributionDocument, options);
        }
export type BiViewWithTimeSpanDistributionQueryHookResult = ReturnType<typeof useBiViewWithTimeSpanDistributionQuery>;
export type BiViewWithTimeSpanDistributionLazyQueryHookResult = ReturnType<typeof useBiViewWithTimeSpanDistributionLazyQuery>;
export type BiViewWithTimeSpanDistributionQueryResult = Apollo.QueryResult<BiViewWithTimeSpanDistributionQuery, BiViewWithTimeSpanDistributionQueryVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($ids: [Float!]!, $handle: String!, $shopName: String!) {
  deleteFulfillmentCentersHolidays(
    ids: $ids
    handle: $handle
    shopName: $shopName
  )
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      handle: // value for 'handle'
 *      shopName: // value for 'shopName'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const ExcludeOrderDocument = gql`
    mutation ExcludeOrder($exclude: Boolean!, $orderIds: [String!]!, $comment: String!, $primaryReason: String!, $secondaryReason: String!) {
  excludeOrder(
    exclude: $exclude
    orderIds: $orderIds
    comment: $comment
    primaryReason: $primaryReason
    secondaryReason: $secondaryReason
  )
}
    `;
export type ExcludeOrderMutationFn = Apollo.MutationFunction<ExcludeOrderMutation, ExcludeOrderMutationVariables>;

/**
 * __useExcludeOrderMutation__
 *
 * To run a mutation, you first call `useExcludeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeOrderMutation, { data, loading, error }] = useExcludeOrderMutation({
 *   variables: {
 *      exclude: // value for 'exclude'
 *      orderIds: // value for 'orderIds'
 *      comment: // value for 'comment'
 *      primaryReason: // value for 'primaryReason'
 *      secondaryReason: // value for 'secondaryReason'
 *   },
 * });
 */
export function useExcludeOrderMutation(baseOptions?: Apollo.MutationHookOptions<ExcludeOrderMutation, ExcludeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludeOrderMutation, ExcludeOrderMutationVariables>(ExcludeOrderDocument, options);
      }
export type ExcludeOrderMutationHookResult = ReturnType<typeof useExcludeOrderMutation>;
export type ExcludeOrderMutationResult = Apollo.MutationResult<ExcludeOrderMutation>;
export type ExcludeOrderMutationOptions = Apollo.BaseMutationOptions<ExcludeOrderMutation, ExcludeOrderMutationVariables>;
export const RerouteOrdersDocument = gql`
    mutation RerouteOrders($fc_handle: String!, $orderIds: [String!]!) {
  rerouteOrders(fc_handle: $fc_handle, orderIds: $orderIds)
}
    `;
export type RerouteOrdersMutationFn = Apollo.MutationFunction<RerouteOrdersMutation, RerouteOrdersMutationVariables>;

/**
 * __useRerouteOrdersMutation__
 *
 * To run a mutation, you first call `useRerouteOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRerouteOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rerouteOrdersMutation, { data, loading, error }] = useRerouteOrdersMutation({
 *   variables: {
 *      fc_handle: // value for 'fc_handle'
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useRerouteOrdersMutation(baseOptions?: Apollo.MutationHookOptions<RerouteOrdersMutation, RerouteOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RerouteOrdersMutation, RerouteOrdersMutationVariables>(RerouteOrdersDocument, options);
      }
export type RerouteOrdersMutationHookResult = ReturnType<typeof useRerouteOrdersMutation>;
export type RerouteOrdersMutationResult = Apollo.MutationResult<RerouteOrdersMutation>;
export type RerouteOrdersMutationOptions = Apollo.BaseMutationOptions<RerouteOrdersMutation, RerouteOrdersMutationVariables>;
export const CreateExcludeRuleDocument = gql`
    mutation CreateExcludeRule($exclude: Boolean!, $tags: [String!]!) {
  createExcludeRule(exclude: $exclude, tags: $tags)
}
    `;
export type CreateExcludeRuleMutationFn = Apollo.MutationFunction<CreateExcludeRuleMutation, CreateExcludeRuleMutationVariables>;

/**
 * __useCreateExcludeRuleMutation__
 *
 * To run a mutation, you first call `useCreateExcludeRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExcludeRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExcludeRuleMutation, { data, loading, error }] = useCreateExcludeRuleMutation({
 *   variables: {
 *      exclude: // value for 'exclude'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateExcludeRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateExcludeRuleMutation, CreateExcludeRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExcludeRuleMutation, CreateExcludeRuleMutationVariables>(CreateExcludeRuleDocument, options);
      }
export type CreateExcludeRuleMutationHookResult = ReturnType<typeof useCreateExcludeRuleMutation>;
export type CreateExcludeRuleMutationResult = Apollo.MutationResult<CreateExcludeRuleMutation>;
export type CreateExcludeRuleMutationOptions = Apollo.BaseMutationOptions<CreateExcludeRuleMutation, CreateExcludeRuleMutationVariables>;
export const CreateExcludeSkuRuleDocument = gql`
    mutation CreateExcludeSKURule($skus: [String!]!) {
  createExcludeSKURule(skus: $skus)
}
    `;
export type CreateExcludeSkuRuleMutationFn = Apollo.MutationFunction<CreateExcludeSkuRuleMutation, CreateExcludeSkuRuleMutationVariables>;

/**
 * __useCreateExcludeSkuRuleMutation__
 *
 * To run a mutation, you first call `useCreateExcludeSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExcludeSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExcludeSkuRuleMutation, { data, loading, error }] = useCreateExcludeSkuRuleMutation({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useCreateExcludeSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateExcludeSkuRuleMutation, CreateExcludeSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExcludeSkuRuleMutation, CreateExcludeSkuRuleMutationVariables>(CreateExcludeSkuRuleDocument, options);
      }
export type CreateExcludeSkuRuleMutationHookResult = ReturnType<typeof useCreateExcludeSkuRuleMutation>;
export type CreateExcludeSkuRuleMutationResult = Apollo.MutationResult<CreateExcludeSkuRuleMutation>;
export type CreateExcludeSkuRuleMutationOptions = Apollo.BaseMutationOptions<CreateExcludeSkuRuleMutation, CreateExcludeSkuRuleMutationVariables>;
export const CreateRouteRuleDocument = gql`
    mutation CreateRouteRule($fc_handle: String!, $tags: [String!]!) {
  createRouteRule(fc_handle: $fc_handle, tags: $tags)
}
    `;
export type CreateRouteRuleMutationFn = Apollo.MutationFunction<CreateRouteRuleMutation, CreateRouteRuleMutationVariables>;

/**
 * __useCreateRouteRuleMutation__
 *
 * To run a mutation, you first call `useCreateRouteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRouteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRouteRuleMutation, { data, loading, error }] = useCreateRouteRuleMutation({
 *   variables: {
 *      fc_handle: // value for 'fc_handle'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateRouteRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRouteRuleMutation, CreateRouteRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRouteRuleMutation, CreateRouteRuleMutationVariables>(CreateRouteRuleDocument, options);
      }
export type CreateRouteRuleMutationHookResult = ReturnType<typeof useCreateRouteRuleMutation>;
export type CreateRouteRuleMutationResult = Apollo.MutationResult<CreateRouteRuleMutation>;
export type CreateRouteRuleMutationOptions = Apollo.BaseMutationOptions<CreateRouteRuleMutation, CreateRouteRuleMutationVariables>;
export const CreateRouteSkuRuleDocument = gql`
    mutation CreateRouteSKURule($fc_handle: String!, $skus: [String!]!) {
  createRouteSKURule(fc_handle: $fc_handle, skus: $skus)
}
    `;
export type CreateRouteSkuRuleMutationFn = Apollo.MutationFunction<CreateRouteSkuRuleMutation, CreateRouteSkuRuleMutationVariables>;

/**
 * __useCreateRouteSkuRuleMutation__
 *
 * To run a mutation, you first call `useCreateRouteSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRouteSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRouteSkuRuleMutation, { data, loading, error }] = useCreateRouteSkuRuleMutation({
 *   variables: {
 *      fc_handle: // value for 'fc_handle'
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useCreateRouteSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRouteSkuRuleMutation, CreateRouteSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRouteSkuRuleMutation, CreateRouteSkuRuleMutationVariables>(CreateRouteSkuRuleDocument, options);
      }
export type CreateRouteSkuRuleMutationHookResult = ReturnType<typeof useCreateRouteSkuRuleMutation>;
export type CreateRouteSkuRuleMutationResult = Apollo.MutationResult<CreateRouteSkuRuleMutation>;
export type CreateRouteSkuRuleMutationOptions = Apollo.BaseMutationOptions<CreateRouteSkuRuleMutation, CreateRouteSkuRuleMutationVariables>;
export const RemoveExcludeRuleDocument = gql`
    mutation RemoveExcludeRule($tags: [String!]!) {
  removeExcludeRule(tags: $tags)
}
    `;
export type RemoveExcludeRuleMutationFn = Apollo.MutationFunction<RemoveExcludeRuleMutation, RemoveExcludeRuleMutationVariables>;

/**
 * __useRemoveExcludeRuleMutation__
 *
 * To run a mutation, you first call `useRemoveExcludeRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExcludeRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExcludeRuleMutation, { data, loading, error }] = useRemoveExcludeRuleMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveExcludeRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExcludeRuleMutation, RemoveExcludeRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExcludeRuleMutation, RemoveExcludeRuleMutationVariables>(RemoveExcludeRuleDocument, options);
      }
export type RemoveExcludeRuleMutationHookResult = ReturnType<typeof useRemoveExcludeRuleMutation>;
export type RemoveExcludeRuleMutationResult = Apollo.MutationResult<RemoveExcludeRuleMutation>;
export type RemoveExcludeRuleMutationOptions = Apollo.BaseMutationOptions<RemoveExcludeRuleMutation, RemoveExcludeRuleMutationVariables>;
export const RemoveRouteRuleDocument = gql`
    mutation RemoveRouteRule($tags: [String!]!) {
  removeRouteRule(tags: $tags)
}
    `;
export type RemoveRouteRuleMutationFn = Apollo.MutationFunction<RemoveRouteRuleMutation, RemoveRouteRuleMutationVariables>;

/**
 * __useRemoveRouteRuleMutation__
 *
 * To run a mutation, you first call `useRemoveRouteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRouteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRouteRuleMutation, { data, loading, error }] = useRemoveRouteRuleMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveRouteRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRouteRuleMutation, RemoveRouteRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRouteRuleMutation, RemoveRouteRuleMutationVariables>(RemoveRouteRuleDocument, options);
      }
export type RemoveRouteRuleMutationHookResult = ReturnType<typeof useRemoveRouteRuleMutation>;
export type RemoveRouteRuleMutationResult = Apollo.MutationResult<RemoveRouteRuleMutation>;
export type RemoveRouteRuleMutationOptions = Apollo.BaseMutationOptions<RemoveRouteRuleMutation, RemoveRouteRuleMutationVariables>;
export const RemoveRouteSkuRuleDocument = gql`
    mutation RemoveRouteSKURule($skus: [String!]!) {
  removeRouteSKURule(skus: $skus)
}
    `;
export type RemoveRouteSkuRuleMutationFn = Apollo.MutationFunction<RemoveRouteSkuRuleMutation, RemoveRouteSkuRuleMutationVariables>;

/**
 * __useRemoveRouteSkuRuleMutation__
 *
 * To run a mutation, you first call `useRemoveRouteSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRouteSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRouteSkuRuleMutation, { data, loading, error }] = useRemoveRouteSkuRuleMutation({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useRemoveRouteSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRouteSkuRuleMutation, RemoveRouteSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRouteSkuRuleMutation, RemoveRouteSkuRuleMutationVariables>(RemoveRouteSkuRuleDocument, options);
      }
export type RemoveRouteSkuRuleMutationHookResult = ReturnType<typeof useRemoveRouteSkuRuleMutation>;
export type RemoveRouteSkuRuleMutationResult = Apollo.MutationResult<RemoveRouteSkuRuleMutation>;
export type RemoveRouteSkuRuleMutationOptions = Apollo.BaseMutationOptions<RemoveRouteSkuRuleMutation, RemoveRouteSkuRuleMutationVariables>;
export const OverwriteOrdersSlaDocument = gql`
    mutation OverwriteOrdersSLA($slaDate: DateTime!, $tags: [String!]!) {
  overwriteOrdersSLA(slaDate: $slaDate, tags: $tags)
}
    `;
export type OverwriteOrdersSlaMutationFn = Apollo.MutationFunction<OverwriteOrdersSlaMutation, OverwriteOrdersSlaMutationVariables>;

/**
 * __useOverwriteOrdersSlaMutation__
 *
 * To run a mutation, you first call `useOverwriteOrdersSlaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverwriteOrdersSlaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overwriteOrdersSlaMutation, { data, loading, error }] = useOverwriteOrdersSlaMutation({
 *   variables: {
 *      slaDate: // value for 'slaDate'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useOverwriteOrdersSlaMutation(baseOptions?: Apollo.MutationHookOptions<OverwriteOrdersSlaMutation, OverwriteOrdersSlaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OverwriteOrdersSlaMutation, OverwriteOrdersSlaMutationVariables>(OverwriteOrdersSlaDocument, options);
      }
export type OverwriteOrdersSlaMutationHookResult = ReturnType<typeof useOverwriteOrdersSlaMutation>;
export type OverwriteOrdersSlaMutationResult = Apollo.MutationResult<OverwriteOrdersSlaMutation>;
export type OverwriteOrdersSlaMutationOptions = Apollo.BaseMutationOptions<OverwriteOrdersSlaMutation, OverwriteOrdersSlaMutationVariables>;
export const FetchExcludeRulesDocument = gql`
    query FetchExcludeRules {
  fetchExcludeRules {
    id
    tag {
      name
      type
    }
    exclude
  }
}
    `;

/**
 * __useFetchExcludeRulesQuery__
 *
 * To run a query within a React component, call `useFetchExcludeRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExcludeRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExcludeRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchExcludeRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchExcludeRulesQuery, FetchExcludeRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchExcludeRulesQuery, FetchExcludeRulesQueryVariables>(FetchExcludeRulesDocument, options);
      }
export function useFetchExcludeRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchExcludeRulesQuery, FetchExcludeRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchExcludeRulesQuery, FetchExcludeRulesQueryVariables>(FetchExcludeRulesDocument, options);
        }
export type FetchExcludeRulesQueryHookResult = ReturnType<typeof useFetchExcludeRulesQuery>;
export type FetchExcludeRulesLazyQueryHookResult = ReturnType<typeof useFetchExcludeRulesLazyQuery>;
export type FetchExcludeRulesQueryResult = Apollo.QueryResult<FetchExcludeRulesQuery, FetchExcludeRulesQueryVariables>;
export const FetchExcludeSkuRulesDocument = gql`
    query FetchExcludeSKURules {
  fetchExcludeSKURules {
    id
    sku
    shopDomain
    customerId
  }
}
    `;

/**
 * __useFetchExcludeSkuRulesQuery__
 *
 * To run a query within a React component, call `useFetchExcludeSkuRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExcludeSkuRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExcludeSkuRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchExcludeSkuRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchExcludeSkuRulesQuery, FetchExcludeSkuRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchExcludeSkuRulesQuery, FetchExcludeSkuRulesQueryVariables>(FetchExcludeSkuRulesDocument, options);
      }
export function useFetchExcludeSkuRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchExcludeSkuRulesQuery, FetchExcludeSkuRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchExcludeSkuRulesQuery, FetchExcludeSkuRulesQueryVariables>(FetchExcludeSkuRulesDocument, options);
        }
export type FetchExcludeSkuRulesQueryHookResult = ReturnType<typeof useFetchExcludeSkuRulesQuery>;
export type FetchExcludeSkuRulesLazyQueryHookResult = ReturnType<typeof useFetchExcludeSkuRulesLazyQuery>;
export type FetchExcludeSkuRulesQueryResult = Apollo.QueryResult<FetchExcludeSkuRulesQuery, FetchExcludeSkuRulesQueryVariables>;
export const RemoveExcludeSkuRuleDocument = gql`
    mutation RemoveExcludeSKURule($skus: [String!]!) {
  removeExcludeSKURule(skus: $skus)
}
    `;
export type RemoveExcludeSkuRuleMutationFn = Apollo.MutationFunction<RemoveExcludeSkuRuleMutation, RemoveExcludeSkuRuleMutationVariables>;

/**
 * __useRemoveExcludeSkuRuleMutation__
 *
 * To run a mutation, you first call `useRemoveExcludeSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExcludeSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExcludeSkuRuleMutation, { data, loading, error }] = useRemoveExcludeSkuRuleMutation({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useRemoveExcludeSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExcludeSkuRuleMutation, RemoveExcludeSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExcludeSkuRuleMutation, RemoveExcludeSkuRuleMutationVariables>(RemoveExcludeSkuRuleDocument, options);
      }
export type RemoveExcludeSkuRuleMutationHookResult = ReturnType<typeof useRemoveExcludeSkuRuleMutation>;
export type RemoveExcludeSkuRuleMutationResult = Apollo.MutationResult<RemoveExcludeSkuRuleMutation>;
export type RemoveExcludeSkuRuleMutationOptions = Apollo.BaseMutationOptions<RemoveExcludeSkuRuleMutation, RemoveExcludeSkuRuleMutationVariables>;
export const FetchSlaOverwrittingRulesDocument = gql`
    query FetchSLAOverwrittingRules {
  fetchSLAOverwrittingRules {
    id
    tag {
      name
      type
    }
    type
    date
    delayInDays
  }
}
    `;

/**
 * __useFetchSlaOverwrittingRulesQuery__
 *
 * To run a query within a React component, call `useFetchSlaOverwrittingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSlaOverwrittingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSlaOverwrittingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSlaOverwrittingRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchSlaOverwrittingRulesQuery, FetchSlaOverwrittingRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSlaOverwrittingRulesQuery, FetchSlaOverwrittingRulesQueryVariables>(FetchSlaOverwrittingRulesDocument, options);
      }
export function useFetchSlaOverwrittingRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSlaOverwrittingRulesQuery, FetchSlaOverwrittingRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSlaOverwrittingRulesQuery, FetchSlaOverwrittingRulesQueryVariables>(FetchSlaOverwrittingRulesDocument, options);
        }
export type FetchSlaOverwrittingRulesQueryHookResult = ReturnType<typeof useFetchSlaOverwrittingRulesQuery>;
export type FetchSlaOverwrittingRulesLazyQueryHookResult = ReturnType<typeof useFetchSlaOverwrittingRulesLazyQuery>;
export type FetchSlaOverwrittingRulesQueryResult = Apollo.QueryResult<FetchSlaOverwrittingRulesQuery, FetchSlaOverwrittingRulesQueryVariables>;
export const RemoveSlaOverwrittingRuleDocument = gql`
    mutation RemoveSLAOverwrittingRule($tags: [String!]!) {
  removeSLAOverwrittingRule(tags: $tags)
}
    `;
export type RemoveSlaOverwrittingRuleMutationFn = Apollo.MutationFunction<RemoveSlaOverwrittingRuleMutation, RemoveSlaOverwrittingRuleMutationVariables>;

/**
 * __useRemoveSlaOverwrittingRuleMutation__
 *
 * To run a mutation, you first call `useRemoveSlaOverwrittingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSlaOverwrittingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSlaOverwrittingRuleMutation, { data, loading, error }] = useRemoveSlaOverwrittingRuleMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveSlaOverwrittingRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSlaOverwrittingRuleMutation, RemoveSlaOverwrittingRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSlaOverwrittingRuleMutation, RemoveSlaOverwrittingRuleMutationVariables>(RemoveSlaOverwrittingRuleDocument, options);
      }
export type RemoveSlaOverwrittingRuleMutationHookResult = ReturnType<typeof useRemoveSlaOverwrittingRuleMutation>;
export type RemoveSlaOverwrittingRuleMutationResult = Apollo.MutationResult<RemoveSlaOverwrittingRuleMutation>;
export type RemoveSlaOverwrittingRuleMutationOptions = Apollo.BaseMutationOptions<RemoveSlaOverwrittingRuleMutation, RemoveSlaOverwrittingRuleMutationVariables>;
export const FetchSlaOverwrittingForSkuRulesDocument = gql`
    query FetchSLAOverwrittingForSKURules {
  fetchSLAOverwrittingForSKURules {
    id
    sku
    type
    date
    delayInDays
    shopId
  }
}
    `;

/**
 * __useFetchSlaOverwrittingForSkuRulesQuery__
 *
 * To run a query within a React component, call `useFetchSlaOverwrittingForSkuRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSlaOverwrittingForSkuRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSlaOverwrittingForSkuRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSlaOverwrittingForSkuRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchSlaOverwrittingForSkuRulesQuery, FetchSlaOverwrittingForSkuRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSlaOverwrittingForSkuRulesQuery, FetchSlaOverwrittingForSkuRulesQueryVariables>(FetchSlaOverwrittingForSkuRulesDocument, options);
      }
export function useFetchSlaOverwrittingForSkuRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSlaOverwrittingForSkuRulesQuery, FetchSlaOverwrittingForSkuRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSlaOverwrittingForSkuRulesQuery, FetchSlaOverwrittingForSkuRulesQueryVariables>(FetchSlaOverwrittingForSkuRulesDocument, options);
        }
export type FetchSlaOverwrittingForSkuRulesQueryHookResult = ReturnType<typeof useFetchSlaOverwrittingForSkuRulesQuery>;
export type FetchSlaOverwrittingForSkuRulesLazyQueryHookResult = ReturnType<typeof useFetchSlaOverwrittingForSkuRulesLazyQuery>;
export type FetchSlaOverwrittingForSkuRulesQueryResult = Apollo.QueryResult<FetchSlaOverwrittingForSkuRulesQuery, FetchSlaOverwrittingForSkuRulesQueryVariables>;
export const RemoveSlaOverwrittingSkuRuleDocument = gql`
    mutation RemoveSLAOverwrittingSKURule($skus: [String!]!) {
  removeSLAOverwrittingSKURule(skus: $skus)
}
    `;
export type RemoveSlaOverwrittingSkuRuleMutationFn = Apollo.MutationFunction<RemoveSlaOverwrittingSkuRuleMutation, RemoveSlaOverwrittingSkuRuleMutationVariables>;

/**
 * __useRemoveSlaOverwrittingSkuRuleMutation__
 *
 * To run a mutation, you first call `useRemoveSlaOverwrittingSkuRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSlaOverwrittingSkuRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSlaOverwrittingSkuRuleMutation, { data, loading, error }] = useRemoveSlaOverwrittingSkuRuleMutation({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useRemoveSlaOverwrittingSkuRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSlaOverwrittingSkuRuleMutation, RemoveSlaOverwrittingSkuRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSlaOverwrittingSkuRuleMutation, RemoveSlaOverwrittingSkuRuleMutationVariables>(RemoveSlaOverwrittingSkuRuleDocument, options);
      }
export type RemoveSlaOverwrittingSkuRuleMutationHookResult = ReturnType<typeof useRemoveSlaOverwrittingSkuRuleMutation>;
export type RemoveSlaOverwrittingSkuRuleMutationResult = Apollo.MutationResult<RemoveSlaOverwrittingSkuRuleMutation>;
export type RemoveSlaOverwrittingSkuRuleMutationOptions = Apollo.BaseMutationOptions<RemoveSlaOverwrittingSkuRuleMutation, RemoveSlaOverwrittingSkuRuleMutationVariables>;
export const FetchAssignTagRulesDocument = gql`
    query FetchAssignTagRules {
  fetchAssignTagRules {
    id
    tag {
      name
      type
    }
    literal
  }
}
    `;

/**
 * __useFetchAssignTagRulesQuery__
 *
 * To run a query within a React component, call `useFetchAssignTagRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAssignTagRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAssignTagRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAssignTagRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchAssignTagRulesQuery, FetchAssignTagRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAssignTagRulesQuery, FetchAssignTagRulesQueryVariables>(FetchAssignTagRulesDocument, options);
      }
export function useFetchAssignTagRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAssignTagRulesQuery, FetchAssignTagRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAssignTagRulesQuery, FetchAssignTagRulesQueryVariables>(FetchAssignTagRulesDocument, options);
        }
export type FetchAssignTagRulesQueryHookResult = ReturnType<typeof useFetchAssignTagRulesQuery>;
export type FetchAssignTagRulesLazyQueryHookResult = ReturnType<typeof useFetchAssignTagRulesLazyQuery>;
export type FetchAssignTagRulesQueryResult = Apollo.QueryResult<FetchAssignTagRulesQuery, FetchAssignTagRulesQueryVariables>;
export const RemoveAssignRuleDocument = gql`
    mutation RemoveAssignRule($tags: [String!]!) {
  removeAssignRule(tags: $tags)
}
    `;
export type RemoveAssignRuleMutationFn = Apollo.MutationFunction<RemoveAssignRuleMutation, RemoveAssignRuleMutationVariables>;

/**
 * __useRemoveAssignRuleMutation__
 *
 * To run a mutation, you first call `useRemoveAssignRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignRuleMutation, { data, loading, error }] = useRemoveAssignRuleMutation({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useRemoveAssignRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAssignRuleMutation, RemoveAssignRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAssignRuleMutation, RemoveAssignRuleMutationVariables>(RemoveAssignRuleDocument, options);
      }
export type RemoveAssignRuleMutationHookResult = ReturnType<typeof useRemoveAssignRuleMutation>;
export type RemoveAssignRuleMutationResult = Apollo.MutationResult<RemoveAssignRuleMutation>;
export type RemoveAssignRuleMutationOptions = Apollo.BaseMutationOptions<RemoveAssignRuleMutation, RemoveAssignRuleMutationVariables>;
export const FetchRouteRulesDocument = gql`
    query FetchRouteRules {
  fetchRouteRules {
    id
    tag {
      name
      type
    }
    fcId
  }
}
    `;

/**
 * __useFetchRouteRulesQuery__
 *
 * To run a query within a React component, call `useFetchRouteRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRouteRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRouteRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRouteRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchRouteRulesQuery, FetchRouteRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchRouteRulesQuery, FetchRouteRulesQueryVariables>(FetchRouteRulesDocument, options);
      }
export function useFetchRouteRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchRouteRulesQuery, FetchRouteRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchRouteRulesQuery, FetchRouteRulesQueryVariables>(FetchRouteRulesDocument, options);
        }
export type FetchRouteRulesQueryHookResult = ReturnType<typeof useFetchRouteRulesQuery>;
export type FetchRouteRulesLazyQueryHookResult = ReturnType<typeof useFetchRouteRulesLazyQuery>;
export type FetchRouteRulesQueryResult = Apollo.QueryResult<FetchRouteRulesQuery, FetchRouteRulesQueryVariables>;
export const FetchRouteSkuRulesDocument = gql`
    query FetchRouteSKURules {
  fetchRouteSKURules {
    fcId
    id
    sku
    fcName
  }
}
    `;

/**
 * __useFetchRouteSkuRulesQuery__
 *
 * To run a query within a React component, call `useFetchRouteSkuRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRouteSkuRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRouteSkuRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchRouteSkuRulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchRouteSkuRulesQuery, FetchRouteSkuRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchRouteSkuRulesQuery, FetchRouteSkuRulesQueryVariables>(FetchRouteSkuRulesDocument, options);
      }
export function useFetchRouteSkuRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchRouteSkuRulesQuery, FetchRouteSkuRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchRouteSkuRulesQuery, FetchRouteSkuRulesQueryVariables>(FetchRouteSkuRulesDocument, options);
        }
export type FetchRouteSkuRulesQueryHookResult = ReturnType<typeof useFetchRouteSkuRulesQuery>;
export type FetchRouteSkuRulesLazyQueryHookResult = ReturnType<typeof useFetchRouteSkuRulesLazyQuery>;
export type FetchRouteSkuRulesQueryResult = Apollo.QueryResult<FetchRouteSkuRulesQuery, FetchRouteSkuRulesQueryVariables>;
export const FetchAllTagsDocument = gql`
    query FetchAllTags {
  fetchAllTags {
    name
    type
  }
}
    `;

/**
 * __useFetchAllTagsQuery__
 *
 * To run a query within a React component, call `useFetchAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllTagsQuery, FetchAllTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllTagsQuery, FetchAllTagsQueryVariables>(FetchAllTagsDocument, options);
      }
export function useFetchAllTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllTagsQuery, FetchAllTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllTagsQuery, FetchAllTagsQueryVariables>(FetchAllTagsDocument, options);
        }
export type FetchAllTagsQueryHookResult = ReturnType<typeof useFetchAllTagsQuery>;
export type FetchAllTagsLazyQueryHookResult = ReturnType<typeof useFetchAllTagsLazyQuery>;
export type FetchAllTagsQueryResult = Apollo.QueryResult<FetchAllTagsQuery, FetchAllTagsQueryVariables>;
export const GetSalesChannelDocument = gql`
    query getSalesChannel {
  getSalesChannel
}
    `;

/**
 * __useGetSalesChannelQuery__
 *
 * To run a query within a React component, call `useGetSalesChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesChannelQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesChannelQuery, GetSalesChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesChannelQuery, GetSalesChannelQueryVariables>(GetSalesChannelDocument, options);
      }
export function useGetSalesChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesChannelQuery, GetSalesChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesChannelQuery, GetSalesChannelQueryVariables>(GetSalesChannelDocument, options);
        }
export type GetSalesChannelQueryHookResult = ReturnType<typeof useGetSalesChannelQuery>;
export type GetSalesChannelLazyQueryHookResult = ReturnType<typeof useGetSalesChannelLazyQuery>;
export type GetSalesChannelQueryResult = Apollo.QueryResult<GetSalesChannelQuery, GetSalesChannelQueryVariables>;
export const GetFulfillmentLocationsBySamDocument = gql`
    query GetFulfillmentLocationsBySAM {
  getFulfillmentLocationsBySAM {
    active
    address
    external_data_source
    handle
    id
    legacy_fulfillment_service_id
    shop_verified
    sla {
      cutOffTime
      daysOfOperation
      expiration
      fulfillmentFrequency
      fulfillmentFrequencyUnit
      onTimeOrderPercent
      processingDelay
      processingDelayUnit
    }
    shop_domain
    name
    timezone
    verification_status
  }
}
    `;

/**
 * __useGetFulfillmentLocationsBySamQuery__
 *
 * To run a query within a React component, call `useGetFulfillmentLocationsBySamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfillmentLocationsBySamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfillmentLocationsBySamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFulfillmentLocationsBySamQuery(baseOptions?: Apollo.QueryHookOptions<GetFulfillmentLocationsBySamQuery, GetFulfillmentLocationsBySamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFulfillmentLocationsBySamQuery, GetFulfillmentLocationsBySamQueryVariables>(GetFulfillmentLocationsBySamDocument, options);
      }
export function useGetFulfillmentLocationsBySamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFulfillmentLocationsBySamQuery, GetFulfillmentLocationsBySamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFulfillmentLocationsBySamQuery, GetFulfillmentLocationsBySamQueryVariables>(GetFulfillmentLocationsBySamDocument, options);
        }
export type GetFulfillmentLocationsBySamQueryHookResult = ReturnType<typeof useGetFulfillmentLocationsBySamQuery>;
export type GetFulfillmentLocationsBySamLazyQueryHookResult = ReturnType<typeof useGetFulfillmentLocationsBySamLazyQuery>;
export type GetFulfillmentLocationsBySamQueryResult = Apollo.QueryResult<GetFulfillmentLocationsBySamQuery, GetFulfillmentLocationsBySamQueryVariables>;
export const VerifyLocationSlaWithGoogleMapsReferenceDocument = gql`
    mutation VerifyLocationSLAWithGoogleMapsReference($geo: GeoLocationByGoogleMapsPlaceId!, $sla: SLAInput!, $locationId: String!, $updatedName: String) {
  verifyLocationSLAWithGoogleMapsReference(
    geo: $geo
    sla: $sla
    LocationId: $locationId
    updatedName: $updatedName
  ) {
    active
    address
    external_data_source
    handle
    id
    legacy_fulfillment_service_id
    shop_verified
    sla {
      cutOffTime
      daysOfOperation
      expiration
      fulfillmentFrequency
      fulfillmentFrequencyUnit
      onTimeOrderPercent
      processingDelay
      processingDelayUnit
    }
    shop_domain
    name
    timezone
    verification_status
  }
}
    `;
export type VerifyLocationSlaWithGoogleMapsReferenceMutationFn = Apollo.MutationFunction<VerifyLocationSlaWithGoogleMapsReferenceMutation, VerifyLocationSlaWithGoogleMapsReferenceMutationVariables>;

/**
 * __useVerifyLocationSlaWithGoogleMapsReferenceMutation__
 *
 * To run a mutation, you first call `useVerifyLocationSlaWithGoogleMapsReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyLocationSlaWithGoogleMapsReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyLocationSlaWithGoogleMapsReferenceMutation, { data, loading, error }] = useVerifyLocationSlaWithGoogleMapsReferenceMutation({
 *   variables: {
 *      geo: // value for 'geo'
 *      sla: // value for 'sla'
 *      locationId: // value for 'locationId'
 *      updatedName: // value for 'updatedName'
 *   },
 * });
 */
export function useVerifyLocationSlaWithGoogleMapsReferenceMutation(baseOptions?: Apollo.MutationHookOptions<VerifyLocationSlaWithGoogleMapsReferenceMutation, VerifyLocationSlaWithGoogleMapsReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyLocationSlaWithGoogleMapsReferenceMutation, VerifyLocationSlaWithGoogleMapsReferenceMutationVariables>(VerifyLocationSlaWithGoogleMapsReferenceDocument, options);
      }
export type VerifyLocationSlaWithGoogleMapsReferenceMutationHookResult = ReturnType<typeof useVerifyLocationSlaWithGoogleMapsReferenceMutation>;
export type VerifyLocationSlaWithGoogleMapsReferenceMutationResult = Apollo.MutationResult<VerifyLocationSlaWithGoogleMapsReferenceMutation>;
export type VerifyLocationSlaWithGoogleMapsReferenceMutationOptions = Apollo.BaseMutationOptions<VerifyLocationSlaWithGoogleMapsReferenceMutation, VerifyLocationSlaWithGoogleMapsReferenceMutationVariables>;
export const DeactivateFulfillmentLocationDocument = gql`
    mutation DeactivateFulfillmentLocation($locationId: String!) {
  deactivateFulfillmentLocation(LocationId: $locationId) {
    id
    external_data_source
    shop_domain
    name
    handle
    address
    supports_inventory_activity_tracking
    supports_courier_tracking
    supports_fulfillment_activity_tracking
    verification_status
    sla {
      daysOfOperation
      cutOffTime
      processingDelay
      processingDelayUnit
      fulfillmentFrequency
      fulfillmentFrequencyUnit
      onTimeOrderPercent
      expiration
    }
    timezone
    legacy_fulfillment_service_id
    shop_verified
    active
  }
}
    `;
export type DeactivateFulfillmentLocationMutationFn = Apollo.MutationFunction<DeactivateFulfillmentLocationMutation, DeactivateFulfillmentLocationMutationVariables>;

/**
 * __useDeactivateFulfillmentLocationMutation__
 *
 * To run a mutation, you first call `useDeactivateFulfillmentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateFulfillmentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateFulfillmentLocationMutation, { data, loading, error }] = useDeactivateFulfillmentLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useDeactivateFulfillmentLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateFulfillmentLocationMutation, DeactivateFulfillmentLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateFulfillmentLocationMutation, DeactivateFulfillmentLocationMutationVariables>(DeactivateFulfillmentLocationDocument, options);
      }
export type DeactivateFulfillmentLocationMutationHookResult = ReturnType<typeof useDeactivateFulfillmentLocationMutation>;
export type DeactivateFulfillmentLocationMutationResult = Apollo.MutationResult<DeactivateFulfillmentLocationMutation>;
export type DeactivateFulfillmentLocationMutationOptions = Apollo.BaseMutationOptions<DeactivateFulfillmentLocationMutation, DeactivateFulfillmentLocationMutationVariables>;
export const EnableFulfillmentLocationDocument = gql`
    mutation EnableFulfillmentLocation($locationInput: String!) {
  enableFulfillmentLocation(LocationInput: $locationInput) {
    id
    external_data_source
    shop_domain
    name
    handle
    address
    supports_inventory_activity_tracking
    supports_courier_tracking
    supports_fulfillment_activity_tracking
    verification_status
    sla {
      daysOfOperation
      cutOffTime
      processingDelay
      processingDelayUnit
      fulfillmentFrequency
      fulfillmentFrequencyUnit
      onTimeOrderPercent
      expiration
    }
    timezone
    legacy_fulfillment_service_id
    shop_verified
    active
  }
}
    `;
export type EnableFulfillmentLocationMutationFn = Apollo.MutationFunction<EnableFulfillmentLocationMutation, EnableFulfillmentLocationMutationVariables>;

/**
 * __useEnableFulfillmentLocationMutation__
 *
 * To run a mutation, you first call `useEnableFulfillmentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableFulfillmentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableFulfillmentLocationMutation, { data, loading, error }] = useEnableFulfillmentLocationMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useEnableFulfillmentLocationMutation(baseOptions?: Apollo.MutationHookOptions<EnableFulfillmentLocationMutation, EnableFulfillmentLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableFulfillmentLocationMutation, EnableFulfillmentLocationMutationVariables>(EnableFulfillmentLocationDocument, options);
      }
export type EnableFulfillmentLocationMutationHookResult = ReturnType<typeof useEnableFulfillmentLocationMutation>;
export type EnableFulfillmentLocationMutationResult = Apollo.MutationResult<EnableFulfillmentLocationMutation>;
export type EnableFulfillmentLocationMutationOptions = Apollo.BaseMutationOptions<EnableFulfillmentLocationMutation, EnableFulfillmentLocationMutationVariables>;
export const FetchAddressSuggestionsDocument = gql`
    query FetchAddressSuggestions($addressQuery: String!) {
  fetchAddressSuggestions(AddressQuery: $addressQuery) {
    place_id
    suggestedAddress
    main_text
    secondary_text
  }
}
    `;

/**
 * __useFetchAddressSuggestionsQuery__
 *
 * To run a query within a React component, call `useFetchAddressSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAddressSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAddressSuggestionsQuery({
 *   variables: {
 *      addressQuery: // value for 'addressQuery'
 *   },
 * });
 */
export function useFetchAddressSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<FetchAddressSuggestionsQuery, FetchAddressSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAddressSuggestionsQuery, FetchAddressSuggestionsQueryVariables>(FetchAddressSuggestionsDocument, options);
      }
export function useFetchAddressSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAddressSuggestionsQuery, FetchAddressSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAddressSuggestionsQuery, FetchAddressSuggestionsQueryVariables>(FetchAddressSuggestionsDocument, options);
        }
export type FetchAddressSuggestionsQueryHookResult = ReturnType<typeof useFetchAddressSuggestionsQuery>;
export type FetchAddressSuggestionsLazyQueryHookResult = ReturnType<typeof useFetchAddressSuggestionsLazyQuery>;
export type FetchAddressSuggestionsQueryResult = Apollo.QueryResult<FetchAddressSuggestionsQuery, FetchAddressSuggestionsQueryVariables>;
export const InsertFcDocument = gql`
    mutation insertFC($warehouseName: String!) {
  insertFulfillmentService(warehouseName: $warehouseName)
}
    `;
export type InsertFcMutationFn = Apollo.MutationFunction<InsertFcMutation, InsertFcMutationVariables>;

/**
 * __useInsertFcMutation__
 *
 * To run a mutation, you first call `useInsertFcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFcMutation, { data, loading, error }] = useInsertFcMutation({
 *   variables: {
 *      warehouseName: // value for 'warehouseName'
 *   },
 * });
 */
export function useInsertFcMutation(baseOptions?: Apollo.MutationHookOptions<InsertFcMutation, InsertFcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFcMutation, InsertFcMutationVariables>(InsertFcDocument, options);
      }
export type InsertFcMutationHookResult = ReturnType<typeof useInsertFcMutation>;
export type InsertFcMutationResult = Apollo.MutationResult<InsertFcMutation>;
export type InsertFcMutationOptions = Apollo.BaseMutationOptions<InsertFcMutation, InsertFcMutationVariables>;
export const DeleteFulfillmentDocument = gql`
    mutation DeleteFulfillment($deleteFulfillmentId: Float!) {
  deleteFulfillment(id: $deleteFulfillmentId)
}
    `;
export type DeleteFulfillmentMutationFn = Apollo.MutationFunction<DeleteFulfillmentMutation, DeleteFulfillmentMutationVariables>;

/**
 * __useDeleteFulfillmentMutation__
 *
 * To run a mutation, you first call `useDeleteFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFulfillmentMutation, { data, loading, error }] = useDeleteFulfillmentMutation({
 *   variables: {
 *      deleteFulfillmentId: // value for 'deleteFulfillmentId'
 *   },
 * });
 */
export function useDeleteFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFulfillmentMutation, DeleteFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFulfillmentMutation, DeleteFulfillmentMutationVariables>(DeleteFulfillmentDocument, options);
      }
export type DeleteFulfillmentMutationHookResult = ReturnType<typeof useDeleteFulfillmentMutation>;
export type DeleteFulfillmentMutationResult = Apollo.MutationResult<DeleteFulfillmentMutation>;
export type DeleteFulfillmentMutationOptions = Apollo.BaseMutationOptions<DeleteFulfillmentMutation, DeleteFulfillmentMutationVariables>;
export const UpdateFulfillmentServiceDocument = gql`
    mutation UpdateFulfillmentService($fcData: [FulfilmentServiceInput!]!) {
  updateFulfillmentService(fcData: $fcData)
}
    `;
export type UpdateFulfillmentServiceMutationFn = Apollo.MutationFunction<UpdateFulfillmentServiceMutation, UpdateFulfillmentServiceMutationVariables>;

/**
 * __useUpdateFulfillmentServiceMutation__
 *
 * To run a mutation, you first call `useUpdateFulfillmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFulfillmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentServiceMutation, { data, loading, error }] = useUpdateFulfillmentServiceMutation({
 *   variables: {
 *      fcData: // value for 'fcData'
 *   },
 * });
 */
export function useUpdateFulfillmentServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFulfillmentServiceMutation, UpdateFulfillmentServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFulfillmentServiceMutation, UpdateFulfillmentServiceMutationVariables>(UpdateFulfillmentServiceDocument, options);
      }
export type UpdateFulfillmentServiceMutationHookResult = ReturnType<typeof useUpdateFulfillmentServiceMutation>;
export type UpdateFulfillmentServiceMutationResult = Apollo.MutationResult<UpdateFulfillmentServiceMutation>;
export type UpdateFulfillmentServiceMutationOptions = Apollo.BaseMutationOptions<UpdateFulfillmentServiceMutation, UpdateFulfillmentServiceMutationVariables>;
export const ShopifyHealthCheckDocument = gql`
    query ShopifyHealthCheck {
  shopifyHealthCheck {
    access_key
    domain
  }
}
    `;

/**
 * __useShopifyHealthCheckQuery__
 *
 * To run a query within a React component, call `useShopifyHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyHealthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyHealthCheckQuery(baseOptions?: Apollo.QueryHookOptions<ShopifyHealthCheckQuery, ShopifyHealthCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifyHealthCheckQuery, ShopifyHealthCheckQueryVariables>(ShopifyHealthCheckDocument, options);
      }
export function useShopifyHealthCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifyHealthCheckQuery, ShopifyHealthCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifyHealthCheckQuery, ShopifyHealthCheckQueryVariables>(ShopifyHealthCheckDocument, options);
        }
export type ShopifyHealthCheckQueryHookResult = ReturnType<typeof useShopifyHealthCheckQuery>;
export type ShopifyHealthCheckLazyQueryHookResult = ReturnType<typeof useShopifyHealthCheckLazyQuery>;
export type ShopifyHealthCheckQueryResult = Apollo.QueryResult<ShopifyHealthCheckQuery, ShopifyHealthCheckQueryVariables>;
export const DisableFulfillmentDocument = gql`
    mutation DisableFulfillment($disableFulfillmentId: Float!) {
  disableFulfillment(id: $disableFulfillmentId)
}
    `;
export type DisableFulfillmentMutationFn = Apollo.MutationFunction<DisableFulfillmentMutation, DisableFulfillmentMutationVariables>;

/**
 * __useDisableFulfillmentMutation__
 *
 * To run a mutation, you first call `useDisableFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableFulfillmentMutation, { data, loading, error }] = useDisableFulfillmentMutation({
 *   variables: {
 *      disableFulfillmentId: // value for 'disableFulfillmentId'
 *   },
 * });
 */
export function useDisableFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<DisableFulfillmentMutation, DisableFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableFulfillmentMutation, DisableFulfillmentMutationVariables>(DisableFulfillmentDocument, options);
      }
export type DisableFulfillmentMutationHookResult = ReturnType<typeof useDisableFulfillmentMutation>;
export type DisableFulfillmentMutationResult = Apollo.MutationResult<DisableFulfillmentMutation>;
export type DisableFulfillmentMutationOptions = Apollo.BaseMutationOptions<DisableFulfillmentMutation, DisableFulfillmentMutationVariables>;
export const EnableFulfillmentDocument = gql`
    mutation EnableFulfillment($enableFulfillmentId: Float!) {
  enableFulfillment(id: $enableFulfillmentId)
}
    `;
export type EnableFulfillmentMutationFn = Apollo.MutationFunction<EnableFulfillmentMutation, EnableFulfillmentMutationVariables>;

/**
 * __useEnableFulfillmentMutation__
 *
 * To run a mutation, you first call `useEnableFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableFulfillmentMutation, { data, loading, error }] = useEnableFulfillmentMutation({
 *   variables: {
 *      enableFulfillmentId: // value for 'enableFulfillmentId'
 *   },
 * });
 */
export function useEnableFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<EnableFulfillmentMutation, EnableFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableFulfillmentMutation, EnableFulfillmentMutationVariables>(EnableFulfillmentDocument, options);
      }
export type EnableFulfillmentMutationHookResult = ReturnType<typeof useEnableFulfillmentMutation>;
export type EnableFulfillmentMutationResult = Apollo.MutationResult<EnableFulfillmentMutation>;
export type EnableFulfillmentMutationOptions = Apollo.BaseMutationOptions<EnableFulfillmentMutation, EnableFulfillmentMutationVariables>;
export const FetchFcRightsDocument = gql`
    query FetchFCRights {
  fetchFCRights {
    id
    fulfillmentServiceId
    userId
    customerId
  }
}
    `;

/**
 * __useFetchFcRightsQuery__
 *
 * To run a query within a React component, call `useFetchFcRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFcRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFcRightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchFcRightsQuery(baseOptions?: Apollo.QueryHookOptions<FetchFcRightsQuery, FetchFcRightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchFcRightsQuery, FetchFcRightsQueryVariables>(FetchFcRightsDocument, options);
      }
export function useFetchFcRightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchFcRightsQuery, FetchFcRightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchFcRightsQuery, FetchFcRightsQueryVariables>(FetchFcRightsDocument, options);
        }
export type FetchFcRightsQueryHookResult = ReturnType<typeof useFetchFcRightsQuery>;
export type FetchFcRightsLazyQueryHookResult = ReturnType<typeof useFetchFcRightsLazyQuery>;
export type FetchFcRightsQueryResult = Apollo.QueryResult<FetchFcRightsQuery, FetchFcRightsQueryVariables>;
export const UpdateFcRightsDocument = gql`
    mutation UpdateFCRights($userIds: [Float!]!, $fulfillmentId: Float!) {
  updateFCRights(userIds: $userIds, fulfillmentId: $fulfillmentId)
}
    `;
export type UpdateFcRightsMutationFn = Apollo.MutationFunction<UpdateFcRightsMutation, UpdateFcRightsMutationVariables>;

/**
 * __useUpdateFcRightsMutation__
 *
 * To run a mutation, you first call `useUpdateFcRightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFcRightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFcRightsMutation, { data, loading, error }] = useUpdateFcRightsMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useUpdateFcRightsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFcRightsMutation, UpdateFcRightsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFcRightsMutation, UpdateFcRightsMutationVariables>(UpdateFcRightsDocument, options);
      }
export type UpdateFcRightsMutationHookResult = ReturnType<typeof useUpdateFcRightsMutation>;
export type UpdateFcRightsMutationResult = Apollo.MutationResult<UpdateFcRightsMutation>;
export type UpdateFcRightsMutationOptions = Apollo.BaseMutationOptions<UpdateFcRightsMutation, UpdateFcRightsMutationVariables>;
export const GetFullfilmentCenterListDocument = gql`
    query GetFullfilmentCenterList {
  listFullfilmentService {
    id
    warehouseName
    handle
    fcSettings {
      contractPercent
      shopDomain
    }
  }
  fetchFCRoutes {
    id
    address
    fulfilmentServiceId
    priority
    fcHandle
  }
}
    `;

/**
 * __useGetFullfilmentCenterListQuery__
 *
 * To run a query within a React component, call `useGetFullfilmentCenterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullfilmentCenterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullfilmentCenterListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFullfilmentCenterListQuery(baseOptions?: Apollo.QueryHookOptions<GetFullfilmentCenterListQuery, GetFullfilmentCenterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullfilmentCenterListQuery, GetFullfilmentCenterListQueryVariables>(GetFullfilmentCenterListDocument, options);
      }
export function useGetFullfilmentCenterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullfilmentCenterListQuery, GetFullfilmentCenterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullfilmentCenterListQuery, GetFullfilmentCenterListQueryVariables>(GetFullfilmentCenterListDocument, options);
        }
export type GetFullfilmentCenterListQueryHookResult = ReturnType<typeof useGetFullfilmentCenterListQuery>;
export type GetFullfilmentCenterListLazyQueryHookResult = ReturnType<typeof useGetFullfilmentCenterListLazyQuery>;
export type GetFullfilmentCenterListQueryResult = Apollo.QueryResult<GetFullfilmentCenterListQuery, GetFullfilmentCenterListQueryVariables>;
export const FetchFcRoutesDocument = gql`
    query FetchFCRoutes {
  fetchFCRoutes {
    id
    address
    fulfilmentServiceId
    priority
    fcHandle
  }
  fetchFulfillments {
    id
    warehouseName
    service_name
  }
}
    `;

/**
 * __useFetchFcRoutesQuery__
 *
 * To run a query within a React component, call `useFetchFcRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchFcRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFcRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchFcRoutesQuery(baseOptions?: Apollo.QueryHookOptions<FetchFcRoutesQuery, FetchFcRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchFcRoutesQuery, FetchFcRoutesQueryVariables>(FetchFcRoutesDocument, options);
      }
export function useFetchFcRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchFcRoutesQuery, FetchFcRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchFcRoutesQuery, FetchFcRoutesQueryVariables>(FetchFcRoutesDocument, options);
        }
export type FetchFcRoutesQueryHookResult = ReturnType<typeof useFetchFcRoutesQuery>;
export type FetchFcRoutesLazyQueryHookResult = ReturnType<typeof useFetchFcRoutesLazyQuery>;
export type FetchFcRoutesQueryResult = Apollo.QueryResult<FetchFcRoutesQuery, FetchFcRoutesQueryVariables>;
export const SendHelpEmailDocument = gql`
    mutation sendHelpEmail($userEmail: String!, $subject: String!, $content: String!) {
  sendHelpEmail(content: $content, subject: $subject, userEmail: $userEmail)
}
    `;
export type SendHelpEmailMutationFn = Apollo.MutationFunction<SendHelpEmailMutation, SendHelpEmailMutationVariables>;

/**
 * __useSendHelpEmailMutation__
 *
 * To run a mutation, you first call `useSendHelpEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHelpEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHelpEmailMutation, { data, loading, error }] = useSendHelpEmailMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      subject: // value for 'subject'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendHelpEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendHelpEmailMutation, SendHelpEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendHelpEmailMutation, SendHelpEmailMutationVariables>(SendHelpEmailDocument, options);
      }
export type SendHelpEmailMutationHookResult = ReturnType<typeof useSendHelpEmailMutation>;
export type SendHelpEmailMutationResult = Apollo.MutationResult<SendHelpEmailMutation>;
export type SendHelpEmailMutationOptions = Apollo.BaseMutationOptions<SendHelpEmailMutation, SendHelpEmailMutationVariables>;
export const UpdateRoutesDocument = gql`
    mutation UpdateRoutes($routes: FulfilmentRoutesUpdate!) {
  updateFCRoutes(routes: $routes)
}
    `;
export type UpdateRoutesMutationFn = Apollo.MutationFunction<UpdateRoutesMutation, UpdateRoutesMutationVariables>;

/**
 * __useUpdateRoutesMutation__
 *
 * To run a mutation, you first call `useUpdateRoutesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoutesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoutesMutation, { data, loading, error }] = useUpdateRoutesMutation({
 *   variables: {
 *      routes: // value for 'routes'
 *   },
 * });
 */
export function useUpdateRoutesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoutesMutation, UpdateRoutesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoutesMutation, UpdateRoutesMutationVariables>(UpdateRoutesDocument, options);
      }
export type UpdateRoutesMutationHookResult = ReturnType<typeof useUpdateRoutesMutation>;
export type UpdateRoutesMutationResult = Apollo.MutationResult<UpdateRoutesMutation>;
export type UpdateRoutesMutationOptions = Apollo.BaseMutationOptions<UpdateRoutesMutation, UpdateRoutesMutationVariables>;
export const FetchPricingPlansDocument = gql`
    query FetchPricingPlans {
  fetchPricingPlans {
    name
    monthlyOrders
    overallOrders
    scorecardUsersLimit
    usersLimit
    alias
  }
}
    `;

/**
 * __useFetchPricingPlansQuery__
 *
 * To run a query within a React component, call `useFetchPricingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPricingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPricingPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPricingPlansQuery(baseOptions?: Apollo.QueryHookOptions<FetchPricingPlansQuery, FetchPricingPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPricingPlansQuery, FetchPricingPlansQueryVariables>(FetchPricingPlansDocument, options);
      }
export function useFetchPricingPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPricingPlansQuery, FetchPricingPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPricingPlansQuery, FetchPricingPlansQueryVariables>(FetchPricingPlansDocument, options);
        }
export type FetchPricingPlansQueryHookResult = ReturnType<typeof useFetchPricingPlansQuery>;
export type FetchPricingPlansLazyQueryHookResult = ReturnType<typeof useFetchPricingPlansLazyQuery>;
export type FetchPricingPlansQueryResult = Apollo.QueryResult<FetchPricingPlansQuery, FetchPricingPlansQueryVariables>;
export const UpdatePlanForCustomerDocument = gql`
    mutation UpdatePlanForCustomer($planName: String!) {
  updatePlanForCustomer(planAlias: $planName)
}
    `;
export type UpdatePlanForCustomerMutationFn = Apollo.MutationFunction<UpdatePlanForCustomerMutation, UpdatePlanForCustomerMutationVariables>;

/**
 * __useUpdatePlanForCustomerMutation__
 *
 * To run a mutation, you first call `useUpdatePlanForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanForCustomerMutation, { data, loading, error }] = useUpdatePlanForCustomerMutation({
 *   variables: {
 *      planName: // value for 'planName'
 *   },
 * });
 */
export function useUpdatePlanForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanForCustomerMutation, UpdatePlanForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanForCustomerMutation, UpdatePlanForCustomerMutationVariables>(UpdatePlanForCustomerDocument, options);
      }
export type UpdatePlanForCustomerMutationHookResult = ReturnType<typeof useUpdatePlanForCustomerMutation>;
export type UpdatePlanForCustomerMutationResult = Apollo.MutationResult<UpdatePlanForCustomerMutation>;
export type UpdatePlanForCustomerMutationOptions = Apollo.BaseMutationOptions<UpdatePlanForCustomerMutation, UpdatePlanForCustomerMutationVariables>;
export const MarkShopActiveDocument = gql`
    mutation MarkShopActive($shopName: String!, $shopType: ShopType!) {
  markShopActive(shopName: $shopName, shopType: $shopType)
}
    `;
export type MarkShopActiveMutationFn = Apollo.MutationFunction<MarkShopActiveMutation, MarkShopActiveMutationVariables>;

/**
 * __useMarkShopActiveMutation__
 *
 * To run a mutation, you first call `useMarkShopActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkShopActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markShopActiveMutation, { data, loading, error }] = useMarkShopActiveMutation({
 *   variables: {
 *      shopName: // value for 'shopName'
 *      shopType: // value for 'shopType'
 *   },
 * });
 */
export function useMarkShopActiveMutation(baseOptions?: Apollo.MutationHookOptions<MarkShopActiveMutation, MarkShopActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkShopActiveMutation, MarkShopActiveMutationVariables>(MarkShopActiveDocument, options);
      }
export type MarkShopActiveMutationHookResult = ReturnType<typeof useMarkShopActiveMutation>;
export type MarkShopActiveMutationResult = Apollo.MutationResult<MarkShopActiveMutation>;
export type MarkShopActiveMutationOptions = Apollo.BaseMutationOptions<MarkShopActiveMutation, MarkShopActiveMutationVariables>;
export const GetPricingPlanForActiveShopDocument = gql`
    query GetPricingPlanForActiveShop {
  getPricingPlanForActiveShop {
    id
    name
    alias
    monthlyOrders
    overallOrders
    scorecardDaysLimit
    scorecardUsersLimit
    usersLimit
    price
  }
}
    `;

/**
 * __useGetPricingPlanForActiveShopQuery__
 *
 * To run a query within a React component, call `useGetPricingPlanForActiveShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingPlanForActiveShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingPlanForActiveShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPricingPlanForActiveShopQuery(baseOptions?: Apollo.QueryHookOptions<GetPricingPlanForActiveShopQuery, GetPricingPlanForActiveShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPricingPlanForActiveShopQuery, GetPricingPlanForActiveShopQueryVariables>(GetPricingPlanForActiveShopDocument, options);
      }
export function useGetPricingPlanForActiveShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPricingPlanForActiveShopQuery, GetPricingPlanForActiveShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPricingPlanForActiveShopQuery, GetPricingPlanForActiveShopQueryVariables>(GetPricingPlanForActiveShopDocument, options);
        }
export type GetPricingPlanForActiveShopQueryHookResult = ReturnType<typeof useGetPricingPlanForActiveShopQuery>;
export type GetPricingPlanForActiveShopLazyQueryHookResult = ReturnType<typeof useGetPricingPlanForActiveShopLazyQuery>;
export type GetPricingPlanForActiveShopQueryResult = Apollo.QueryResult<GetPricingPlanForActiveShopQuery, GetPricingPlanForActiveShopQueryVariables>;
export const UpsertReasonCodesDocument = gql`
    mutation UpsertReasonCodes($reasonCodes: [ReasonCodeInput!]!) {
  upsertReasonCodes(reasonCodes: $reasonCodes)
}
    `;
export type UpsertReasonCodesMutationFn = Apollo.MutationFunction<UpsertReasonCodesMutation, UpsertReasonCodesMutationVariables>;

/**
 * __useUpsertReasonCodesMutation__
 *
 * To run a mutation, you first call `useUpsertReasonCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReasonCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertReasonCodesMutation, { data, loading, error }] = useUpsertReasonCodesMutation({
 *   variables: {
 *      reasonCodes: // value for 'reasonCodes'
 *   },
 * });
 */
export function useUpsertReasonCodesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertReasonCodesMutation, UpsertReasonCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertReasonCodesMutation, UpsertReasonCodesMutationVariables>(UpsertReasonCodesDocument, options);
      }
export type UpsertReasonCodesMutationHookResult = ReturnType<typeof useUpsertReasonCodesMutation>;
export type UpsertReasonCodesMutationResult = Apollo.MutationResult<UpsertReasonCodesMutation>;
export type UpsertReasonCodesMutationOptions = Apollo.BaseMutationOptions<UpsertReasonCodesMutation, UpsertReasonCodesMutationVariables>;
export const DeleteReasonCodesDocument = gql`
    mutation DeleteReasonCodes($reasonCodes: [ReasonCodeInput!]!) {
  deleteReasonCodes(reasonCodes: $reasonCodes)
}
    `;
export type DeleteReasonCodesMutationFn = Apollo.MutationFunction<DeleteReasonCodesMutation, DeleteReasonCodesMutationVariables>;

/**
 * __useDeleteReasonCodesMutation__
 *
 * To run a mutation, you first call `useDeleteReasonCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReasonCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReasonCodesMutation, { data, loading, error }] = useDeleteReasonCodesMutation({
 *   variables: {
 *      reasonCodes: // value for 'reasonCodes'
 *   },
 * });
 */
export function useDeleteReasonCodesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReasonCodesMutation, DeleteReasonCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReasonCodesMutation, DeleteReasonCodesMutationVariables>(DeleteReasonCodesDocument, options);
      }
export type DeleteReasonCodesMutationHookResult = ReturnType<typeof useDeleteReasonCodesMutation>;
export type DeleteReasonCodesMutationResult = Apollo.MutationResult<DeleteReasonCodesMutation>;
export type DeleteReasonCodesMutationOptions = Apollo.BaseMutationOptions<DeleteReasonCodesMutation, DeleteReasonCodesMutationVariables>;
export const AssignReasonCodeToOrdersDocument = gql`
    mutation AssignReasonCodeToOrders($orderIds: [String!]!, $reasonCode: ReasonCodeInput!) {
  assignReasonCodeToOrders(orderIds: $orderIds, reasonCode: $reasonCode)
}
    `;
export type AssignReasonCodeToOrdersMutationFn = Apollo.MutationFunction<AssignReasonCodeToOrdersMutation, AssignReasonCodeToOrdersMutationVariables>;

/**
 * __useAssignReasonCodeToOrdersMutation__
 *
 * To run a mutation, you first call `useAssignReasonCodeToOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignReasonCodeToOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignReasonCodeToOrdersMutation, { data, loading, error }] = useAssignReasonCodeToOrdersMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *      reasonCode: // value for 'reasonCode'
 *   },
 * });
 */
export function useAssignReasonCodeToOrdersMutation(baseOptions?: Apollo.MutationHookOptions<AssignReasonCodeToOrdersMutation, AssignReasonCodeToOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignReasonCodeToOrdersMutation, AssignReasonCodeToOrdersMutationVariables>(AssignReasonCodeToOrdersDocument, options);
      }
export type AssignReasonCodeToOrdersMutationHookResult = ReturnType<typeof useAssignReasonCodeToOrdersMutation>;
export type AssignReasonCodeToOrdersMutationResult = Apollo.MutationResult<AssignReasonCodeToOrdersMutation>;
export type AssignReasonCodeToOrdersMutationOptions = Apollo.BaseMutationOptions<AssignReasonCodeToOrdersMutation, AssignReasonCodeToOrdersMutationVariables>;
export const UnassignReasonCodeToOrdersDocument = gql`
    mutation UnassignReasonCodeToOrders($orderIds: [String!]!, $reasonCode: ReasonCodeInput!) {
  unassignReasonCodeToOrders(orderIds: $orderIds, reasonCode: $reasonCode)
}
    `;
export type UnassignReasonCodeToOrdersMutationFn = Apollo.MutationFunction<UnassignReasonCodeToOrdersMutation, UnassignReasonCodeToOrdersMutationVariables>;

/**
 * __useUnassignReasonCodeToOrdersMutation__
 *
 * To run a mutation, you first call `useUnassignReasonCodeToOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignReasonCodeToOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignReasonCodeToOrdersMutation, { data, loading, error }] = useUnassignReasonCodeToOrdersMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *      reasonCode: // value for 'reasonCode'
 *   },
 * });
 */
export function useUnassignReasonCodeToOrdersMutation(baseOptions?: Apollo.MutationHookOptions<UnassignReasonCodeToOrdersMutation, UnassignReasonCodeToOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignReasonCodeToOrdersMutation, UnassignReasonCodeToOrdersMutationVariables>(UnassignReasonCodeToOrdersDocument, options);
      }
export type UnassignReasonCodeToOrdersMutationHookResult = ReturnType<typeof useUnassignReasonCodeToOrdersMutation>;
export type UnassignReasonCodeToOrdersMutationResult = Apollo.MutationResult<UnassignReasonCodeToOrdersMutation>;
export type UnassignReasonCodeToOrdersMutationOptions = Apollo.BaseMutationOptions<UnassignReasonCodeToOrdersMutation, UnassignReasonCodeToOrdersMutationVariables>;
export const GetReasonCodesDocument = gql`
    query GetReasonCodes {
  getReasonCodes {
    id
    name
    shopDomain
  }
}
    `;

/**
 * __useGetReasonCodesQuery__
 *
 * To run a query within a React component, call `useGetReasonCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReasonCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetReasonCodesQuery, GetReasonCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReasonCodesQuery, GetReasonCodesQueryVariables>(GetReasonCodesDocument, options);
      }
export function useGetReasonCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReasonCodesQuery, GetReasonCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReasonCodesQuery, GetReasonCodesQueryVariables>(GetReasonCodesDocument, options);
        }
export type GetReasonCodesQueryHookResult = ReturnType<typeof useGetReasonCodesQuery>;
export type GetReasonCodesLazyQueryHookResult = ReturnType<typeof useGetReasonCodesLazyQuery>;
export type GetReasonCodesQueryResult = Apollo.QueryResult<GetReasonCodesQuery, GetReasonCodesQueryVariables>;
export const ListSalesChannelsDocument = gql`
    query ListSalesChannels {
  listSalesChannels {
    displayName
    id
  }
}
    `;

/**
 * __useListSalesChannelsQuery__
 *
 * To run a query within a React component, call `useListSalesChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSalesChannelsQuery(baseOptions?: Apollo.QueryHookOptions<ListSalesChannelsQuery, ListSalesChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSalesChannelsQuery, ListSalesChannelsQueryVariables>(ListSalesChannelsDocument, options);
      }
export function useListSalesChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSalesChannelsQuery, ListSalesChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSalesChannelsQuery, ListSalesChannelsQueryVariables>(ListSalesChannelsDocument, options);
        }
export type ListSalesChannelsQueryHookResult = ReturnType<typeof useListSalesChannelsQuery>;
export type ListSalesChannelsLazyQueryHookResult = ReturnType<typeof useListSalesChannelsLazyQuery>;
export type ListSalesChannelsQueryResult = Apollo.QueryResult<ListSalesChannelsQuery, ListSalesChannelsQueryVariables>;
export const UpsertSalesChannelDocument = gql`
    mutation UpsertSalesChannel($salesChannel: SalesChannelInput!) {
  upsertSalesChannel(salesChannel: $salesChannel)
}
    `;
export type UpsertSalesChannelMutationFn = Apollo.MutationFunction<UpsertSalesChannelMutation, UpsertSalesChannelMutationVariables>;

/**
 * __useUpsertSalesChannelMutation__
 *
 * To run a mutation, you first call `useUpsertSalesChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalesChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalesChannelMutation, { data, loading, error }] = useUpsertSalesChannelMutation({
 *   variables: {
 *      salesChannel: // value for 'salesChannel'
 *   },
 * });
 */
export function useUpsertSalesChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalesChannelMutation, UpsertSalesChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalesChannelMutation, UpsertSalesChannelMutationVariables>(UpsertSalesChannelDocument, options);
      }
export type UpsertSalesChannelMutationHookResult = ReturnType<typeof useUpsertSalesChannelMutation>;
export type UpsertSalesChannelMutationResult = Apollo.MutationResult<UpsertSalesChannelMutation>;
export type UpsertSalesChannelMutationOptions = Apollo.BaseMutationOptions<UpsertSalesChannelMutation, UpsertSalesChannelMutationVariables>;
export const RemoveSalesChannelDocument = gql`
    mutation RemoveSalesChannel($salesChannel: SalesChannelInput!) {
  removeSalesChannel(salesChannel: $salesChannel)
}
    `;
export type RemoveSalesChannelMutationFn = Apollo.MutationFunction<RemoveSalesChannelMutation, RemoveSalesChannelMutationVariables>;

/**
 * __useRemoveSalesChannelMutation__
 *
 * To run a mutation, you first call `useRemoveSalesChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesChannelMutation, { data, loading, error }] = useRemoveSalesChannelMutation({
 *   variables: {
 *      salesChannel: // value for 'salesChannel'
 *   },
 * });
 */
export function useRemoveSalesChannelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSalesChannelMutation, RemoveSalesChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSalesChannelMutation, RemoveSalesChannelMutationVariables>(RemoveSalesChannelDocument, options);
      }
export type RemoveSalesChannelMutationHookResult = ReturnType<typeof useRemoveSalesChannelMutation>;
export type RemoveSalesChannelMutationResult = Apollo.MutationResult<RemoveSalesChannelMutation>;
export type RemoveSalesChannelMutationOptions = Apollo.BaseMutationOptions<RemoveSalesChannelMutation, RemoveSalesChannelMutationVariables>;
export const ListSalesChannelRulesDocument = gql`
    query ListSalesChannelRules {
  listSalesChannelRules {
    comparisonNumber
    comparisonType
    id
    isPrefix
    literal
    ruleType
    scId
    salesChannelName
  }
}
    `;

/**
 * __useListSalesChannelRulesQuery__
 *
 * To run a query within a React component, call `useListSalesChannelRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesChannelRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesChannelRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSalesChannelRulesQuery(baseOptions?: Apollo.QueryHookOptions<ListSalesChannelRulesQuery, ListSalesChannelRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSalesChannelRulesQuery, ListSalesChannelRulesQueryVariables>(ListSalesChannelRulesDocument, options);
      }
export function useListSalesChannelRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSalesChannelRulesQuery, ListSalesChannelRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSalesChannelRulesQuery, ListSalesChannelRulesQueryVariables>(ListSalesChannelRulesDocument, options);
        }
export type ListSalesChannelRulesQueryHookResult = ReturnType<typeof useListSalesChannelRulesQuery>;
export type ListSalesChannelRulesLazyQueryHookResult = ReturnType<typeof useListSalesChannelRulesLazyQuery>;
export type ListSalesChannelRulesQueryResult = Apollo.QueryResult<ListSalesChannelRulesQuery, ListSalesChannelRulesQueryVariables>;
export const UpsertSalesChannelRuleDocument = gql`
    mutation UpsertSalesChannelRule($salesChannelRule: SalesChannelRuleObject!) {
  upsertSalesChannelRule(salesChannelRule: $salesChannelRule)
}
    `;
export type UpsertSalesChannelRuleMutationFn = Apollo.MutationFunction<UpsertSalesChannelRuleMutation, UpsertSalesChannelRuleMutationVariables>;

/**
 * __useUpsertSalesChannelRuleMutation__
 *
 * To run a mutation, you first call `useUpsertSalesChannelRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSalesChannelRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSalesChannelRuleMutation, { data, loading, error }] = useUpsertSalesChannelRuleMutation({
 *   variables: {
 *      salesChannelRule: // value for 'salesChannelRule'
 *   },
 * });
 */
export function useUpsertSalesChannelRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSalesChannelRuleMutation, UpsertSalesChannelRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSalesChannelRuleMutation, UpsertSalesChannelRuleMutationVariables>(UpsertSalesChannelRuleDocument, options);
      }
export type UpsertSalesChannelRuleMutationHookResult = ReturnType<typeof useUpsertSalesChannelRuleMutation>;
export type UpsertSalesChannelRuleMutationResult = Apollo.MutationResult<UpsertSalesChannelRuleMutation>;
export type UpsertSalesChannelRuleMutationOptions = Apollo.BaseMutationOptions<UpsertSalesChannelRuleMutation, UpsertSalesChannelRuleMutationVariables>;
export const RemoveSalesChannelRuleDocument = gql`
    mutation RemoveSalesChannelRule($salesChannelRule: SalesChannelRuleObject!) {
  removeSalesChannelRule(salesChannelRule: $salesChannelRule)
}
    `;
export type RemoveSalesChannelRuleMutationFn = Apollo.MutationFunction<RemoveSalesChannelRuleMutation, RemoveSalesChannelRuleMutationVariables>;

/**
 * __useRemoveSalesChannelRuleMutation__
 *
 * To run a mutation, you first call `useRemoveSalesChannelRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesChannelRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesChannelRuleMutation, { data, loading, error }] = useRemoveSalesChannelRuleMutation({
 *   variables: {
 *      salesChannelRule: // value for 'salesChannelRule'
 *   },
 * });
 */
export function useRemoveSalesChannelRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSalesChannelRuleMutation, RemoveSalesChannelRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSalesChannelRuleMutation, RemoveSalesChannelRuleMutationVariables>(RemoveSalesChannelRuleDocument, options);
      }
export type RemoveSalesChannelRuleMutationHookResult = ReturnType<typeof useRemoveSalesChannelRuleMutation>;
export type RemoveSalesChannelRuleMutationResult = Apollo.MutationResult<RemoveSalesChannelRuleMutation>;
export type RemoveSalesChannelRuleMutationOptions = Apollo.BaseMutationOptions<RemoveSalesChannelRuleMutation, RemoveSalesChannelRuleMutationVariables>;
export const ScorecardDocument = gql`
    query Scorecard($ordersToDate: DateTime!, $ordersFromDate: DateTime!, $fullfilmentCenter: String, $reportsOverviewToDate: DateTime!, $reportsOverviewFromDate: DateTime!, $reportsOverviewFullfilmentCenter: String, $sumaryInfoToDate: DateTime, $sumaryInfoFullfilmentCenter: String, $sumaryInfoFromDate: DateTime, $status: OrderStatus!) {
  getOrders(
    toDate: $ordersToDate
    fromDate: $ordersFromDate
    fulfillmentCenter: $fullfilmentCenter
    status: $status
  ) {
    orderId
    orderName
    qty
    orderDate
    slaDate
    orderStatus
    delay
    shipping_address
  }
  reportsOverview(
    toDate: $reportsOverviewToDate
    fromDate: $reportsOverviewFromDate
    fullfilmentCenter: $reportsOverviewFullfilmentCenter
  ) {
    showAllMetrics
    totalUnitsShipped
    totalSLAShippedWithinSLA
    overallPerformance
    maxleadTimeForShuppedOrder
    slaPercentageAndChange
  }
  sumaryInfo(
    toDate: $sumaryInfoToDate
    fullfilmentCenter: $sumaryInfoFullfilmentCenter
    fromDate: $sumaryInfoFromDate
  ) {
    totalOrdersShipped
    totalOrdersShippedPercentage
    totalOrdersShippedWithinSLA
    totalOrdersShippedWithinSLAPercentage
    totalOrdersShippedLate
    totalOrdersShippedLatePercentage
    oldesOutstandingOrders
    oldesOutstandingOrdersPercentage
    oldesOutstandingOrdersDaysLate
    totalUnshippedOrdersOutsideSLA
    totalUnshippedOrdersOutsideSLAPercentage
  }
}
    `;

/**
 * __useScorecardQuery__
 *
 * To run a query within a React component, call `useScorecardQuery` and pass it any options that fit your needs.
 * When your component renders, `useScorecardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScorecardQuery({
 *   variables: {
 *      ordersToDate: // value for 'ordersToDate'
 *      ordersFromDate: // value for 'ordersFromDate'
 *      fullfilmentCenter: // value for 'fullfilmentCenter'
 *      reportsOverviewToDate: // value for 'reportsOverviewToDate'
 *      reportsOverviewFromDate: // value for 'reportsOverviewFromDate'
 *      reportsOverviewFullfilmentCenter: // value for 'reportsOverviewFullfilmentCenter'
 *      sumaryInfoToDate: // value for 'sumaryInfoToDate'
 *      sumaryInfoFullfilmentCenter: // value for 'sumaryInfoFullfilmentCenter'
 *      sumaryInfoFromDate: // value for 'sumaryInfoFromDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useScorecardQuery(baseOptions: Apollo.QueryHookOptions<ScorecardQuery, ScorecardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScorecardQuery, ScorecardQueryVariables>(ScorecardDocument, options);
      }
export function useScorecardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScorecardQuery, ScorecardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScorecardQuery, ScorecardQueryVariables>(ScorecardDocument, options);
        }
export type ScorecardQueryHookResult = ReturnType<typeof useScorecardQuery>;
export type ScorecardLazyQueryHookResult = ReturnType<typeof useScorecardLazyQuery>;
export type ScorecardQueryResult = Apollo.QueryResult<ScorecardQuery, ScorecardQueryVariables>;
export const GetScorecardSummaryDocument = gql`
    query getScorecardSummary($toDate: DateTime, $fromDate: DateTime) {
  sumaryInfo(toDate: $toDate, fromDate: $fromDate) {
    totalOrdersShipped
    totalOrdersShippedPercentage
    totalOrdersShippedWithinSLA
    totalOrdersShippedWithinSLAPercentage
    totalOrdersShippedLate
    totalOrdersShippedLatePercentage
    oldesOutstandingOrders
    oldesOutstandingOrdersPercentage
    oldesOutstandingOrdersDaysLate
    totalUnshippedOrdersOutsideSLA
    totalUnshippedOrdersOutsideSLAPercentage
  }
}
    `;

/**
 * __useGetScorecardSummaryQuery__
 *
 * To run a query within a React component, call `useGetScorecardSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScorecardSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScorecardSummaryQuery({
 *   variables: {
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetScorecardSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetScorecardSummaryQuery, GetScorecardSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScorecardSummaryQuery, GetScorecardSummaryQueryVariables>(GetScorecardSummaryDocument, options);
      }
export function useGetScorecardSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScorecardSummaryQuery, GetScorecardSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScorecardSummaryQuery, GetScorecardSummaryQueryVariables>(GetScorecardSummaryDocument, options);
        }
export type GetScorecardSummaryQueryHookResult = ReturnType<typeof useGetScorecardSummaryQuery>;
export type GetScorecardSummaryLazyQueryHookResult = ReturnType<typeof useGetScorecardSummaryLazyQuery>;
export type GetScorecardSummaryQueryResult = Apollo.QueryResult<GetScorecardSummaryQuery, GetScorecardSummaryQueryVariables>;
export const GetReportsOverviewDocument = gql`
    query getReportsOverview($toDate: DateTime!, $fromDate: DateTime!) {
  reportsOverview(toDate: $toDate, fromDate: $fromDate) {
    showAllMetrics
    totalUnitsShipped
    totalSLAShippedWithinSLA
    overallPerformance
    maxleadTimeForShuppedOrder
    slaPercentageAndChange
  }
}
    `;

/**
 * __useGetReportsOverviewQuery__
 *
 * To run a query within a React component, call `useGetReportsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsOverviewQuery({
 *   variables: {
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetReportsOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetReportsOverviewQuery, GetReportsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsOverviewQuery, GetReportsOverviewQueryVariables>(GetReportsOverviewDocument, options);
      }
export function useGetReportsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsOverviewQuery, GetReportsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsOverviewQuery, GetReportsOverviewQueryVariables>(GetReportsOverviewDocument, options);
        }
export type GetReportsOverviewQueryHookResult = ReturnType<typeof useGetReportsOverviewQuery>;
export type GetReportsOverviewLazyQueryHookResult = ReturnType<typeof useGetReportsOverviewLazyQuery>;
export type GetReportsOverviewQueryResult = Apollo.QueryResult<GetReportsOverviewQuery, GetReportsOverviewQueryVariables>;
export const GetOrdersDocument = gql`
    query GetOrders($toDate: DateTime!, $fromDate: DateTime!, $status: OrderStatus!) {
  getOrders(toDate: $toDate, fromDate: $fromDate, status: $status) {
    orderId
    orderName
    qty
    orderDate
    slaDate
    orderStatus
    delay
  }
}
    `;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      toDate: // value for 'toDate'
 *      fromDate: // value for 'fromDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const FetchScorecardScheduleByFcDocument = gql`
    query FetchScorecardScheduleByFC($fulfillmentId: Float!) {
  fetchScorecardScheduleByFC(fulfillmentId: $fulfillmentId) {
    id
    days
    time
    fulfillmentServiceId
  }
}
    `;

/**
 * __useFetchScorecardScheduleByFcQuery__
 *
 * To run a query within a React component, call `useFetchScorecardScheduleByFcQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchScorecardScheduleByFcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchScorecardScheduleByFcQuery({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useFetchScorecardScheduleByFcQuery(baseOptions: Apollo.QueryHookOptions<FetchScorecardScheduleByFcQuery, FetchScorecardScheduleByFcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchScorecardScheduleByFcQuery, FetchScorecardScheduleByFcQueryVariables>(FetchScorecardScheduleByFcDocument, options);
      }
export function useFetchScorecardScheduleByFcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchScorecardScheduleByFcQuery, FetchScorecardScheduleByFcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchScorecardScheduleByFcQuery, FetchScorecardScheduleByFcQueryVariables>(FetchScorecardScheduleByFcDocument, options);
        }
export type FetchScorecardScheduleByFcQueryHookResult = ReturnType<typeof useFetchScorecardScheduleByFcQuery>;
export type FetchScorecardScheduleByFcLazyQueryHookResult = ReturnType<typeof useFetchScorecardScheduleByFcLazyQuery>;
export type FetchScorecardScheduleByFcQueryResult = Apollo.QueryResult<FetchScorecardScheduleByFcQuery, FetchScorecardScheduleByFcQueryVariables>;
export const FetchAllScorecardSchedulesDocument = gql`
    query FetchAllScorecardSchedules {
  fetchAllScorecardSchedules {
    id
    days
    time
    timezone
    fulfillmentServiceId
  }
}
    `;

/**
 * __useFetchAllScorecardSchedulesQuery__
 *
 * To run a query within a React component, call `useFetchAllScorecardSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllScorecardSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllScorecardSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllScorecardSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllScorecardSchedulesQuery, FetchAllScorecardSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllScorecardSchedulesQuery, FetchAllScorecardSchedulesQueryVariables>(FetchAllScorecardSchedulesDocument, options);
      }
export function useFetchAllScorecardSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllScorecardSchedulesQuery, FetchAllScorecardSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllScorecardSchedulesQuery, FetchAllScorecardSchedulesQueryVariables>(FetchAllScorecardSchedulesDocument, options);
        }
export type FetchAllScorecardSchedulesQueryHookResult = ReturnType<typeof useFetchAllScorecardSchedulesQuery>;
export type FetchAllScorecardSchedulesLazyQueryHookResult = ReturnType<typeof useFetchAllScorecardSchedulesLazyQuery>;
export type FetchAllScorecardSchedulesQueryResult = Apollo.QueryResult<FetchAllScorecardSchedulesQuery, FetchAllScorecardSchedulesQueryVariables>;
export const UpdateScorecardScheduleDocument = gql`
    mutation UpdateScorecardSchedule($schedule: ScorecardScheduleInput!) {
  updateScorecardSchedule(schedule: $schedule)
}
    `;
export type UpdateScorecardScheduleMutationFn = Apollo.MutationFunction<UpdateScorecardScheduleMutation, UpdateScorecardScheduleMutationVariables>;

/**
 * __useUpdateScorecardScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScorecardScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScorecardScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScorecardScheduleMutation, { data, loading, error }] = useUpdateScorecardScheduleMutation({
 *   variables: {
 *      schedule: // value for 'schedule'
 *   },
 * });
 */
export function useUpdateScorecardScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScorecardScheduleMutation, UpdateScorecardScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScorecardScheduleMutation, UpdateScorecardScheduleMutationVariables>(UpdateScorecardScheduleDocument, options);
      }
export type UpdateScorecardScheduleMutationHookResult = ReturnType<typeof useUpdateScorecardScheduleMutation>;
export type UpdateScorecardScheduleMutationResult = Apollo.MutationResult<UpdateScorecardScheduleMutation>;
export type UpdateScorecardScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateScorecardScheduleMutation, UpdateScorecardScheduleMutationVariables>;
export const FetchAllScorecardUsersDocument = gql`
    query FetchAllScorecardUsers {
  fetchAllScorecardUsers {
    id
    email
    scheduleId
  }
}
    `;

/**
 * __useFetchAllScorecardUsersQuery__
 *
 * To run a query within a React component, call `useFetchAllScorecardUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllScorecardUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllScorecardUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllScorecardUsersQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllScorecardUsersQuery, FetchAllScorecardUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllScorecardUsersQuery, FetchAllScorecardUsersQueryVariables>(FetchAllScorecardUsersDocument, options);
      }
export function useFetchAllScorecardUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllScorecardUsersQuery, FetchAllScorecardUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllScorecardUsersQuery, FetchAllScorecardUsersQueryVariables>(FetchAllScorecardUsersDocument, options);
        }
export type FetchAllScorecardUsersQueryHookResult = ReturnType<typeof useFetchAllScorecardUsersQuery>;
export type FetchAllScorecardUsersLazyQueryHookResult = ReturnType<typeof useFetchAllScorecardUsersLazyQuery>;
export type FetchAllScorecardUsersQueryResult = Apollo.QueryResult<FetchAllScorecardUsersQuery, FetchAllScorecardUsersQueryVariables>;
export const UpdateScorecardUsersDocument = gql`
    mutation UpdateScorecardUsers($scheduleId: Float!, $emails: [String!]!) {
  updateScorecardUsers(scheduleId: $scheduleId, emails: $emails)
}
    `;
export type UpdateScorecardUsersMutationFn = Apollo.MutationFunction<UpdateScorecardUsersMutation, UpdateScorecardUsersMutationVariables>;

/**
 * __useUpdateScorecardUsersMutation__
 *
 * To run a mutation, you first call `useUpdateScorecardUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScorecardUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScorecardUsersMutation, { data, loading, error }] = useUpdateScorecardUsersMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useUpdateScorecardUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScorecardUsersMutation, UpdateScorecardUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScorecardUsersMutation, UpdateScorecardUsersMutationVariables>(UpdateScorecardUsersDocument, options);
      }
export type UpdateScorecardUsersMutationHookResult = ReturnType<typeof useUpdateScorecardUsersMutation>;
export type UpdateScorecardUsersMutationResult = Apollo.MutationResult<UpdateScorecardUsersMutation>;
export type UpdateScorecardUsersMutationOptions = Apollo.BaseMutationOptions<UpdateScorecardUsersMutation, UpdateScorecardUsersMutationVariables>;
export const VerifyTokenDocument = gql`
    mutation VerifyToken($shopType: String!, $token: String!, $shop: String!) {
  verifyToken(shopType: $shopType, token: $token, shop: $shop)
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      shopType: // value for 'shopType'
 *      token: // value for 'token'
 *      shop: // value for 'shop'
 *   },
 * });
 */
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<VerifyTokenMutation, VerifyTokenMutationVariables>;
export const AddSkubanaShopDocument = gql`
    mutation AddSkubanaShop($cid: String!, $code: String!) {
  addSkubanaShop(cid: $cid, code: $code) {
    shop
    type
    otp
  }
}
    `;
export type AddSkubanaShopMutationFn = Apollo.MutationFunction<AddSkubanaShopMutation, AddSkubanaShopMutationVariables>;

/**
 * __useAddSkubanaShopMutation__
 *
 * To run a mutation, you first call `useAddSkubanaShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSkubanaShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSkubanaShopMutation, { data, loading, error }] = useAddSkubanaShopMutation({
 *   variables: {
 *      cid: // value for 'cid'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAddSkubanaShopMutation(baseOptions?: Apollo.MutationHookOptions<AddSkubanaShopMutation, AddSkubanaShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSkubanaShopMutation, AddSkubanaShopMutationVariables>(AddSkubanaShopDocument, options);
      }
export type AddSkubanaShopMutationHookResult = ReturnType<typeof useAddSkubanaShopMutation>;
export type AddSkubanaShopMutationResult = Apollo.MutationResult<AddSkubanaShopMutation>;
export type AddSkubanaShopMutationOptions = Apollo.BaseMutationOptions<AddSkubanaShopMutation, AddSkubanaShopMutationVariables>;
export const SubscriptionUrlDocument = gql`
    query SubscriptionURL {
  fetchSubscriptionURL
}
    `;

/**
 * __useSubscriptionUrlQuery__
 *
 * To run a query within a React component, call `useSubscriptionUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionUrlQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionUrlQuery, SubscriptionUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionUrlQuery, SubscriptionUrlQueryVariables>(SubscriptionUrlDocument, options);
      }
export function useSubscriptionUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionUrlQuery, SubscriptionUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionUrlQuery, SubscriptionUrlQueryVariables>(SubscriptionUrlDocument, options);
        }
export type SubscriptionUrlQueryHookResult = ReturnType<typeof useSubscriptionUrlQuery>;
export type SubscriptionUrlLazyQueryHookResult = ReturnType<typeof useSubscriptionUrlLazyQuery>;
export type SubscriptionUrlQueryResult = Apollo.QueryResult<SubscriptionUrlQuery, SubscriptionUrlQueryVariables>;
export const GetStandardViewDocument = gql`
    query GetStandardView($sumaryInfoToDate2: DateTime, $sumaryInfoFromDate2: DateTime, $sumaryInfoFullfilmentCenter2: String, $reportsOverviewToDate2: DateTime!, $reportsOverviewFromDate2: DateTime!, $reportsOverviewFullfilmentCenter2: String) {
  sumaryInfo(
    toDate: $sumaryInfoToDate2
    fromDate: $sumaryInfoFromDate2
    fullfilmentCenter: $sumaryInfoFullfilmentCenter2
  ) {
    totalOrdersShipped
    totalOrdersShippedPercentage
    totalOrdersShippedWithinSLA
    totalOrdersShippedWithinSLAPercentage
    totalOrdersShippedLate
    totalOrdersShippedLatePercentage
    oldesOutstandingOrders
    oldesOutstandingOrdersPercentage
    oldesOutstandingOrdersDaysLate
    totalUnshippedOrdersOutsideSLAPercentage
    totalUnshippedOrdersOutsideSLA
  }
  reportsOverview(
    toDate: $reportsOverviewToDate2
    fromDate: $reportsOverviewFromDate2
    fullfilmentCenter: $reportsOverviewFullfilmentCenter2
  ) {
    showAllMetrics
    totalUnitsShipped
    totalSLAShippedWithinSLA
    overallPerformance
    maxleadTimeForShuppedOrder
    slaPercentageAndChange
    dates
  }
}
    `;

/**
 * __useGetStandardViewQuery__
 *
 * To run a query within a React component, call `useGetStandardViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardViewQuery({
 *   variables: {
 *      sumaryInfoToDate2: // value for 'sumaryInfoToDate2'
 *      sumaryInfoFromDate2: // value for 'sumaryInfoFromDate2'
 *      sumaryInfoFullfilmentCenter2: // value for 'sumaryInfoFullfilmentCenter2'
 *      reportsOverviewToDate2: // value for 'reportsOverviewToDate2'
 *      reportsOverviewFromDate2: // value for 'reportsOverviewFromDate2'
 *      reportsOverviewFullfilmentCenter2: // value for 'reportsOverviewFullfilmentCenter2'
 *   },
 * });
 */
export function useGetStandardViewQuery(baseOptions: Apollo.QueryHookOptions<GetStandardViewQuery, GetStandardViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardViewQuery, GetStandardViewQueryVariables>(GetStandardViewDocument, options);
      }
export function useGetStandardViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardViewQuery, GetStandardViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardViewQuery, GetStandardViewQueryVariables>(GetStandardViewDocument, options);
        }
export type GetStandardViewQueryHookResult = ReturnType<typeof useGetStandardViewQuery>;
export type GetStandardViewLazyQueryHookResult = ReturnType<typeof useGetStandardViewLazyQuery>;
export type GetStandardViewQueryResult = Apollo.QueryResult<GetStandardViewQuery, GetStandardViewQueryVariables>;
export const GetScorecardViewDocument = gql`
    query GetScorecardView($FullfilmentCenter: String, $startingDate: DateTime!, $advanceViewStartingDate2: DateTime!, $readFromCache: Boolean) {
  weeklyColumnValues(startingDate: $startingDate) {
    current
    previous
    weekly
    monthly
    quarter
  }
  advanceView(
    startingDate: $advanceViewStartingDate2
    fullfilmentCenter: $FullfilmentCenter
    readFromCache: $readFromCache
  ) {
    sla {
      title
      values {
        cellId
        value
        threshold
      }
    }
    trends {
      title
      values {
        cellId
        value
        threshold
      }
    }
  }
}
    `;

/**
 * __useGetScorecardViewQuery__
 *
 * To run a query within a React component, call `useGetScorecardViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScorecardViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScorecardViewQuery({
 *   variables: {
 *      FullfilmentCenter: // value for 'FullfilmentCenter'
 *      startingDate: // value for 'startingDate'
 *      advanceViewStartingDate2: // value for 'advanceViewStartingDate2'
 *      readFromCache: // value for 'readFromCache'
 *   },
 * });
 */
export function useGetScorecardViewQuery(baseOptions: Apollo.QueryHookOptions<GetScorecardViewQuery, GetScorecardViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScorecardViewQuery, GetScorecardViewQueryVariables>(GetScorecardViewDocument, options);
      }
export function useGetScorecardViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScorecardViewQuery, GetScorecardViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScorecardViewQuery, GetScorecardViewQueryVariables>(GetScorecardViewDocument, options);
        }
export type GetScorecardViewQueryHookResult = ReturnType<typeof useGetScorecardViewQuery>;
export type GetScorecardViewLazyQueryHookResult = ReturnType<typeof useGetScorecardViewLazyQuery>;
export type GetScorecardViewQueryResult = Apollo.QueryResult<GetScorecardViewQuery, GetScorecardViewQueryVariables>;
export const GetPaymentIntentDocument = gql`
    query GetPaymentIntent {
  getClientSecretFromPaymentIntent {
    clientSecret
    customerId
  }
}
    `;

/**
 * __useGetPaymentIntentQuery__
 *
 * To run a query within a React component, call `useGetPaymentIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentIntentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentIntentQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentIntentQuery, GetPaymentIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentIntentQuery, GetPaymentIntentQueryVariables>(GetPaymentIntentDocument, options);
      }
export function useGetPaymentIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentIntentQuery, GetPaymentIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentIntentQuery, GetPaymentIntentQueryVariables>(GetPaymentIntentDocument, options);
        }
export type GetPaymentIntentQueryHookResult = ReturnType<typeof useGetPaymentIntentQuery>;
export type GetPaymentIntentLazyQueryHookResult = ReturnType<typeof useGetPaymentIntentLazyQuery>;
export type GetPaymentIntentQueryResult = Apollo.QueryResult<GetPaymentIntentQuery, GetPaymentIntentQueryVariables>;
export const VerifyCouponAndReturnDiscountsDocument = gql`
    query VerifyCouponAndReturnDiscounts($coupon: String!) {
  verifyCouponAndReturnDiscounts(coupon: $coupon) {
    planName
    discount
  }
}
    `;

/**
 * __useVerifyCouponAndReturnDiscountsQuery__
 *
 * To run a query within a React component, call `useVerifyCouponAndReturnDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCouponAndReturnDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCouponAndReturnDiscountsQuery({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useVerifyCouponAndReturnDiscountsQuery(baseOptions: Apollo.QueryHookOptions<VerifyCouponAndReturnDiscountsQuery, VerifyCouponAndReturnDiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyCouponAndReturnDiscountsQuery, VerifyCouponAndReturnDiscountsQueryVariables>(VerifyCouponAndReturnDiscountsDocument, options);
      }
export function useVerifyCouponAndReturnDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyCouponAndReturnDiscountsQuery, VerifyCouponAndReturnDiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyCouponAndReturnDiscountsQuery, VerifyCouponAndReturnDiscountsQueryVariables>(VerifyCouponAndReturnDiscountsDocument, options);
        }
export type VerifyCouponAndReturnDiscountsQueryHookResult = ReturnType<typeof useVerifyCouponAndReturnDiscountsQuery>;
export type VerifyCouponAndReturnDiscountsLazyQueryHookResult = ReturnType<typeof useVerifyCouponAndReturnDiscountsLazyQuery>;
export type VerifyCouponAndReturnDiscountsQueryResult = Apollo.QueryResult<VerifyCouponAndReturnDiscountsQuery, VerifyCouponAndReturnDiscountsQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  fetchUser {
    customerId
    email
    role
    firstName
    lastName
    currentStep
    skippedSteps
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const FulfillmentsDocument = gql`
    query Fulfillments {
  fetchFulfillments {
    id
    warehouseName
    routingSoftware
    address
    status
    handle
    daylightFrom
    daylightOffset
    daylightTo
    daylightEnabled
    fromTime
    toTime
    fcSettings {
      id
      delayOrders
      delayDuration
      delayDurationType
      contractPercent
      days
      timezone
      start
      end
      cutoffDate
      ordersBefore
      ordersAfter
      fcId
      shopDomain
      orderAccuracyPercent
      prepDuration
      prepDurationType
      salesChannelId
      shippingPeriodCount
      shippingPeriodType
      dayOfShipping
      shippingWindowType
    }
  }
}
    `;

/**
 * __useFulfillmentsQuery__
 *
 * To run a query within a React component, call `useFulfillmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfillmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfillmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFulfillmentsQuery(baseOptions?: Apollo.QueryHookOptions<FulfillmentsQuery, FulfillmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FulfillmentsQuery, FulfillmentsQueryVariables>(FulfillmentsDocument, options);
      }
export function useFulfillmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FulfillmentsQuery, FulfillmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FulfillmentsQuery, FulfillmentsQueryVariables>(FulfillmentsDocument, options);
        }
export type FulfillmentsQueryHookResult = ReturnType<typeof useFulfillmentsQuery>;
export type FulfillmentsLazyQueryHookResult = ReturnType<typeof useFulfillmentsLazyQuery>;
export type FulfillmentsQueryResult = Apollo.QueryResult<FulfillmentsQuery, FulfillmentsQueryVariables>;
export const FetchAllUsersDocument = gql`
    query FetchAllUsers {
  fetchAllUsers {
    id
    email
    role
    customerId
    createdAt
    firstName
    lastName
  }
}
    `;

/**
 * __useFetchAllUsersQuery__
 *
 * To run a query within a React component, call `useFetchAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<FetchAllUsersQuery, FetchAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAllUsersQuery, FetchAllUsersQueryVariables>(FetchAllUsersDocument, options);
      }
export function useFetchAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAllUsersQuery, FetchAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAllUsersQuery, FetchAllUsersQueryVariables>(FetchAllUsersDocument, options);
        }
export type FetchAllUsersQueryHookResult = ReturnType<typeof useFetchAllUsersQuery>;
export type FetchAllUsersLazyQueryHookResult = ReturnType<typeof useFetchAllUsersLazyQuery>;
export type FetchAllUsersQueryResult = Apollo.QueryResult<FetchAllUsersQuery, FetchAllUsersQueryVariables>;
export const InviteUsersDocument = gql`
    mutation inviteUsers($users: [UserRoleInput!]!) {
  inviteUser(users: $users) {
    email
    role
    firstName
    lastName
  }
}
    `;
export type InviteUsersMutationFn = Apollo.MutationFunction<InviteUsersMutation, InviteUsersMutationVariables>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useInviteUsersMutation(baseOptions?: Apollo.MutationHookOptions<InviteUsersMutation, InviteUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument, options);
      }
export type InviteUsersMutationHookResult = ReturnType<typeof useInviteUsersMutation>;
export type InviteUsersMutationResult = Apollo.MutationResult<InviteUsersMutation>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<InviteUsersMutation, InviteUsersMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user: UserInput!) {
  updateUser(user: $user)
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: Float!) {
  deleteUser(id: $deleteUserId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetActiveUserRoleDocument = gql`
    query GetActiveUserRole {
  getActiveUserRole {
    role
    firstName
    lastName
  }
}
    `;

/**
 * __useGetActiveUserRoleQuery__
 *
 * To run a query within a React component, call `useGetActiveUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveUserRoleQuery, GetActiveUserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveUserRoleQuery, GetActiveUserRoleQueryVariables>(GetActiveUserRoleDocument, options);
      }
export function useGetActiveUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveUserRoleQuery, GetActiveUserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveUserRoleQuery, GetActiveUserRoleQueryVariables>(GetActiveUserRoleDocument, options);
        }
export type GetActiveUserRoleQueryHookResult = ReturnType<typeof useGetActiveUserRoleQuery>;
export type GetActiveUserRoleLazyQueryHookResult = ReturnType<typeof useGetActiveUserRoleLazyQuery>;
export type GetActiveUserRoleQueryResult = Apollo.QueryResult<GetActiveUserRoleQuery, GetActiveUserRoleQueryVariables>;
export const UpdateUserFirstLastNameDocument = gql`
    mutation UpdateUserFirstLastName($lastName: String!, $firstName: String!) {
  updateUserFirstLastName(lastName: $lastName, firstName: $firstName)
}
    `;
export type UpdateUserFirstLastNameMutationFn = Apollo.MutationFunction<UpdateUserFirstLastNameMutation, UpdateUserFirstLastNameMutationVariables>;

/**
 * __useUpdateUserFirstLastNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserFirstLastNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFirstLastNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFirstLastNameMutation, { data, loading, error }] = useUpdateUserFirstLastNameMutation({
 *   variables: {
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useUpdateUserFirstLastNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFirstLastNameMutation, UpdateUserFirstLastNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFirstLastNameMutation, UpdateUserFirstLastNameMutationVariables>(UpdateUserFirstLastNameDocument, options);
      }
export type UpdateUserFirstLastNameMutationHookResult = ReturnType<typeof useUpdateUserFirstLastNameMutation>;
export type UpdateUserFirstLastNameMutationResult = Apollo.MutationResult<UpdateUserFirstLastNameMutation>;
export type UpdateUserFirstLastNameMutationOptions = Apollo.BaseMutationOptions<UpdateUserFirstLastNameMutation, UpdateUserFirstLastNameMutationVariables>;
export const UpdateUserCurrentStepDocument = gql`
    mutation UpdateUserCurrentStep($currentStep: CurrentSteps!) {
  updateUserCurrentStep(currentStep: $currentStep)
}
    `;
export type UpdateUserCurrentStepMutationFn = Apollo.MutationFunction<UpdateUserCurrentStepMutation, UpdateUserCurrentStepMutationVariables>;

/**
 * __useUpdateUserCurrentStepMutation__
 *
 * To run a mutation, you first call `useUpdateUserCurrentStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCurrentStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCurrentStepMutation, { data, loading, error }] = useUpdateUserCurrentStepMutation({
 *   variables: {
 *      currentStep: // value for 'currentStep'
 *   },
 * });
 */
export function useUpdateUserCurrentStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCurrentStepMutation, UpdateUserCurrentStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCurrentStepMutation, UpdateUserCurrentStepMutationVariables>(UpdateUserCurrentStepDocument, options);
      }
export type UpdateUserCurrentStepMutationHookResult = ReturnType<typeof useUpdateUserCurrentStepMutation>;
export type UpdateUserCurrentStepMutationResult = Apollo.MutationResult<UpdateUserCurrentStepMutation>;
export type UpdateUserCurrentStepMutationOptions = Apollo.BaseMutationOptions<UpdateUserCurrentStepMutation, UpdateUserCurrentStepMutationVariables>;
export const UpdateSkippedStepDocument = gql`
    mutation UpdateSkippedStep($skippedSteps: String!) {
  updateUserSkippedStep(skippedSteps: $skippedSteps)
}
    `;
export type UpdateSkippedStepMutationFn = Apollo.MutationFunction<UpdateSkippedStepMutation, UpdateSkippedStepMutationVariables>;

/**
 * __useUpdateSkippedStepMutation__
 *
 * To run a mutation, you first call `useUpdateSkippedStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkippedStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkippedStepMutation, { data, loading, error }] = useUpdateSkippedStepMutation({
 *   variables: {
 *      skippedSteps: // value for 'skippedSteps'
 *   },
 * });
 */
export function useUpdateSkippedStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkippedStepMutation, UpdateSkippedStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkippedStepMutation, UpdateSkippedStepMutationVariables>(UpdateSkippedStepDocument, options);
      }
export type UpdateSkippedStepMutationHookResult = ReturnType<typeof useUpdateSkippedStepMutation>;
export type UpdateSkippedStepMutationResult = Apollo.MutationResult<UpdateSkippedStepMutation>;
export type UpdateSkippedStepMutationOptions = Apollo.BaseMutationOptions<UpdateSkippedStepMutation, UpdateSkippedStepMutationVariables>;
export const FetchInProcessOnboardingDocument = gql`
    query FetchInProcessOnboarding {
  fetchInProcessOnboarding {
    activeUser {
      id
      email
      comp_name
      cust_id
      role
      status
      created_at
      deleted_at
      firstName
      lastName
    }
    fulfilmentServices {
      name
      handle
      providerInternalId
      id
      address
    }
    selectedPlanAlias
    cardAttached
    shopifyIntegrated
    shopifyURL
    stripeIntent {
      clientSecret
      customerId
      coupon
    }
  }
}
    `;

/**
 * __useFetchInProcessOnboardingQuery__
 *
 * To run a query within a React component, call `useFetchInProcessOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInProcessOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInProcessOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchInProcessOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<FetchInProcessOnboardingQuery, FetchInProcessOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchInProcessOnboardingQuery, FetchInProcessOnboardingQueryVariables>(FetchInProcessOnboardingDocument, options);
      }
export function useFetchInProcessOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchInProcessOnboardingQuery, FetchInProcessOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchInProcessOnboardingQuery, FetchInProcessOnboardingQueryVariables>(FetchInProcessOnboardingDocument, options);
        }
export type FetchInProcessOnboardingQueryHookResult = ReturnType<typeof useFetchInProcessOnboardingQuery>;
export type FetchInProcessOnboardingLazyQueryHookResult = ReturnType<typeof useFetchInProcessOnboardingLazyQuery>;
export type FetchInProcessOnboardingQueryResult = Apollo.QueryResult<FetchInProcessOnboardingQuery, FetchInProcessOnboardingQueryVariables>;
export const FetchInProcessShopDocument = gql`
    query FetchInProcessShop {
  fetchInProcessShop {
    activeUser {
      id
      email
      comp_name
      cust_id
      role
      status
      created_at
      deleted_at
      firstName
      lastName
    }
    fulfilmentServices {
      name
      handle
      providerInternalId
      id
      address
    }
    selectedPlanAlias
    shopifyURL
    cardAttached
    shopifyIntegrated
    stripeIntent {
      clientSecret
      customerId
      coupon
    }
  }
}
    `;

/**
 * __useFetchInProcessShopQuery__
 *
 * To run a query within a React component, call `useFetchInProcessShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInProcessShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInProcessShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchInProcessShopQuery(baseOptions?: Apollo.QueryHookOptions<FetchInProcessShopQuery, FetchInProcessShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchInProcessShopQuery, FetchInProcessShopQueryVariables>(FetchInProcessShopDocument, options);
      }
export function useFetchInProcessShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchInProcessShopQuery, FetchInProcessShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchInProcessShopQuery, FetchInProcessShopQueryVariables>(FetchInProcessShopDocument, options);
        }
export type FetchInProcessShopQueryHookResult = ReturnType<typeof useFetchInProcessShopQuery>;
export type FetchInProcessShopLazyQueryHookResult = ReturnType<typeof useFetchInProcessShopLazyQuery>;
export type FetchInProcessShopQueryResult = Apollo.QueryResult<FetchInProcessShopQuery, FetchInProcessShopQueryVariables>;
export const InitiateOnboardingDocument = gql`
    mutation initiateOnboarding($shopName: String!, $plan: PricingPlan!, $shopApiKey: String!, $shopPassword: String!, $shopType: ShopType!) {
  initiatedOnboardingProcess(
    shopName: $shopName
    planAlias: $plan
    shopApiKey: $shopApiKey
    shopPassword: $shopPassword
    shopType: $shopType
  ) {
    activeUser {
      id
      email
      comp_name
      cust_id
      role
      status
      created_at
      deleted_at
      firstName
      lastName
    }
    fulfilmentServices {
      id
      name
      providerInternalId
      handle
      address
    }
    cardAttached
    shopifyIntegrated
    shopifyURL
  }
}
    `;
export type InitiateOnboardingMutationFn = Apollo.MutationFunction<InitiateOnboardingMutation, InitiateOnboardingMutationVariables>;

/**
 * __useInitiateOnboardingMutation__
 *
 * To run a mutation, you first call `useInitiateOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateOnboardingMutation, { data, loading, error }] = useInitiateOnboardingMutation({
 *   variables: {
 *      shopName: // value for 'shopName'
 *      plan: // value for 'plan'
 *      shopApiKey: // value for 'shopApiKey'
 *      shopPassword: // value for 'shopPassword'
 *      shopType: // value for 'shopType'
 *   },
 * });
 */
export function useInitiateOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<InitiateOnboardingMutation, InitiateOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateOnboardingMutation, InitiateOnboardingMutationVariables>(InitiateOnboardingDocument, options);
      }
export type InitiateOnboardingMutationHookResult = ReturnType<typeof useInitiateOnboardingMutation>;
export type InitiateOnboardingMutationResult = Apollo.MutationResult<InitiateOnboardingMutation>;
export type InitiateOnboardingMutationOptions = Apollo.BaseMutationOptions<InitiateOnboardingMutation, InitiateOnboardingMutationVariables>;
export const FinalizeOnboardingForCustomerDocument = gql`
    mutation FinalizeOnboardingForCustomer($onboardingValues: OnboardingInput!) {
  finalizeOnboardingForCustomer(onboardingValues: $onboardingValues)
}
    `;
export type FinalizeOnboardingForCustomerMutationFn = Apollo.MutationFunction<FinalizeOnboardingForCustomerMutation, FinalizeOnboardingForCustomerMutationVariables>;

/**
 * __useFinalizeOnboardingForCustomerMutation__
 *
 * To run a mutation, you first call `useFinalizeOnboardingForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeOnboardingForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeOnboardingForCustomerMutation, { data, loading, error }] = useFinalizeOnboardingForCustomerMutation({
 *   variables: {
 *      onboardingValues: // value for 'onboardingValues'
 *   },
 * });
 */
export function useFinalizeOnboardingForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeOnboardingForCustomerMutation, FinalizeOnboardingForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeOnboardingForCustomerMutation, FinalizeOnboardingForCustomerMutationVariables>(FinalizeOnboardingForCustomerDocument, options);
      }
export type FinalizeOnboardingForCustomerMutationHookResult = ReturnType<typeof useFinalizeOnboardingForCustomerMutation>;
export type FinalizeOnboardingForCustomerMutationResult = Apollo.MutationResult<FinalizeOnboardingForCustomerMutation>;
export type FinalizeOnboardingForCustomerMutationOptions = Apollo.BaseMutationOptions<FinalizeOnboardingForCustomerMutation, FinalizeOnboardingForCustomerMutationVariables>;
export const AttachCardForCustomerDocument = gql`
    mutation AttachCardForCustomer($planName: String!, $lastName: String!, $firstName: String!, $companyName: String!, $email: String!, $stripeIntent: StripeIntentInput!) {
  attachCardAndCreateCustomer(
    planAlias: $planName
    lastName: $lastName
    firstName: $firstName
    companyName: $companyName
    email: $email
    stripeIntent: $stripeIntent
  ) {
    activeUser {
      id
      email
      comp_name
      cust_id
      role
      status
      created_at
      deleted_at
      firstName
      lastName
    }
    fulfilmentServices {
      id
      name
      providerInternalId
      handle
      address
    }
    selectedPlanAlias
    cardAttached
    shopifyIntegrated
  }
}
    `;
export type AttachCardForCustomerMutationFn = Apollo.MutationFunction<AttachCardForCustomerMutation, AttachCardForCustomerMutationVariables>;

/**
 * __useAttachCardForCustomerMutation__
 *
 * To run a mutation, you first call `useAttachCardForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachCardForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachCardForCustomerMutation, { data, loading, error }] = useAttachCardForCustomerMutation({
 *   variables: {
 *      planName: // value for 'planName'
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *      companyName: // value for 'companyName'
 *      email: // value for 'email'
 *      stripeIntent: // value for 'stripeIntent'
 *   },
 * });
 */
export function useAttachCardForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AttachCardForCustomerMutation, AttachCardForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttachCardForCustomerMutation, AttachCardForCustomerMutationVariables>(AttachCardForCustomerDocument, options);
      }
export type AttachCardForCustomerMutationHookResult = ReturnType<typeof useAttachCardForCustomerMutation>;
export type AttachCardForCustomerMutationResult = Apollo.MutationResult<AttachCardForCustomerMutation>;
export type AttachCardForCustomerMutationOptions = Apollo.BaseMutationOptions<AttachCardForCustomerMutation, AttachCardForCustomerMutationVariables>;
export const GetShopsDocument = gql`
    query GetShops {
  getShops {
    name
    domain
    shopType
    pricingPlan
    isOldInstallation
  }
}
    `;

/**
 * __useGetShopsQuery__
 *
 * To run a query within a React component, call `useGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShopsQuery(baseOptions?: Apollo.QueryHookOptions<GetShopsQuery, GetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
      }
export function useGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopsQuery, GetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
        }
export type GetShopsQueryHookResult = ReturnType<typeof useGetShopsQuery>;
export type GetShopsLazyQueryHookResult = ReturnType<typeof useGetShopsLazyQuery>;
export type GetShopsQueryResult = Apollo.QueryResult<GetShopsQuery, GetShopsQueryVariables>;
export const GetActiveShopDocument = gql`
    query GetActiveShop {
  getActiveShop {
    domain
    shopType
  }
}
    `;

/**
 * __useGetActiveShopQuery__
 *
 * To run a query within a React component, call `useGetActiveShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveShopQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveShopQuery, GetActiveShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveShopQuery, GetActiveShopQueryVariables>(GetActiveShopDocument, options);
      }
export function useGetActiveShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveShopQuery, GetActiveShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveShopQuery, GetActiveShopQueryVariables>(GetActiveShopDocument, options);
        }
export type GetActiveShopQueryHookResult = ReturnType<typeof useGetActiveShopQuery>;
export type GetActiveShopLazyQueryHookResult = ReturnType<typeof useGetActiveShopLazyQuery>;
export type GetActiveShopQueryResult = Apollo.QueryResult<GetActiveShopQuery, GetActiveShopQueryVariables>;
export const EnableDaylightSavingDocument = gql`
    mutation EnableDaylightSaving($to: DateTime!, $from: DateTime!, $offset: Float!, $id: Float!) {
  enableDaylightSaving(to: $to, from: $from, offset: $offset, id: $id)
}
    `;
export type EnableDaylightSavingMutationFn = Apollo.MutationFunction<EnableDaylightSavingMutation, EnableDaylightSavingMutationVariables>;

/**
 * __useEnableDaylightSavingMutation__
 *
 * To run a mutation, you first call `useEnableDaylightSavingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableDaylightSavingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableDaylightSavingMutation, { data, loading, error }] = useEnableDaylightSavingMutation({
 *   variables: {
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableDaylightSavingMutation(baseOptions?: Apollo.MutationHookOptions<EnableDaylightSavingMutation, EnableDaylightSavingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableDaylightSavingMutation, EnableDaylightSavingMutationVariables>(EnableDaylightSavingDocument, options);
      }
export type EnableDaylightSavingMutationHookResult = ReturnType<typeof useEnableDaylightSavingMutation>;
export type EnableDaylightSavingMutationResult = Apollo.MutationResult<EnableDaylightSavingMutation>;
export type EnableDaylightSavingMutationOptions = Apollo.BaseMutationOptions<EnableDaylightSavingMutation, EnableDaylightSavingMutationVariables>;
export const DisableDaylightSavingDocument = gql`
    mutation DisableDaylightSaving($id: Float!) {
  disableDaylightSaving(id: $id)
}
    `;
export type DisableDaylightSavingMutationFn = Apollo.MutationFunction<DisableDaylightSavingMutation, DisableDaylightSavingMutationVariables>;

/**
 * __useDisableDaylightSavingMutation__
 *
 * To run a mutation, you first call `useDisableDaylightSavingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableDaylightSavingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableDaylightSavingMutation, { data, loading, error }] = useDisableDaylightSavingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableDaylightSavingMutation(baseOptions?: Apollo.MutationHookOptions<DisableDaylightSavingMutation, DisableDaylightSavingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableDaylightSavingMutation, DisableDaylightSavingMutationVariables>(DisableDaylightSavingDocument, options);
      }
export type DisableDaylightSavingMutationHookResult = ReturnType<typeof useDisableDaylightSavingMutation>;
export type DisableDaylightSavingMutationResult = Apollo.MutationResult<DisableDaylightSavingMutation>;
export type DisableDaylightSavingMutationOptions = Apollo.BaseMutationOptions<DisableDaylightSavingMutation, DisableDaylightSavingMutationVariables>;
export const GetShopifyPaymentLinkForActiveShopDocument = gql`
    query GetShopifyPaymentLinkForActiveShop($paymentPlanAlias: String!) {
  getShopifyPaymentLinkForActiveShop(paymentPlanAlias: $paymentPlanAlias)
}
    `;

/**
 * __useGetShopifyPaymentLinkForActiveShopQuery__
 *
 * To run a query within a React component, call `useGetShopifyPaymentLinkForActiveShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopifyPaymentLinkForActiveShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopifyPaymentLinkForActiveShopQuery({
 *   variables: {
 *      paymentPlanAlias: // value for 'paymentPlanAlias'
 *   },
 * });
 */
export function useGetShopifyPaymentLinkForActiveShopQuery(baseOptions: Apollo.QueryHookOptions<GetShopifyPaymentLinkForActiveShopQuery, GetShopifyPaymentLinkForActiveShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopifyPaymentLinkForActiveShopQuery, GetShopifyPaymentLinkForActiveShopQueryVariables>(GetShopifyPaymentLinkForActiveShopDocument, options);
      }
export function useGetShopifyPaymentLinkForActiveShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopifyPaymentLinkForActiveShopQuery, GetShopifyPaymentLinkForActiveShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopifyPaymentLinkForActiveShopQuery, GetShopifyPaymentLinkForActiveShopQueryVariables>(GetShopifyPaymentLinkForActiveShopDocument, options);
        }
export type GetShopifyPaymentLinkForActiveShopQueryHookResult = ReturnType<typeof useGetShopifyPaymentLinkForActiveShopQuery>;
export type GetShopifyPaymentLinkForActiveShopLazyQueryHookResult = ReturnType<typeof useGetShopifyPaymentLinkForActiveShopLazyQuery>;
export type GetShopifyPaymentLinkForActiveShopQueryResult = Apollo.QueryResult<GetShopifyPaymentLinkForActiveShopQuery, GetShopifyPaymentLinkForActiveShopQueryVariables>;
export const CancelSubscirptionForActiveShopDocument = gql`
    query CancelSubscirptionForActiveShop {
  cancelSubscirptionForActiveShop
}
    `;

/**
 * __useCancelSubscirptionForActiveShopQuery__
 *
 * To run a query within a React component, call `useCancelSubscirptionForActiveShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscirptionForActiveShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelSubscirptionForActiveShopQuery({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscirptionForActiveShopQuery(baseOptions?: Apollo.QueryHookOptions<CancelSubscirptionForActiveShopQuery, CancelSubscirptionForActiveShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancelSubscirptionForActiveShopQuery, CancelSubscirptionForActiveShopQueryVariables>(CancelSubscirptionForActiveShopDocument, options);
      }
export function useCancelSubscirptionForActiveShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancelSubscirptionForActiveShopQuery, CancelSubscirptionForActiveShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancelSubscirptionForActiveShopQuery, CancelSubscirptionForActiveShopQueryVariables>(CancelSubscirptionForActiveShopDocument, options);
        }
export type CancelSubscirptionForActiveShopQueryHookResult = ReturnType<typeof useCancelSubscirptionForActiveShopQuery>;
export type CancelSubscirptionForActiveShopLazyQueryHookResult = ReturnType<typeof useCancelSubscirptionForActiveShopLazyQuery>;
export type CancelSubscirptionForActiveShopQueryResult = Apollo.QueryResult<CancelSubscirptionForActiveShopQuery, CancelSubscirptionForActiveShopQueryVariables>;
export const ShopExistsDocument = gql`
    query ShopExists($shopName: String!, $shopType: ShopType) {
  shopExists(shopName: $shopName, shopType: $shopType)
}
    `;

/**
 * __useShopExistsQuery__
 *
 * To run a query within a React component, call `useShopExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopExistsQuery({
 *   variables: {
 *      shopName: // value for 'shopName'
 *      shopType: // value for 'shopType'
 *   },
 * });
 */
export function useShopExistsQuery(baseOptions: Apollo.QueryHookOptions<ShopExistsQuery, ShopExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopExistsQuery, ShopExistsQueryVariables>(ShopExistsDocument, options);
      }
export function useShopExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopExistsQuery, ShopExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopExistsQuery, ShopExistsQueryVariables>(ShopExistsDocument, options);
        }
export type ShopExistsQueryHookResult = ReturnType<typeof useShopExistsQuery>;
export type ShopExistsLazyQueryHookResult = ReturnType<typeof useShopExistsLazyQuery>;
export type ShopExistsQueryResult = Apollo.QueryResult<ShopExistsQuery, ShopExistsQueryVariables>;